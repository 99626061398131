// src/components/GoalForm.jsx

import React, { useState, useEffect } from 'react';
import { DialogContent, DialogHeader, DialogTitle, DialogDescription } from './ui/Dialog';
import { Input } from './ui/Input';
import { Button } from './ui/Button';
import { Label } from './ui/Label';
import { Progress } from './ui/Progress';
import { Calendar } from 'lucide-react';
import { Alert, AlertDescription } from './ui/Alert';

const GoalForm = ({ 
  isEditing,
  formData,
  handleInputChange,
  handleSubmit,
  onClose 
}) => {
  const [validation, setValidation] = useState({
    title: true,
    targetDate: true,
    targetValue: true
  });
  
  const [progress, setProgress] = useState(0);
  
  useEffect(() => {
    if (formData.currentValue && formData.targetValue) {
      const percentage = (formData.currentValue / formData.targetValue) * 100;
      setProgress(Math.min(100, Math.max(0, percentage)));
    }
  }, [formData.currentValue, formData.targetValue]);

  const handleValidation = (name, value) => {
    switch (name) {
      case 'title':
        setValidation(prev => ({ ...prev, title: value.length >= 3 }));
        break;
      case 'targetDate':
        const selectedDate = new Date(value);
        const today = new Date();
        setValidation(prev => ({ ...prev, targetDate: selectedDate >= today }));
        break;
      case 'targetValue':
        setValidation(prev => ({ ...prev, targetValue: value > 0 }));
        break;
      default:
        break;
    }
  };

  const handleLocalInputChange = (e) => {
    const { name, value } = e.target;
    handleInputChange(e);
    handleValidation(name, value);
  };

  const handleLocalSubmit = (e) => {
    e.preventDefault();
    const isValid = Object.values(validation).every(v => v);
    if (!isValid) return;
    handleSubmit(e);
  };

  const remainingDays = () => {
    if (!formData.targetDate) return null;
    const today = new Date();
    const target = new Date(formData.targetDate);
    const diff = Math.ceil((target - today) / (1000 * 60 * 60 * 24));
    return diff;
  };

  return (
    <DialogContent className="sm:max-w-[525px] bg-zinc-900 border border-gray-800 shadow-xl text-white">
      <DialogHeader>
        <DialogTitle className="text-2xl font-bold bg-gradient-to-r from-cyan-400 to-purple-400 bg-clip-text text-transparent">
          {isEditing ? 'Edit Goal' : 'Create New Goal'}
        </DialogTitle>
        <DialogDescription className="text-gray-400">
          {isEditing ? 'Update your goal details and track progress' : 'Set a new goal and start tracking your journey'}
        </DialogDescription>
      </DialogHeader>

      <form onSubmit={handleLocalSubmit} className="space-y-6">
        <div className="space-y-2">
          <Label htmlFor="title" className="text-sm font-medium text-gray-300 flex items-center gap-2">
            Goal Title
          </Label>
          <Input
            id="title"
            name="title"
            value={formData.title}
            onChange={handleLocalInputChange}
            placeholder="e.g., Increase Bench Press"
            className={`bg-gray-800 border-gray-700 text-white placeholder-gray-500 ${!validation.title ? 'border-red-500' : 'focus:border-cyan-500 hover:border-gray-600'}`}
            required
          />
          {!validation.title && (
            <p className="text-xs text-red-400">Title must be at least 3 characters</p>
          )}
        </div>

        <div className="space-y-2">
          <Label htmlFor="targetDate" className="text-sm font-medium text-gray-300 flex items-center gap-2">
            <Calendar className="w-4 h-4 text-cyan-400" />
            Target Date
          </Label>
          <Input
            id="targetDate"
            type="date"
            name="targetDate"
            value={formData.targetDate}
            onChange={handleLocalInputChange}
            className={`bg-gray-800 border-gray-700 text-white ${!validation.targetDate ? 'border-red-500' : 'focus:border-cyan-500 hover:border-gray-600'}`}
            required
            min={new Date().toISOString().split('T')[0]}
          />
          {!validation.targetDate && (
            <p className="text-xs text-red-400">Target date must be in the future</p>
          )}
          {formData.targetDate && remainingDays() > 0 && (
            <p className="text-sm text-cyan-400">
              {remainingDays()} days remaining
            </p>
          )}
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <Label htmlFor="targetValue" className="text-sm font-medium text-gray-300">
              Target Value
            </Label>
            <Input
              id="targetValue"
              type="number"
              name="targetValue"
              value={formData.targetValue}
              onChange={handleLocalInputChange}
              placeholder="e.g., 225"
              className={`bg-gray-800 border-gray-700 text-white placeholder-gray-500 ${!validation.targetValue ? 'border-red-500' : 'focus:border-cyan-500 hover:border-gray-600'}`}
              required
              min="0"
              step="any"
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="unit" className="text-sm font-medium text-gray-300">
              Unit of Measurement
            </Label>
            <Input
              id="unit"
              name="unit"
              value={formData.unit}
              onChange={handleLocalInputChange}
              placeholder="e.g., lbs, km, minutes"
              className="bg-gray-800 border-gray-700 text-white placeholder-gray-500 focus:border-cyan-500 hover:border-gray-600"
              required
            />
          </div>
        </div>

        {isEditing && (
          <>
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="currentValue" className="text-sm font-medium text-gray-300">
                  Current Progress
                </Label>
                <Input
                  id="currentValue"
                  type="number"
                  name="currentValue"
                  value={formData.currentValue}
                  onChange={handleLocalInputChange}
                  placeholder="Current value"
                  className="bg-gray-800 border-gray-700 text-white placeholder-gray-500"
                  min="0"
                  step="any"
                />
              </div>
              <div className="space-y-2 pt-8">
                <span className="text-sm text-cyan-400 font-medium">
                  {formData.unit}
                </span>
              </div>
            </div>

            {formData.currentValue && formData.targetValue && (
              <div className="space-y-2">
                <div className="flex justify-between text-sm">
                  <span className="text-gray-400">Progress</span>
                  <span className="font-medium text-cyan-400">{Math.round(progress)}%</span>
                </div>
                <Progress 
                  value={progress} 
                  className="h-2 bg-gray-800"
                  indicatorClassName="bg-gradient-to-r from-cyan-400 to-purple-400"
                />
                {progress >= 100 && (
                  <Alert className="mt-2 bg-gray-800 border border-green-500">
                    <AlertDescription className="text-green-400">
                      🎉 Congratulations! You've reached your goal!
                    </AlertDescription>
                  </Alert>
                )}
              </div>
            )}
          </>
        )}

        <div className="flex justify-end gap-3 pt-4">
          <Button
            type="button"
            variant="ghost"
            onClick={onClose}
            className="text-gray-400 hover:bg-gray-800 hover:text-white"
          >
            Cancel
          </Button>
          <Button 
            type="submit"
            disabled={!Object.values(validation).every(v => v)}
            className="bg-gradient-to-r from-cyan-400 to-purple-400 text-gray-900 font-medium hover:from-cyan-500 hover:to-purple-500 disabled:opacity-50"
          >
            {isEditing ? 'Update Goal' : 'Create Goal'}
          </Button>
        </div>
      </form>
    </DialogContent>
  );
};

export default GoalForm;