// src/components/ClientInfoTab.jsx

import React, { useState, useEffect } from 'react';
import { Plus, X } from 'lucide-react';
import { Button } from './ui/Button';
import { Input } from './ui/Input';
import { Switch } from './ui/Switch';
import { Tabs, TabsList, TabsTrigger, TabsContent } from './ui/Tabs';
import { ScrollArea } from './ui/ScrollArea';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from './ui/Card';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { auth } from '../firebase';

// Import components
import ClientRoutineBuilder from './ClientRoutineBuilder';
import ClientGoalTab from './ClientGoalTab';
import ClientDietTab from './ClientDietTab';
import ClientInjuriesTab from './ClientInjuriesTab';


const ClientInfoTab = ({
  clientInfo,
  clientId,
  isAddingPreference,
  setIsAddingPreference,
  newPreference,
  setNewPreference,
  handleAddPreference,
  handleDeletePreference,
}) => {
  const [requireApproval, setRequireApproval] = useState(false);
  const [activeTab, setActiveTab] = useState('routines');

  useEffect(() => {
    fetchApprovalSetting();
  }, []);

  const fetchApprovalSetting = async () => {
    try {
      const settingsDoc = await getDoc(
        doc(db, 'users', clientId, 'settings', 'messageSettings')
      );
      if (settingsDoc.exists()) {
        const data = settingsDoc.data();
        setRequireApproval(data.requireCoachApproval || false);
      }
    } catch (error) {
      console.error('Error fetching approval setting:', error);
    }
  };

  const handleToggleApproval = async () => {
    try {
      const newValue = !requireApproval;
      setRequireApproval(newValue);
      
      // Update client's message settings
      await setDoc(
        doc(db, 'users', clientId, 'settings', 'messageSettings'),
        { requireCoachApproval: newValue },
        { merge: true }
      );

      // Update coach's AI approval setting
      const user = auth.currentUser;
      if (user) {
        await setDoc(
          doc(db, 'coaches', user.uid),
          { aiApproval: newValue },
          { merge: true }
        );
      }
    } catch (error) {
      console.error('Error updating approval setting:', error);
    }
  };

  return (
    <div className="flex flex-col h-full max-h-full">
      {/* AI Message Approval Setting */}
      <Card className="mb-6">
        <CardContent className="pt-6">
          <div className="flex items-center justify-between">
            <div className="space-y-1">
              <h4 className="text-sm font-semibold text-neutral-200">AI Message Control</h4>
              <p className="text-sm text-neutral-400">
                {requireApproval 
                  ? "Require Approval before sending AI messages"
                  : "AI messages will be sent automatically"}
              </p>
            </div>
            <Switch
              checked={requireApproval}
              onCheckedChange={handleToggleApproval}
            />
          </div>
        </CardContent>
      </Card>

      {/* Main Content Tabs */}
      <Tabs value={activeTab} onValueChange={setActiveTab} className="flex-1 flex flex-col min-h-0">
        <TabsList className="w-full grid grid-cols-4 gap-4 px-1">
          <TabsTrigger value="routines" className="px-4 py-2">
            Routines
          </TabsTrigger>
          <TabsTrigger value="goals" className="px-4 py-2">
            Goals
          </TabsTrigger>
          <TabsTrigger value="diet" className="px-4 py-2">
            Diet
          </TabsTrigger>
          <TabsTrigger value="injuries" className="px-4 py-2">
            Injuries
          </TabsTrigger>
        </TabsList>

        <div className="flex-1 min-h-0 mt-6">
          <ScrollArea className="h-full">
            <div className="px-1 pb-6">
              <TabsContent value="routines" className="mt-0">
                <ClientRoutineBuilder clientId={clientId} />
              </TabsContent>

              <TabsContent value="goals" className="mt-0">
                <ClientGoalTab clientId={clientId} />
              </TabsContent>

              <TabsContent value="diet" className="mt-0">
                <ClientDietTab clientId={clientId} />
              </TabsContent>

              <TabsContent value="injuries" className="mt-0">
                <ClientInjuriesTab clientId={clientId} />
              </TabsContent>
            </div>
          </ScrollArea>
        </div>
      </Tabs>
    </div>
  );
};

export default ClientInfoTab;