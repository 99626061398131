// src/services/workoutService.js

import { db } from '../firebase';
import { doc, collection, getDocs, Timestamp, getDoc, deleteDoc, addDoc, setDoc, updateDoc } from 'firebase/firestore';
import { sendWorkoutMessage } from './workoutMessageService';
import { getDetailedExerciseData } from './exerciseService';  


// Helper to calculate default message send time
const calculateMessageSendTime = (scheduledDate) => {
  const now = new Date();
  const scheduledDateTime = new Date(scheduledDate);
  const twoDaysFromNow = new Date();
  twoDaysFromNow.setDate(now.getDate() + 2);
  return now
  
  // if (scheduledDate < twoDaysFromNow) {
  //   // If workout is scheduled within 2 days, send immediately
  //   return now;
  // } else {
    
  //   // Otherwise, send one day before
  //   const sendDate = new Date(scheduledDateTime);
  //   sendDate.setDate(sendDate.getDate() - 1);
  //   sendDate.setHours(9, 0, 0, 0); // Set to 9 AM the day before
  //   return sendDate;
  // }
};

export const getAssignedWorkoutsForClient = async (coachId, clientId) => {
  const workoutsRef = collection(db, `users/${clientId}/workouts`);
  const snap = await getDocs(workoutsRef);
  return snap.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

export const assignCustomWorkout = async (workoutData, clientId, date, customSendTime = null) => {
  const coachId = workoutData.coachId || 'unknown';
  
  // Ensure date is valid
  const scheduledDate = date ? new Date(date) : new Date();
  const messageSendTime = customSendTime ? new Date(customSendTime) : calculateMessageSendTime(scheduledDate);

  const { name, description, headerImageUrl, sections, exercises, ...otherData } = workoutData;

  // Ensure all exercises have full details
  const processedExercises = await Promise.all(exercises.map(async (ex) => {
    
    const fullData = await getDetailedExerciseData(coachId, ex.exerciseId);
    if (fullData) {
      const instructions = fullData?.formCues?.instructions || fullData.instructions || [];
      const commonMistakes = fullData?.formCues?.commonMistakes || fullData.commonMistakes || [];
      const mediaUrl = fullData.defaultVideoUrl || fullData.mediaUrl || '';
      return {
        ...ex,
        name: fullData.name || 'Unnamed Exercise',
        instructions,
        commonMistakes,
        mediaUrl,
        equipmentNeeded: fullData.equipmentNeeded || [],
        tags: fullData.tags || []
      };
    }
    return { ...ex, name: "Unnamed Exercise", instructions: [], commonMistakes: [], mediaUrl: '' };
  }));

  const processedExercisesWithSets = processedExercises.map((ex) => {
    const sets = ex.sets?.map((s) => ({
      reps: s.reps !== '' ? Number(s.reps) : null,
      weight: s.weight !== '' ? Number(s.weight) : null,
      actualReps: s.actualReps !== '' ? Number(s.actualReps) : null,
      actualWeight: s.actualWeight !== '' ? Number(s.actualWeight) : null,
      completed: s.completed ?? false
    })) || [];

    return {
      ...ex,
      sets
    };
  });


  const assignedWorkout = {
    name,
    description,
    headerImageUrl,
    sections,
    exercises: processedExercisesWithSets,
    ...otherData,
    userId: clientId,
    dateAssigned: Timestamp.now(),
    scheduledTime: Timestamp.fromDate(scheduledDate),
    messageSendTime: Timestamp.fromDate(messageSendTime),
    messageStatus: 'pending',
    status: 'assigned',
    originalTemplateId: null,
    isTemplate: false,
    dateStarted: null,
    dateCompleted: null,
    coachId,
    exerciseCount: processedExercisesWithSets.length,
    duration: calculateWorkoutDuration(processedExercisesWithSets)
  };

  const workoutRef = collection(db, `users/${clientId}/workouts`);
  const docRef = await addDoc(workoutRef, assignedWorkout);
  const workoutId = docRef.id;

  await updateDoc(docRef, { id: workoutId });

  if (messageSendTime <= new Date()) {
    await sendWorkoutMessage(clientId, { ...assignedWorkout, id: workoutId });
  }

  return { ...assignedWorkout, id: workoutId };
};

export const updateAssignedWorkout = async (coachId, clientId, workoutId, workoutData, date, customSendTime = null) => {
  // Ensure date is valid
  const scheduledDate = date ? new Date(date) : new Date();
  const messageSendTime = customSendTime ? new Date(customSendTime) : calculateMessageSendTime(scheduledDate);

  const { name, description, headerImageUrl, sections, exercises, ...otherData } = workoutData;

  // 1. Ensure all exercises have full details
  const processedExercises = await Promise.all(
    exercises.map(async (ex) => {
      const fullData = await getDetailedExerciseData(coachId, ex.exerciseId);
      if (fullData) {
        const instructions = fullData?.formCues?.instructions || fullData.instructions || [];
        const commonMistakes = fullData?.formCues?.commonMistakes || fullData.commonMistakes || [];
        const mediaUrl = fullData.defaultVideoUrl || fullData.mediaUrl || '';
        return {
          ...ex,
          name: fullData.name || 'Unnamed Exercise',
          instructions,
          commonMistakes,
          mediaUrl,
          equipmentNeeded: fullData.equipmentNeeded || [],
          tags: fullData.tags || []
        };
      }
      return {
        ...ex,
        name: "Unnamed Exercise",
        instructions: [],
        commonMistakes: [],
        mediaUrl: ''
      };
    })
  );

  // 2. Force each set to have actualReps, actualWeight, and completed
  const processedExercisesWithSets = processedExercises.map((ex) => {
    const sets = ex.sets?.map((s) => ({
      reps: s.reps !== '' ? Number(s.reps) : null,
      weight: s.weight !== '' ? Number(s.weight) : null,
      actualReps: s.actualReps !== '' ? Number(s.actualReps) : null,
      actualWeight: s.actualWeight !== '' ? Number(s.actualWeight) : null,
      completed: s.completed ?? false
    })) || [];

    return {
      ...ex,
      sets
    };
  });

  // 3. Build the updatedWorkout doc
  const updatedWorkout = {
    name,
    description,
    headerImageUrl,
    sections,
    exercises: processedExercisesWithSets,
    ...otherData,
    updatedAt: Timestamp.now(),
    scheduledTime: Timestamp.fromDate(scheduledDate),
    messageSendTime: Timestamp.fromDate(messageSendTime),
    exerciseCount: processedExercisesWithSets.length,
    duration: calculateWorkoutDuration(processedExercisesWithSets),
    isTemplate: false
  };

  const workoutRef = doc(db, 'users', clientId, 'workouts', workoutId);
  await setDoc(workoutRef, updatedWorkout, { merge: true });

  // 5. If the message time is now or past AND the doc isn't already marked 'sent', send
  const currentDoc = await getDoc(workoutRef);
  const currentWorkout = currentDoc.data();
  if (messageSendTime <= new Date() && currentWorkout?.messageStatus !== 'sent') {
    await sendWorkoutMessage(clientId, { ...updatedWorkout, id: workoutId });
    await updateDoc(workoutRef, { messageStatus: 'sent' });
  }

  return workoutId;
};


export const deleteAssignedWorkout = async (coachId, clientId, workoutId) => {
  try {
    const workoutRef = doc(db, `users/${clientId}/workouts`, workoutId);
    await deleteDoc(workoutRef);
    return true;
  } catch (error) {
    console.error('Error deleting workout:', error);
    throw error;
  }
};

const calculateWorkoutDuration = (exercises) => {
  const totalSets = exercises.reduce((sum, ex) => sum + (ex.sets?.length || 0), 0);
  const avgRestTime = exercises.reduce((sum, ex) => sum + (parseInt(ex.rest) || 60), 0) / exercises.length;
  return Math.round((totalSets * 45 + totalSets * avgRestTime) / 60);
};

export const assignTemplateToClient = async (templateId, clientId, date, customSendTime = null) => {
  const template = await getWorkoutTemplate(templateId);
  
  if (template.isRecurring) {
    const startDate = new Date(date);
    const assignments = [];
    
    for (let week = 0; week < template.recurringWeeks; week++) {
      Object.entries(template.recurringDays).forEach(([day, isSelected]) => {
        if (isSelected) {
          const dayIndex = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].indexOf(day);
          const currentDate = new Date(startDate);
          currentDate.setDate(startDate.getDate() + (week * 7) + ((7 + dayIndex - startDate.getDay()) % 7));
          
          assignments.push(
            assignCustomWorkout(template, clientId, currentDate, customSendTime)
          );
        }
      });
    }

    return Promise.all(assignments);
  } else {
    return assignCustomWorkout(template, clientId, date, customSendTime);
  }
};


