// src/components/TemplateCreation.jsx

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useAuth } from '../contexts/AuthContext';
import { Button } from './ui/Button';
import { Input } from './ui/Input';
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';
import { Plus, Trash2, Image as ImageIcon, Edit } from 'lucide-react';
import ExerciseSearch from './ExerciseSearch';
import { createWorkoutTemplate, getWorkoutTemplate, updateWorkoutTemplate } from '../services/workoutTemplateService';
import  ModifyExerciseOverlay  from './overlays/ModifyExerciseOverlay';
import  CreateExerciseOverlay  from './overlays/CreateExerciseOverlay';
import { getAllExercises } from '../services/exerciseService';
import { modifyExercise, createCustomExercise } from '../services/exerciseService';
import ExpandedSetsEditor from './ExpandedSetsEditor';

window.onerror = function(msg, url, lineNo, columnNo, error) {
  console.warn('GLOBAL ERROR CAUGHT:', {
      message: msg,
      url: url,
      lineNo: lineNo,
      columnNo: columnNo,
      error: error
  });
  return false;
};


const TemplateCreation = ({ isEdit }) => {
  const { user } = useAuth();
  const { templateId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { returnToAssignment, onComplete } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [headerImage, setHeaderImage] = useState(null);
  const [headerImagePreview, setHeaderImagePreview] = useState(null);
  const [showExerciseEdit, setShowExerciseEdit] = useState(false);
  const [exerciseToEdit, setExerciseToEdit] = useState(null);
  const [showModifyOverlay, setShowModifyOverlay] = useState(false);
  const [showCreateOverlay, setShowCreateOverlay] = useState(false);
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [allExercises, setAllExercises] = useState([]);

  useEffect(() => {
    const fetchExercises = async () => {
      try {
        const exercises = await getAllExercises(user.uid);
        setAllExercises(exercises);
      } catch (error) {
        console.error('Error fetching exercises:', error, {
          userId: user.uid,
          timestamp: new Date().toISOString(),
          stack: error.stack
        });
      }
    };
  
    fetchExercises();
  }, [user.uid]);
    

  const [templateData, setTemplateData] = useState(() => {
    // Only get from session storage if editing
    try{
    if (isEdit) {
      const saved = sessionStorage.getItem("templateData");
      if (saved) {
        return JSON.parse(saved);
      }
    }
  }catch(error){
    console.error('Error getting template data from sessionStorage:', error);
  }
    // Always use default empty state for new templates
    return {
      name: '',
      description: '',
      headerImageUrl: '',
      sections: [],
    };
  });

  // Clear session storage when component unmounts
  useEffect(() => {
    return () => {
      if (!isEdit) {
        sessionStorage.removeItem("templateData");
      }
    };
  }, [isEdit]);

  // keep saving form state to session storage when the form changes
  useEffect(() => {
    try {
      sessionStorage.setItem("templateData", JSON.stringify(templateData));
    } catch (error) {
      console.error('Error saving to sessionStorage:', error);
    }
  }, [templateData]);

  useEffect(() => {
    let isMounted = true;

    const fetchWorkoutTemplate = async () => {
      try {
        const workout = await getWorkoutTemplate(user.uid, templateId);

        if (workout) {
          setTemplateData({
            name: workout.name,
            description: workout.description,
            headerImageUrl: workout.headerImageUrl,
            sections: workout.sections.map(section => ({
              ...section,
              exercises: workout.exercises
                .filter(exercise => exercise.sectionName == section.name)
                .map(exercise => ({
                  exerciseId: exercise.exerciseId,
                  sets: exercise.sets,
                  rest: exercise.rest,
                  notes: exercise.notes,
                  order: exercise.order
                }))
            })),
          });
          setHeaderImagePreview(workout.headerImageUrl);
        }
      } catch (error) {
        console.error('Error fetching template:', error, {
        templateId,
        userId: user.uid,
        timestamp: new Date().toISOString()
      });
      }
    }

    if (isEdit) {
      fetchWorkoutTemplate()
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setHeaderImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setHeaderImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const addSection = (type) => {
    const newSection = {
      id: `${type}-${Date.now()}`,
      type,
      name: type === 'warmup' ? 'Warm Up' 
           : type === 'cooldown' ? 'Cool Down' 
           : `Block ${String.fromCharCode(65 + templateData.sections.filter(s => s.type === 'block').length)}`,
      exercises: []
    };
  
    setTemplateData(prev => {
      let newSections;
      if (type === 'warmup') {
        newSections = [newSection, ...prev.sections]; 
      } else {
        newSections = [...prev.sections, newSection];
      }
      return { ...prev, sections: newSections };
    });
  };

  const removeSection = (sectionId) => {
    setTemplateData(prev => ({
      ...prev,
      sections: prev.sections.filter(section => section.id !== sectionId)
    }));
  };

  const addExercise = (sectionId) => {
    setTemplateData(prev => ({
      ...prev,
      sections: prev.sections.map(section => {
        if (section.id === sectionId) {
          return {
            ...section,
            exercises: [
              ...section.exercises,
              {
                exerciseId: '',
                sets: Array(3).fill().map(() => ({ reps: 8, weight: 0 })),
                rest: 60,
                notes: '',
                order: section.exercises.length // or section.exercises.length + 1, just be consistent
              }
            ]            
          };
        }
        return section;
      })
    }));
  };

  const updateExercise = (sectionId, exerciseIndex, field, value) => {
    try {
      console.log('Updating exercise:', { sectionId, exerciseIndex, field, value });
      setTemplateData(prev => {
        console.log('Previous template data:', prev);
        const newData = {
          ...prev,
          sections: prev.sections.map(section => {
            if (section.id === sectionId) {
              return {
                ...section,
                exercises: section.exercises.map((exercise, i) =>
                  i === exerciseIndex ? { ...exercise, [field]: value } : exercise
                )
              };
            }
            return section;
          })
        };
        console.log('New template data:', newData);
        return newData;
      });
    } catch (error) {
      console.error('Error in updateExercise:', error, {
        sectionId,
        exerciseIndex,
        field,
        value,
        timestamp: new Date().toISOString(),
        stack: error.stack
      });
    }
  };  

  const removeExercise = (sectionId, exerciseIndex) => {
    setTemplateData(prev => ({
      ...prev,
      sections: prev.sections.map(section => {
        if (section.id === sectionId) {
          return {
            ...section,
            exercises: section.exercises.filter((_, i) => i !== exerciseIndex)
          };
        }
        return section;
      })
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      let headerImageUrl = templateData.headerImageUrl;
      if (headerImage) {
        const imageRef = ref(storage, `coaches/${user.uid}/images/templates/${Date.now()}-${headerImage.name}`);
        await uploadBytes(imageRef, headerImage);
        headerImageUrl = await getDownloadURL(imageRef);
      }
  
      // Flatten exercises
      const allExercises = templateData.sections.flatMap((section, sectionIndex) =>
        section.exercises.map((exercise, exerciseIndex) => ({
          ...exercise,
          sectionId: section.id,
          sectionType: section.type,
          sectionName: section.name,
          order: sectionIndex * 1000 + exerciseIndex 
        }))
      );
  
      const formattedTemplateData = {
        name: templateData.name,
        headerImageUrl,
        description: templateData.description,
        sections: templateData.sections.map(section => ({
          id: section.id,
          type: section.type,
          name: section.name
        })),
        exercises: allExercises
      };
  
      let savedTemplate;
      if (isEdit) {
        savedTemplate = await updateWorkoutTemplate(user.uid, templateId, formattedTemplateData);
      } else {
        savedTemplate = await createWorkoutTemplate(user.uid, formattedTemplateData);
      }

      sessionStorage.removeItem("templateData");

      // Check if we came from client assignment flow
      const clientId = location.state?.clientId;
      if (clientId) {
        // Go back to template management overlay with the client context
        navigate(-1, { 
          state: { 
            newTemplate: savedTemplate,
            clientId: clientId
          }
        });
      } else {
        // Regular template creation, go to templates page
        navigate('/templates');
      }
    } catch (error) {
      console.error('Error creating template:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditExercise = (exercise) => {
    setSelectedExercise(exercise);
    if (exercise) {
      setShowModifyOverlay(true);
    } else {
      setShowCreateOverlay(true);
    }
  };
  
  const handleSaveExercise = async (formData) => {
    try {
      let savedExercise;
      if (selectedExercise) {
        // Modifying existing exercise
        savedExercise = await modifyExercise(user.uid, selectedExercise.id, formData);

        setAllExercises(prev => prev.map(ex => 
          ex.id === selectedExercise.id 
            ? { ...ex, ...formData, isModified: true }
            : ex
        ));
  
      } else {
        // Creating new exercise
        savedExercise = await createCustomExercise(user.uid, formData);

        setAllExercises(prev => [...prev, { ...savedExercise, isCustom: true }]);
      }

      const exerciseId = selectedExercise ? selectedExercise.id : savedExercise.id;
      
      // For ClientWorkoutEditOverlay.jsx
      // if (workoutData) {
      //   setWorkoutData(prev => ({
      //     ...prev,
      //     exercises: prev.exercises.map(exercise =>
      //       exercise.exerciseId === exerciseId
      //         ? { ...exercise, name: formData.name }
      //         : exercise
      //     )
      //   }));
      // }

    } catch (error) {
      console.error('Error saving exercise:', error);
    }
  };

  return (
    <div className="min-h-screen bg-black text-white pt-20 px-4">
      <Card className="max-w-4xl mx-auto bg-neutral-900 border-0">
        <CardHeader>
          <CardTitle>Create New Template</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-4">
              <Input
                placeholder="Template Name"
                value={templateData.name}
                onChange={(e) => setTemplateData(prev => ({ ...prev, name: e.target.value }))}
                className="bg-neutral-800 border-neutral-700"
                required
              />
              
              <Input
                placeholder="Description"
                value={templateData.description}
                onChange={(e) => setTemplateData(prev => ({ ...prev, description: e.target.value }))}
                className="bg-neutral-800 border-neutral-700"
              />

              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-400">Header Image</label>
                <div className="flex items-center space-x-4">
                  <Button
                    type="button"
                    onClick={() => document.getElementById('header-image').click()}
                    className="bg-neutral-800 hover:bg-neutral-700"
                  >
                    <ImageIcon className="h-4 w-4 mr-2" />
                    Choose Image
                  </Button>
                  <input
                    id="header-image"
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    className="hidden"
                  />
                </div>
                {headerImagePreview && (
                  <div className="mt-2">
                    <img
                      src={headerImagePreview}
                      alt="Header preview"
                      className="max-h-40 rounded-lg object-cover"
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-semibold">Template Sections</h3>
                <div className="flex space-x-2">
                  {!templateData.sections.some(s => s.type === 'warmup') && (
                    <Button
                      type="button"
                      onClick={() => addSection('warmup')}
                      className="bg-neutral-800 hover:bg-neutral-700"
                    >
                      Add Warm Up
                    </Button>
                  )}
                  <Button
                    type="button"
                    onClick={() => addSection('block')}
                    className="bg-neutral-800 hover:bg-neutral-700"
                  >
                    Add Block
                  </Button>
                  {!templateData.sections.some(s => s.type === 'cooldown') && (
                    <Button
                      type="button"
                      onClick={() => addSection('cooldown')}
                      className="bg-neutral-800 hover:bg-neutral-700"
                    >
                      Add Cool Down
                    </Button>
                  )}
                </div>
              </div>

              {templateData.sections.map((section) => (
                <div
                  key={section.id}
                  className="border border-neutral-700 rounded-lg p-4 space-y-4"
                >
                  <div className="flex items-center justify-between">
                    <h4 className="text-lg font-medium">{section.name}</h4>
                    {section.type !== 'main' && (
                      <Button
                        type="button"
                        variant="ghost"
                        onClick={() => removeSection(section.id)}
                        className="text-red-500 hover:text-red-400"
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                      )}
                  </div>

                  {section.exercises.map((exercise, index) => (
                    <div 
                      key={`${section.id}-${index}`}
                      className="border border-neutral-700 rounded-lg p-4 space-y-4"
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex-1 mr-4">
                        <ExerciseSearch
                          value={exercise.exerciseId}
                          onChange={(value) => updateExercise(section.id, index, 'exerciseId', value)}
                          className="w-full"
                          onEditExercise={handleEditExercise}
                          allExercises={allExercises} // Pass the latest exercises to ExerciseSearch
                        />
                        </div>
                        <Button
                          type="button"
                          variant="ghost"
                          onClick={() => removeExercise(section.id, index)}
                          className="text-red-500 hover:text-red-400"
                        >
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      </div>

                      <ExpandedSetsEditor
                        sets={exercise.sets}
                        onChange={(newSets) => updateExercise(section.id, index, 'sets', newSets)}
                      />

                      <div>
                        <label className="text-sm text-gray-400">Rest (seconds)</label>
                        <Input
                          type="number"
                          value={exercise.rest}
                          onChange={(e) => updateExercise(section.id, index, 'rest', parseInt(e.target.value))}
                          className="bg-neutral-800 border-neutral-700"
                          min="0"
                          required
                        />
                      </div>

                      <Input
                        placeholder="Notes (form cues, variations, etc.)"
                        value={exercise.notes}
                        onChange={(e) => updateExercise(section.id, index, 'notes', e.target.value)}
                        className="bg-neutral-800 border-neutral-700"
                      />
                    </div>
                  ))}

                  <Button
                    type="button"
                    onClick={() => addExercise(section.id)}
                    className="w-full bg-neutral-800 hover:bg-neutral-700"
                  >
                    <Plus className="h-4 w-4 mr-2" />
                    Add Exercise to {section.name}
                  </Button>
                </div>
              ))}
            </div>

            <Button
              type="submit"
              disabled={loading}
              className="w-full bg-blue-600 hover:bg-blue-700 flex gap-2"
            >
              {loading ? <div
								className="inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-muted-foreground border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
								role="status"
							/> : <></>}
              {isEdit ? 'Save Template' : 'Create Template'}
            </Button>
          </form>
        </CardContent>
      </Card>

      {showModifyOverlay && (
        <ModifyExerciseOverlay
          exercise={selectedExercise}
          onSave={handleSaveExercise}
          onClose={() => {
            setShowModifyOverlay(false);
            setSelectedExercise(null);
          }}
        />
      )}

      {showCreateOverlay && (
        <CreateExerciseOverlay
          onSave={handleSaveExercise}
          onClose={() => {
            setShowCreateOverlay(false);
            setSelectedExercise(null);
          }}
        />
      )}
    </div>
  );
};

export default TemplateCreation;
