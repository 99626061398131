// src/components/onboarding/NameUsernamePage.jsx

import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { User, AtSign, Hexagon } from 'lucide-react';
import { doc, updateDoc, getDocs, collection, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const NameUsernamePage = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    username: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();

  const checkUsernameExists = async (username) => {
    const coachesRef = collection(db, 'coaches');
    const q = query(coachesRef, where('username', '==', username));
    const snapshot = await getDocs(q);
    return !snapshot.empty;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { firstName, lastName, username } = formData;

    if (!firstName || !lastName || !username) {
      alert('Please fill in all fields');
      return;
    }

    try {
      setIsLoading(true);
      
      const exists = await checkUsernameExists(username);
      if (exists) {
        alert('Username already exists. Please choose another one.');
        setIsLoading(false);
        return;
      }

      const coachRef = doc(db, 'coaches', user.uid);
      await updateDoc(coachRef, {
        firstName,
        lastName,
        username,
        updatedAt: new Date()
      });

      navigate('/account');
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Error updating profile. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 flex items-center justify-center px-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-md"
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          className="text-center mb-12"
        >
          <Hexagon className="w-12 h-12 text-indigo-500 mx-auto mb-4" />
          <h1 className="text-5xl font-bold text-white mb-2 tracking-tight">Welcome.</h1>
          <p className="text-gray-400 text-lg">Let's set up your profile</p>
        </motion.div>

        <form onSubmit={handleSubmit} className="space-y-8">
          <div className="relative">
            <User className="absolute top-3 left-0 text-gray-500" size={20} />
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={(e) => setFormData(prev => ({ ...prev, firstName: e.target.value }))}
              className="w-full pl-8 pb-3 text-lg bg-transparent border-b border-gray-700 focus:border-indigo-500 text-white placeholder-gray-500 focus:outline-none transition duration-300"
              placeholder="First Name"
              required
            />
          </div>
          <div className="relative">
            <User className="absolute top-3 left-0 text-gray-500" size={20} />
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={(e) => setFormData(prev => ({ ...prev, lastName: e.target.value }))}
              className="w-full pl-8 pb-3 text-lg bg-transparent border-b border-gray-700 focus:border-indigo-500 text-white placeholder-gray-500 focus:outline-none transition duration-300"
              placeholder="Last Name"
              required
            />
          </div>
          <div className="relative">
            <AtSign className="absolute top-3 left-0 text-gray-500" size={20} />
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={(e) => setFormData(prev => ({ ...prev, username: e.target.value }))}
              className="w-full pl-8 pb-3 text-lg bg-transparent border-b border-gray-700 focus:border-indigo-500 text-white placeholder-gray-500 focus:outline-none transition duration-300"
              placeholder="Choose a username"
              required
            />
          </div>

          <motion.button
            whileHover={{ scale: 1.02, boxShadow: "0 0 20px rgba(79, 70, 229, 0.4)" }}
            whileTap={{ scale: 0.98 }}
            type="submit"
            disabled={isLoading}
            className="w-full bg-indigo-600 text-white py-2.5 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 transition duration-300 ease-in-out transform hover:-translate-y-1 shadow-lg text-lg font-semibold mt-8"
          >
            {isLoading ? 'Setting up...' : 'Complete Setup'}
          </motion.button>
        </form>
      </motion.div>
    </div>
  );
};

export default NameUsernamePage;
