// src/components/MessagesPage.jsx

import React, { useState, useEffect, useRef , useCallback} from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Add this import
import {
  collection,
  doc,
  getDocs,
  query,
  orderBy,
  deleteDoc,
  addDoc,
  getDoc,
  where,
  updateDoc,
  serverTimestamp,
  onSnapshot  // Add this import
} from 'firebase/firestore';
import { Tour, useTour } from '@reactour/tour';
import { db, functions } from '../firebase';
import { Input } from './ui/Input';
import { Button } from './ui/Button';
import { httpsCallable } from 'firebase/functions';
import { Send, Info, X, HelpCircle } from 'lucide-react';
import NavigationBar from './NavigationBar';
import { useAuth } from '../contexts/AuthContext';

import Joyride from 'react-joyride';

const MessagingSteps = [
  {
    selector: '[data-tour="main-mess"]', // Changed from target to selector
    content: "Here is where client messages will show up. The pending messages section is where you can approve/edit/cancel the AI message response. Based off these actions, the AI will improve over time.",
    styles: {
      options: {
        backgroundColor: '#1C1C1C',
        textColor: '#fff',
        primaryColor: '#6495ED',
      },
      tooltipContainer: {
        textAlign: 'left',
        padding: '1rem',
      },
      tooltipTitle: {
        color: 'white',
      },
      buttonNext: {
        backgroundColor: '#6495ED',
        color: 'white',
      },
      buttonBack: {
        color: '#6495ED',
      },
    }
  },
  {
    selector: '[data-tour="add-mess"]', // Changed from target to selector
    content: "You can also respond to messages in the app on the coach side of the IOS app.",
    styles: {
      options: {
        backgroundColor: '#1C1C1C',
        textColor: '#fff',
        primaryColor: '#6495ED',
      },
      tooltipContainer: {
        textAlign: 'left',
        padding: '1rem',
      },
      tooltipTitle: {
        color: 'white',
      },
      buttonNext: {
        backgroundColor: '#6495ED',
        color: 'white',
      },
      buttonBack: {
        color: '#6495ED',
      },
    }
  }
];

const MessagesPage = () => {
  const { user, isAdmin } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const currentPage = location.pathname.substring(1);
  const [pendingMessages, setPendingMessages] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [aiResponse, setAiResponse] = useState('');
  const [currentPendingMessageId, setCurrentPendingMessageId] = useState(null);
  const chatContainerRef = useRef(null);
  const { setIsOpen, setSteps, isOpen } = useTour();
  const [hasSeenTour, setHasSeenTour] = useState(false);


  const inactivityTime = 15 * 1000; // 15 seconds in milliseconds
  const inactivityTimeoutRef = useRef(null);

  const startTour = useCallback(() => {
    console.log('Starting tour...');
    setSteps(MessagingSteps);
    setIsOpen(true);
  }, [setSteps, setIsOpen]);

  const handleTourClose = useCallback(() => {
    setIsOpen(false);
    localStorage.setItem('messages-tour', 'completed');
    setHasSeenTour(true);
  }, [setIsOpen]);

  
  useEffect(() => {
    let heartbeatInterval;

    const updateLastActive = async () => {
      if (user) {
        const coachDocRef = doc(db, 'coaches', user.uid);
        await updateDoc(coachDocRef, {
          lastActive: serverTimestamp(),
        });
      }
    };

    updateLastActive(); // Update immediately when component mounts

    heartbeatInterval = setInterval(updateLastActive, 5000); // Update every 5 seconds

    // Cleanup function
    return () => {
      clearInterval(heartbeatInterval);
    };
  }, [user]);

  useEffect(() => {
    fetchPendingMessages();
    fetchConversations();
  }, []);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatMessages]);

  useEffect(() => {
    const checkAndStartTour = async () => {
      const tourSeen = localStorage.getItem('messages-tour') === 'completed';
      setHasSeenTour(tourSeen);
      
      if (!tourSeen) {
        startTour();
      }
    };

    checkAndStartTour();

    return () => {
      setIsOpen(false); // Ensure tour is closed when component unmounts
    };
  }, [startTour, setIsOpen]);

  useEffect(() => {
    if (!isOpen) {
      handleTourClose();
    }
  }, [isOpen, handleTourClose]);



  const fetchPendingMessages = async () => {
    try {
      const pendingMessagesRef = collection(db, 'coaches', user.uid, 'pendingMessages');
      const q = query(pendingMessagesRef, orderBy('timestamp', 'asc'));
      const querySnapshot = await getDocs(q);
      const messages = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPendingMessages(messages);
    } catch (error) {
      console.error('Error fetching pending messages:', error);
    }
  };

  const fetchConversations = async () => {
    try {
      const coachDoc = await getDoc(doc(db, 'coaches', user.uid));
      const coachData = coachDoc.data();
      if (coachData.clients && coachData.clients.length > 0) {
        const clientPromises = coachData.clients.map(async (clientId) => {
          const clientDoc = await getDoc(doc(db, 'users', clientId));
          const clientData = clientDoc.data();
          return {
            clientId,
            name: clientData.name || clientData.email || clientData.phoneNumber || 'Unnamed Client',
          };
        });
        const clients = await Promise.all(clientPromises);
        setConversations(clients);
      } else {
        setConversations([]); // Ensure conversations is empty if no clients
      }
    } catch (error) {
      console.error('Error fetching conversations:', error);
    }
  };

  const handleSelectClient = async (client) => {
    setSelectedClient(client);
    setMessage('');
    setAiResponse('');
    setCurrentPendingMessageId(null);
    await fetchConversation(client.clientId);
  };

  const fetchConversation = async (clientId) => {
    try {
      const messagesRef = collection(db, 'users', clientId, 'messages');
      const q = query(messagesRef, orderBy('date_sent', 'asc'));
      
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const messages = snapshot.docs.map((doc) => {
          const data = doc.data();
          const isFromCoach = data.sender_id === data.coach_id;
          return {
            id: doc.id,
            text: isFromCoach ? data.aiResponse || data.content : data.content,
            sender: isFromCoach ? 'coach' : 'client',
            time: data.date_sent?.toDate().toLocaleString() || new Date().toLocaleString(),
          };
        });        
        console.log('Processed messages:', messages);
        setChatMessages(messages);

        if (chatContainerRef.current) {
          chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
      });

      return unsubscribe;
    } catch (error) {
      console.error('Error setting up message listener:', error);
    }
  };

  async function handleSendMessage(clientId, messageContent, pendingMessageId = null) {
    try {
      // Create message document
      const messageDoc = {
        content: messageContent,
        is_system_message: false,
        date_sent: serverTimestamp(),
        media_url: "",
        source: "web",
        client_id: clientId,
        coach_id: user.uid,
        sender_id: user.uid  // Since this is from coach
      };
  
  
  
      // Add message to user's messages collection
      const messageRef = collection(db, 'users', clientId, 'messages');
      await addDoc(messageRef, messageDoc);
  
      if (pendingMessageId) {
        const pendingMessageRef = doc(db, 'coaches', user.uid, 'pendingMessages', pendingMessageId);
        const pendingMessageDoc = await getDoc(pendingMessageRef);
        
        if (pendingMessageDoc.exists()) {
          const pendingMessageData = pendingMessageDoc.data();
          await saveExchange(
            clientId, 
            messageContent, 
            pendingMessageData.aiResponse, 
            pendingMessageData.userMessage
          );
        }
        
        await deleteDoc(pendingMessageRef);
        setPendingMessages((prev) => prev.filter((msg) => msg.id !== pendingMessageId));
      }
  
      // Update chat messages state
      setChatMessages((prev) => [
        ...prev,
        {
          id: new Date().getTime().toString(),
          text: messageContent,
          sender: 'coach',
          time: new Date().toLocaleString(),
        },
      ]);
      
      setMessage('');
      setAiResponse('');
      setCurrentPendingMessageId(null);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  }
  

  const saveExchange = async (clientId, coachResponse, aiResponse, userMessage) => {
    try {
      const editsRequired = coachResponse !== aiResponse;
      const exchangeData = {
        coachResponse,
        aiResponse,
        userMessage,
        editsRequired,
        timestamp: new Date(),
      };
      await addDoc(collection(db, 'coaches', user.uid, 'messageExchanges'), exchangeData);
    } catch (error) {
      console.error('Error saving exchange:', error);
    }
  };

  const handleQuickSend = async (pendingMessage) => {
    await handleSendMessage(
      pendingMessage.clientId,
      pendingMessage.aiResponse,
      pendingMessage.id
    );
    fetchPendingMessages();
  };

  const handleCancelPendingMessage = async (pendingMessageId) => {
    try {
      // Delete the pending message from Firestore
      const pendingMessageRef = doc(db, 'coaches', user.uid, 'pendingMessages', pendingMessageId);
      await deleteDoc(pendingMessageRef);
  
      // Update state
      setPendingMessages((prev) => prev.filter((msg) => msg.id !== pendingMessageId));
      setMessage('');
      setAiResponse('');
      setCurrentPendingMessageId(null);
    } catch (error) {
      console.error('Error canceling pending message:', error);
    }
  };

  const onNavigate = (path) => {
    navigate(`/${path}`);
  };

  const onSelectClient = (clientId) => {
    navigate(`/client/${clientId}`);
  };


  return (
    <div className="bg-[#000000] text-[#E0E0E0] h-screen pt-14 flex flex-col">
      {hasSeenTour && (
        <button
          onClick={startTour}
          className="fixed bottom-4 right-4 bg-blue-500 hover:bg-blue-600 text-white p-3 rounded-full w-12 h-12 flex items-center justify-center transition-colors z-50"
          aria-label="Start Tour"
        >
          <HelpCircle className="h-10 w-10"/>
        </button>
      )}

      {/* Main Content Area */}
      <div className="flex flex-grow overflow-hidden p-6">
        {/* Left Side - Messages List */}
        <div 
          id="pending-messages-section"
          className="w-1/3 border-r border-gray-700 flex flex-col overflow-y-auto"
        >
          <div 
            id="messages-response-section"
            className="flex-1"
          >
            {/* Pending Messages Section */}
            <h2 className="text-lg font-bold mb-2 sticky top-0 bg-[#000000] pl-4" data-tour="main-mess">
              <div data-tour="add-mess"></div>
              Pending Messages
            </h2>
            {pendingMessages.map((message) => (
              <div
                key={message.id}
                className="p-4 border-b border-gray-700 flex items-center justify-between hover:bg-gray-800 cursor-pointer" 
              >
                <div 
                  className="flex-1 min-w-0 overflow-hidden"
                  onClick={() => handleSelectClient({ clientId: message.clientId })}
                >
                  <div className="text-white font-bold truncate">
                    {message.clientEmail || message.clientPhoneNumber || 'Unknown Sender'}
                  </div>
                  <div className="text-gray-400 truncate">{message.userMessage}</div>
                  <div className="text-gray-500 truncate">{message.aiResponse}</div>
                </div>
                <div className="flex-shrink-0 flex">
                  <Button
                    variant="ghost"
                    className="text-[#6495ED] hover:bg-[#333333]"
                    onClick={() => handleQuickSend(message)}
                  >
                    <Send size={20} />
                  </Button>
                  <Button
                    variant="ghost"
                    className="text-[#6495ED] hover:bg-[#333333]"
                    onClick={() => handleCancelPendingMessage(message.id)}
                  >
                    <X size={20} />
                  </Button>
                </div>
              </div>
            ))}

            {/* Conversations Section */}
            <h2 className="text-lg font-bold mt-4 mb-2 sticky top-0 bg-[#000000]"  >
              Conversations
            </h2>
            {conversations.map((client) => (
              <div
                key={client.clientId}
                className="p-4 border-b border-gray-700 cursor-pointer hover:bg-gray-800"
                onClick={() => handleSelectClient(client)}
              >
                <div className="text-white font-bold">{client.name}</div>
              </div>
            ))}
          </div>
        </div>

        {/* Right Side - Chat Interface */}
        <div className="w-2/3 flex flex-col h-full">
          {selectedClient ? (
            <>
              {/* Header */}
              <div className="flex-none flex items-center justify-between p-4 border-b border-gray-700">
                <h2 className="text-xl font-bold">{selectedClient.name}</h2>
                <Button
                  variant="ghost"
                  className="text-[#6495ED] hover:bg-[#333333]"
                  onClick={() => onSelectClient(selectedClient.clientId)}
                >
                  <Info size={24} />
                </Button>
              </div>

              {/* Chat Messages Container */}
              <div ref={chatContainerRef} className="flex-grow overflow-y-auto pr-2">
                {chatMessages.map((msg) => (
                  <div
                    key={msg.id}
                    className={`mb-3 ${msg.sender === 'client' ? 'text-left' : 'text-right'}`}
                  >
                    <div
                      className={`inline-block p-3 rounded-lg ${
                        msg.sender === 'client'
                          ? 'bg-[#333333] text-white'
                          : 'bg-[#7AB8FD] text-black'
                      }`}
                    >
                      {msg.text}
                    </div>
                    <div className="text-xs text-[#808080] mt-1">{msg.time}</div>
                  </div>
                ))}
              </div>

              {/* Message Input Bar */}
              <div className="flex-none p-4 flex items-center border-t border-gray-700">
                <Input
                  type="text"
                  placeholder="Type your message..."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className="flex-grow mr-2 bg-[#333333] border-[#4F4F4F] text-[#FFFFFF] focus:ring-[#6495ED] focus:border-[#6495ED]"
                />
                <Button
                  onClick={() =>
                    handleSendMessage(
                      selectedClient.clientId,
                      message,
                      currentPendingMessageId
                    )
                  }
                  className="bg-[#6495ED] hover:bg-[#4F7BE8]"
                  
                >
                  <Send size={20} />
                </Button>
              </div>
            </>
          ) : (
            <div className="text-gray-500 flex items-center justify-center h-full">
              Select a message to view details.

            </div>
          )}
        </div>
      </div>
   
     
    </div>
  );
};
      

export default MessagesPage;
