// src/components/ui/ScrollArea.jsx

import React from 'react';
import { cn } from '../../lib/utils';

const ScrollArea = React.forwardRef(({ className, children, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'relative overflow-auto',
      '[&::-webkit-scrollbar]:w-2',
      '[&::-webkit-scrollbar-track]:bg-neutral-800',
      '[&::-webkit-scrollbar-thumb]:bg-neutral-600',
      '[&::-webkit-scrollbar-thumb]:rounded-full',
      '[&::-webkit-scrollbar-thumb]:border-2',
      '[&::-webkit-scrollbar-thumb]:border-neutral-800',
      'hover:[&::-webkit-scrollbar-thumb]:bg-neutral-500',
      className
    )}
    {...props}
  >
    {children}
  </div>
));

ScrollArea.displayName = 'ScrollArea';

export { ScrollArea };