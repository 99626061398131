// src/components/App.jsx
import React from 'react';  
import { BrowserRouter } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { AuthProvider } from '../contexts/AuthContext';
import { TourProvider } from '@reactour/tour';  
import { tours } from '../tours';
import AppRoutes from './AppRoutes';

const stripePromise = loadStripe('pk_live_51PmzEsJp9datwQ8XX5QXruYq4HeCdzi5qLpPTOJ6DauNGUe4RicW0vtsCiyow8gqjubN2NtcB9w5jr7ycWtgMAaR00wFdVnC4K');

const STRIPE_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap',
    },
  ],
  appearance: {
    theme: 'night',
    variables: {
      colorPrimary: '#6495ED',
      colorBackground: '#1C1C1C',
      colorText: '#FFFFFF',
      colorDanger: '#ef4444',
      fontFamily: 'Inter, system-ui, sans-serif',
    },
  },
};

const App = () => {
  return (
    
    <TourProvider
    steps={[]} 
    styles={{
      popover: (base) => ({
        ...base,
        background: '#1C1C1C',
        color: 'white',
        borderRadius: '8px',
        padding: '16px',
      }),
    }}>
      <AuthProvider>
        <BrowserRouter>
          <Elements stripe={stripePromise} options={STRIPE_OPTIONS}>
          
            <AppRoutes />
          </Elements>
        </BrowserRouter>
      </AuthProvider>
    </TourProvider>
  );
};

export default App;