// src/components/Signup.jsx

import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { User, Lock, Mail, Hexagon } from 'lucide-react';
import { 
  createUserWithEmailAndPassword, 
  signOut,
  updateCurrentUser // <-- import updateCurrentUser
} from 'firebase/auth';
import {
  doc,
  serverTimestamp,
  writeBatch,
  increment,
  query,
  where,
  collection,
  getDocs,
  limit
} from 'firebase/firestore';
import { useNavigate, Link } from 'react-router-dom';
import { auth, db } from '../firebase';
import { API_URLS } from '../constants/api';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [referralError, setReferralError] = useState('');
  const navigate = useNavigate();

  // Calls your private Cloud Function. Requires the user to be authenticated.
  const validateReferralCode = async (code) => {
    const token = await auth.currentUser.getIdToken();
    const response = await fetch(API_URLS.validateReferralCode, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ code })
    });
    if (!response.ok) {
      throw new Error('Invalid referral code');
    }
    return response.json();
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    setReferralError('');
    setIsLoading(true);

    let userCredential = null;
    try {
      // 1) Create user in Firebase Auth
      userCredential = await createUserWithEmailAndPassword(auth, email, password);

      // 2) If user entered a referral code, validate it
      if (referralCode.trim()) {
        try {
          // Ensure the just-created user is recognized as currentUser
          await updateCurrentUser(auth, userCredential.user);

          // Force-refresh the ID token so it can be used in validateReferralCode
          await userCredential.user.getIdToken(true);

          await validateReferralCode(referralCode.trim());
        } catch (error) {
          console.error('Invalid referral code:', error);
          
          setReferralError('That referral code is invalid. Please try again.');
          await userCredential.user.delete();
          await signOut(auth);
          setIsLoading(false);
          return;
        }
      }

      // 3) Proceed with Firestore logic
      const batch = writeBatch(db);
      const coachRef = doc(db, 'coaches', userCredential.user.uid);
      const messageStyleRef = doc(db, 'coaches', userCredential.user.uid, 'philosophy', 'messageStyleSettings');


      let referredBy = null;
      if (referralCode.trim()) {
        const referralsQuery = query(
          collection(db, 'referrals'),
          where('code', '==', referralCode.trim()),
          limit(1)
        );
        const referralSnapshot = await getDocs(referralsQuery);
        if (!referralSnapshot.empty) {
          referredBy = referralSnapshot.docs[0].data().createdBy;
        }
      }


      batch.set(coachRef, {
        email: userCredential.user.email,
        isCoach: true,
        isAdmin: false,
        subscriptionStatus: 'inactive',
        subscriptionQuantity: 0,
        numberOfClients: 0,
        clients: [],
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        stripeCustomerId: '',
        hasHadTrial: false,
        referralCodeUsed: referralCode.trim() || null,
        referredBy: referredBy,
        hasProcessedReferralReward: false,
        firstTime: true,
        firstTimeDashboard: true,
      });

      batch.set(messageStyleRef, {
        toneStyle: ['Encouraging', 'Professional'],
        emojiUse: false,
        grammarLevel: 'Perfect Grammar',
        languageStyle: 'Motivational',
        exampleConversation: {},
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });

      if (referralCode.trim() !== '' && referredBy) {  // Only update if we found a valid referral
        const referralsQuery = query(
          collection(db, 'referrals'),
          where('code', '==', referralCode.trim()),
          limit(1)
        );
        const referralSnapshot = await getDocs(referralsQuery);
        if (!referralSnapshot.empty) {
          const referralDoc = referralSnapshot.docs[0];
          batch.update(referralDoc.ref, {
            usageCount: increment(1),
            lastUsed: serverTimestamp(),
            'metadata.lastUsedBy': userCredential.user.uid,
          });
        }
      }      

      // 4) Commit Firestore batch
      await batch.commit();

      // 5) Navigate to next page
      navigate('/onboarding/setup');
    } catch (error) {
      console.error('Error signing up:', error);
      alert('Error signing up: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 flex items-center justify-center px-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-md"
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          className="text-center mb-12"
        >
          <Hexagon className="w-12 h-12 text-indigo-500 mx-auto mb-4" />
          <h1 className="text-5xl font-bold text-white mb-2 tracking-tight">Join Evolv.</h1>
          <p className="text-gray-400 text-lg">Begin Your Journey</p>
        </motion.div>

        {referralError && (
          <div className="mb-4 p-3 rounded bg-red-900/40 border border-red-700 text-red-200">
            {referralError}
          </div>
        )}

        <form onSubmit={handleSignup} className="space-y-8">
          <div className="relative">
            <Mail className="absolute top-3 left-0 text-gray-500" size={20} />
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full pl-8 pb-3 text-lg bg-transparent border-b border-gray-700 
                         focus:border-indigo-500 text-white placeholder-gray-500 
                         focus:outline-none transition duration-300"
              placeholder="Email"
              required
            />
          </div>
          <div className="relative">
            <Lock className="absolute top-3 left-0 text-gray-500" size={20} />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full pl-8 pb-3 text-lg bg-transparent border-b border-gray-700 
                         focus:border-indigo-500 text-white placeholder-gray-500 
                         focus:outline-none transition duration-300"
              placeholder="Password"
              required
            />
          </div>
          <div className="relative">
            <User className="absolute top-3 left-0 text-gray-500" size={20} />
            <input
              type="text"
              value={referralCode}
              onChange={(e) => setReferralCode(e.target.value)}
              className="w-full pl-8 pb-3 text-lg bg-transparent border-b border-gray-700 
                         focus:border-indigo-500 text-white placeholder-gray-500 
                         focus:outline-none transition duration-300"
              placeholder="Referral Code (optional)"
            />
          </div>

          <motion.button
            whileHover={{ scale: 1.02, boxShadow: '0 0 20px rgba(79, 70, 229, 0.4)' }}
            whileTap={{ scale: 0.98 }}
            type="submit"
            disabled={isLoading}
            className="w-full bg-indigo-600 text-white py-2.5 rounded-lg hover:bg-indigo-700
                       focus:outline-none focus:ring-2 focus:ring-indigo-500
                       focus:ring-opacity-50 transition duration-300 ease-in-out transform
                       hover:-translate-y-1 shadow-lg text-lg font-semibold mt-8"
          >
            {isLoading ? 'Creating Account...' : 'Create Account'}
          </motion.button>
        </form>

        <div className="mt-6 text-center">
          <p className="text-sm text-gray-500">
            Already have an account?{' '}
            <Link to="/" className="text-indigo-400 hover:text-indigo-300 transition duration-300">
              Sign In
            </Link>
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default Signup;