import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from './ui/Button';
import { Copy } from 'lucide-react';

const NavigationBar = ({ isAdmin }) => {
  const [showCopiedToast, setShowCopiedToast] = useState(false);

  const handleCopyAppLink = () => {
    navigator.clipboard.writeText('https://apps.apple.com/us/app/evolv/id6739216841');
    setShowCopiedToast(true);
    setTimeout(() => setShowCopiedToast(false), 2000);
  };

  return (
    <nav className="fixed top-0 left-0 right-0 z-50 bg-black py-4 border-b-0">
      <div className="container mx-auto px-4 flex items-center justify-between">
        <Button
          variant="ghost"
          onClick={handleCopyAppLink}
          className="text-blue-500 hover:bg-blue-500/10 flex items-center gap-2 transition-colors"
        >
          <Copy size={16} />
          <span className="hidden sm:inline">Copy Client App Link</span>
        </Button>

        <div className="space-x-4">
          <NavLink to="/dashboard" data-tour="dashboard">
            {({ isActive }) => (
              <Button
                variant="ghost"
                className={`${
                  isActive ? 'text-blue-500' : 'text-white'
                } hover:bg-neutral-800`}
              >
                Dashboard
              </Button>
            )}
          </NavLink>

          {isAdmin && (
            <NavLink to="/coaches">
              {({ isActive }) => (
                <Button
                  variant="ghost"
                  className={`${
                    isActive ? 'text-blue-500' : 'text-white'
                  } hover:bg-neutral-800`}
                >
                  Coaches
                </Button>
              )}
            </NavLink>
          )}

          <NavLink to="/messages" data-tour="messages">
            {({ isActive }) => (
              <Button
                variant="ghost"
                className={`${
                  isActive ? 'text-blue-500' : 'text-white'
                } hover:bg-neutral-800`}
              >
                Messages
              </Button>
            )}
          </NavLink>

          <NavLink to="/schedule" data-tour="calendar">
            {({ isActive }) => (
              <Button
                variant="ghost"
                className={`${
                  isActive ? 'text-blue-500' : 'text-white'
                } hover:bg-neutral-800`}
              >
                Calendar
              </Button>
            )}
          </NavLink>

          <NavLink to="/account" data-tour="account-tab">
            {({ isActive }) => (
              <Button
                variant="ghost"
                className={`${
                  isActive ? 'text-blue-500' : 'text-white'
                } hover:bg-neutral-800`}
              >
                Account
              </Button>
            )}
          </NavLink>
        </div>

        <div className="w-[140px]" />
      </div>

      {showCopiedToast && (
        <div className="fixed top-20 left-1/2 transform -translate-x-1/2 bg-blue-500 text-white px-4 py-2 rounded-md shadow-lg">
          Link copied to clipboard!
        </div>
      )}
    </nav>
  );
};

export default NavigationBar;