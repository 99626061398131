// src/components/ui/Switch.jsx

import React from 'react';

export const Switch = ({ checked, onCheckedChange, className }) => {
  return (
    <button
      onClick={() => onCheckedChange(!checked)}
      className={`relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none ${className} ${
        checked ? 'bg-blue-600' : 'bg-gray-400'
      }`}
    >
      <span
        className={`transform transition-transform inline-block w-5 h-5 bg-white rounded-full shadow ${
          checked ? 'translate-x-5' : 'translate-x-1'
        }`}
      ></span>
    </button>
  );
};
