// src/components/Message/components/AutomatedMessageConfig.jsx


import React, { useState } from 'react';
import { Card, CardContent } from '../../../components/ui/Card';
import { Button } from '../../../components/ui/Button';
import { Input } from '../../../components/ui/Input';
import { Label } from '../../../components/ui/Label';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../../../components/ui/Tabs';
import ClientScheduleCard from '../../../components/ui/ClientScheduleCard';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../../components/ui/Select';
import { Switch } from '../../../components/ui/Switch';
import {
  Plus,
  Trash2,
  Clock,
  Bell,
  Target,
  ChevronRight,
  RotateCcw,
} from 'lucide-react';

const clientSchedules = {
  client1: [
    { label: 'Wake Up', time: '7:30 AM' },
    { label: 'Morning Snack', time: '10:00 AM' },
    { label: 'Workout', time: '11:00 AM' },
    { label: 'Lunch Break', time: '1:00 PM' },
    { label: 'Bed Time', time: '10:00 PM' },
  ],
  client2: [
    { label: 'Wake Up', time: '6:30 AM' },
    { label: 'Workout', time: '7:00 AM' },
    { label: 'Breakfast', time: '8:30 AM' },
    { label: 'Lunch', time: '12:00 PM' },
    { label: 'Dinner', time: '6:00 PM' },
  ],
  client3: [
    { label: 'Wake Up', time: '8:00 AM' },
    { label: 'Breakfast', time: '9:00 AM' },
    { label: 'Workout', time: '5:00 PM' },
    { label: 'Dinner', time: '7:00 PM' },
    { label: 'Bed Time', time: '11:00 PM' },
  ]
};




const TemplateItem = ({ template, index, templates, setTemplates, removeTemplate }) => {
  return (
    <div className="group relative">
      <Input
        value={template}
        onChange={(e) => {
          const newTemplates = [...templates];
          newTemplates[index] = e.target.value;
          setTemplates(newTemplates);
        }}
        className="bg-neutral-900 border-neutral-800 text-white pr-12 hover:border-neutral-700 focus:border-white transition-colors"
      />
      <Button
        variant="ghost"
        size="sm"
        onClick={() => removeTemplate(index)}
        className="absolute right-2 top-1/2 -translate-y-1/2 text-neutral-400 hover:text-red-400 opacity-0 group-hover:opacity-100 transition-opacity"
      >
        <Trash2 className="w-4 h-4" />
      </Button>
    </div>
  );
};

const CheckInConfig = ({
  label,
  enabled,
  onToggle,
  time,
  onTimeChange,
  repeat,
  onRepeatChange,
}) => {
  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between p-4 rounded-lg bg-neutral-900 border border-neutral-800">
        <div className="space-y-0.5">
          <div className="text-white font-light text-lg">{label} Check-ins</div>
          <div className="text-neutral-400 text-sm">Routine check-in messages</div>
        </div>
        <Switch checked={enabled} onCheckedChange={onToggle} />
      </div>

      {/* Check-in schedule settings */}
      <div className="space-y-4">
        <Label className="text-neutral-400 text-sm">Check-in Schedule</Label>
        <div className="grid grid-cols-2 gap-4">
          {/* Time */}
          <div className="p-4 rounded-lg bg-neutral-900 border border-neutral-800 space-y-2">
            <div className="flex items-center gap-2 text-neutral-400">
              <Clock className="w-4 h-4" />
              <span className="text-sm">Time</span>
            </div>
            <Input
              type="time"
              value={time}
              onChange={(e) => onTimeChange(e.target.value)}
              className="bg-transparent border-0 p-0 text-lg font-light text-white"
            />
          </div>

          {/* Repeat */}
          <div className="p-4 rounded-lg bg-neutral-900 border border-neutral-800 space-y-2">
            <div className="flex items-center gap-2 text-neutral-400">
              <RotateCcw className="w-4 h-4" />
              <span className="text-sm">Repeat</span>
            </div>
            <Select value={repeat} onValueChange={onRepeatChange}>
              <SelectTrigger className="bg-transparent border-0 p-0 text-lg font-light text-white">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="daily">Daily</SelectItem>
                <SelectItem value="weekdays">Weekdays</SelectItem>
                <SelectItem value="custom">Custom</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Routine configuration section that uses the shared templates state.
 */
const RoutineConfig = ({ templates, setTemplates, removeTemplate, onClientSelected }) => {
  const [selectedClient, setSelectedClient] = useState('');

  // MORNING & EVENING states (time, repeat, switch enabled).
  const [morningEnabled, setMorningEnabled] = useState(false);
  const [morningTime, setMorningTime] = useState('08:00');
  const [morningRepeat, setMorningRepeat] = useState('daily');

  const [eveningEnabled, setEveningEnabled] = useState(false);
  const [eveningTime, setEveningTime] = useState('20:00');
  const [eveningRepeat, setEveningRepeat] = useState('daily');

  // Handling client selection
  const handleClientChange = (value) => {
    setSelectedClient(value);
    if (onClientSelected) {
      onClientSelected(value, clientSchedules[value]);
    }
  };

  return (
    <div className="space-y-8">
      {/* ───── SELECT CLIENT ───── */}
      <div className="space-y-4">
        <Label className="text-neutral-400 text-sm">Select Client</Label>
        <Select 
        value={selectedClient} 
        onValueChange={handleClientChange}
        defaultValue=""
        >
          <SelectTrigger className="bg-neutral-800 border-0 text-white">
            <SelectValue placeholder="Choose a client..." />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="">No Client</SelectItem>
            {/* Updated to show "John Doe" explicitly */}
            <SelectItem value="client1">John Doe</SelectItem>
            <SelectItem value="client2">Jane Smith</SelectItem>
            <SelectItem value="client3">Client 3</SelectItem>
          </SelectContent>
        </Select>
      </div>

      {/* ───── TAB SWITCHER FOR MORNING vs EVENING ───── */}
      <Tabs defaultValue="morning" className="space-y-4">
        <TabsList className="bg-[#121212] p-1 rounded-lg mb-4 border border-neutral-800">
          <TabsTrigger
            value="morning"
            className="text-neutral-400 data-[state=active]:bg-white data-[state=active]:text-black flex-1"
          >
            Morning
          </TabsTrigger>
          <TabsTrigger
            value="evening"
            className="text-neutral-400 data-[state=active]:bg-white data-[state=active]:text-black flex-1"
          >
            Evening
          </TabsTrigger>
        </TabsList>

        {/* Morning Check-ins */}
        <TabsContent value="morning">
          <CheckInConfig
            label="Morning"
            enabled={morningEnabled}
            onToggle={(val) => setMorningEnabled(val)}
            time={morningTime}
            onTimeChange={setMorningTime}
            repeat={morningRepeat}
            onRepeatChange={setMorningRepeat}
          />
        </TabsContent>

        {/* Evening Check-ins */}
        <TabsContent value="evening">
          <CheckInConfig
            label="Evening"
            enabled={eveningEnabled}
            onToggle={(val) => setEveningEnabled(val)}
            time={eveningTime}
            onTimeChange={setEveningTime}
            repeat={eveningRepeat}
            onRepeatChange={setEveningRepeat}
          />
        </TabsContent>
      </Tabs>

      {/* ───── AUTO-VARY & RANDOMIZE SETTINGS ───── */}
      <div className="space-y-4">
        <div className="flex items-center justify-between p-4 rounded-lg bg-neutral-900 border border-neutral-800">
          <Label className="text-neutral-400 text-sm">Auto Vary Messages</Label>
          <Switch />
        </div>
        <div className="flex items-center justify-between p-4 rounded-lg bg-neutral-900 border border-neutral-800">
          <Label className="text-neutral-400 text-sm">Randomize Sending Time</Label>
          <Switch />
        </div>
        <div className="space-y-4">
          <Label className="text-neutral-400 text-sm">Set Time Block</Label>
          <div className="grid grid-cols-2 gap-4">
            <Input
              type="time"
              defaultValue="09:00"
              className="bg-transparent border-0 p-0 text-lg font-light text-white"
            />
            <Input
              type="time"
              defaultValue="12:00"
              className="bg-transparent border-0 p-0 text-lg font-light text-white"
            />
          </div>
        </div>
      </div>

      {/* ───── MESSAGE TEMPLATES ───── */}
      <div className="space-y-4">
        <Label className="text-neutral-400 text-sm">Message Templates</Label>
        <div className="space-y-3">
          {templates.map((template, index) => (
            <TemplateItem
              key={index}
              template={template}
              index={index}
              templates={templates}
              setTemplates={setTemplates}
              removeTemplate={removeTemplate}
            />
          ))}
          <Button
            variant="outline"
            className="w-full bg-neutral-900 border-neutral-800 text-white hover:bg-neutral-800 hover:text-white"
            onClick={() => setTemplates([...templates, ''])}
          >
            <Plus className="w-4 h-4 mr-2" />
            Add Template
          </Button>
        </div>
      </div>
    </div>
  );
};

const GoalConfig = () => (
  <div className="space-y-8">
    <div className="flex items-center justify-between p-4 rounded-lg bg-neutral-900 border border-neutral-800">
      <div className="space-y-0.5">
        <div className="text-white font-light text-lg">Progress Reminders</div>
        <div className="text-neutral-400 text-sm">Send reminders for unmet goals</div>
      </div>
      <Switch />
    </div>
    <div className="space-y-4">
      <Label className="text-neutral-400 text-sm">Reminder Conditions</Label>
      <div className="p-4 rounded-lg bg-neutral-900 border border-neutral-800 space-y-4">
        <div className="flex items-center gap-4">
          <Select defaultValue="75">
            <SelectTrigger className="w-32 bg-neutral-800 border-0">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="50">50%</SelectItem>
              <SelectItem value="75">75%</SelectItem>
              <SelectItem value="90">90%</SelectItem>
            </SelectContent>
          </Select>
          <span className="text-neutral-400">of daily goal unmet by</span>
          <Input
            type="time"
            defaultValue="18:00"
            className="w-32 bg-neutral-800 border-0"
          />
        </div>
      </div>
    </div>
    <div className="space-y-4">
      <Label className="text-neutral-400 text-sm">Message Templates</Label>
      <div className="space-y-3">
        <Input
          className="bg-neutral-900 border-neutral-800 text-white"
          defaultValue="You're {remaining}g away from your protein goal! How about a {suggestion}?"
        />
        <Input
          className="bg-neutral-900 border-neutral-800 text-white"
          defaultValue="Quick reminder: {remaining} more steps to hit your daily goal!"
        />
        <Button
          variant="outline"
          className="w-full bg-neutral-900 border-neutral-800 text-white hover:bg-neutral-800 hover:text-white"
        >
          <Plus className="w-4 h-4 mr-2" />
          Add Template
        </Button>
      </div>
    </div>
  </div>
);


/**
 * Main wrapper that switches between Routine & Goal config, 
 * holding the `templates` state at this parent level.
 */
const AutomatedMessageConfig = ({ templates: initialTemplates, onClientSelected }) => {
  const [templates, setTemplates] = useState(initialTemplates);
  const [activeCard, setActiveCard] = useState('routine');
  const [selectedClientSchedule, setSelectedClientSchedule] = useState(null);


  // Helper to remove a template by index
  const removeTemplate = (index) => {
    setTemplates((prevTemplates) => prevTemplates.filter((_, i) => i !== index));
  };

  const handleClientSelection = (clientId, schedule) => {
    if (onClientSelected) {
      onClientSelected(clientId);
    }
    setSelectedClientSchedule(schedule);
  };

  const ConfigCard = ({ icon: Icon, title, description, isActive, onClick }) => (
    <button
      onClick={onClick}
      className={`w-full flex items-center justify-between p-4 rounded-lg border ${
        isActive
          ? 'bg-neutral-800 border-white/20'
          : 'bg-neutral-900 border-neutral-800 hover:bg-neutral-800'
      } transition-all group`}
    >
      <div className="flex items-center gap-4">
        <div
          className={`p-3 rounded-full ${
            isActive ? 'bg-white' : 'bg-neutral-800 group-hover:bg-neutral-700'
          }`}
        >
          <Icon className={`w-5 h-5 ${isActive ? 'text-black' : 'text-white'}`} />
        </div>
        <div className="text-left">
          <h3 className="text-white font-light text-lg">{title}</h3>
          <p className="text-neutral-400 text-sm">{description}</p>
        </div>
      </div>
      <ChevronRight
        className={`w-5 h-5 ${
          isActive ? 'text-white' : 'text-neutral-400 group-hover:text-white'
        } transition-colors`}
      />
    </button>
  );

  return (
    <div className="space-y-6">
      {/* Card Switcher */}
      <div className="grid grid-cols-2 gap-4">
        <ConfigCard
          icon={Bell}
          title="Routine Check-ins"
          description="Configure daily check-in messages"
          isActive={activeCard === 'routine'}
          onClick={() => setActiveCard('routine')}
        />
        <ConfigCard
          icon={Target}
          title="Goal Tracking"
          description="Set up goal-based reminders"
          isActive={activeCard === 'goal'}
          onClick={() => setActiveCard('goal')}
        />
      </div>

      <Card className="bg-[#121212] border-neutral-800">
        <CardContent className="pt-6">
          {activeCard === 'routine' ? (
            <RoutineConfig
              templates={templates}
              setTemplates={setTemplates}
              removeTemplate={removeTemplate}
              onClientSelected={handleClientSelection}
            />
          ) : (
            <GoalConfig />
          )}
        </CardContent>
      </Card>
      {selectedClientSchedule && (
        <ClientScheduleCard schedule={selectedClientSchedule} />
      )}
    </div>
  );
};


export default AutomatedMessageConfig;
