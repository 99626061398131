import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown } from 'lucide-react';
import { cn } from '../../lib/utils';

const SelectTrigger = React.forwardRef(({ children, className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "relative flex h-9 w-full items-center justify-between rounded-md border border-neutral-700 bg-neutral-800 px-3 py-2 text-sm",
      className
    )}
    {...props}
  >
    {children}
  </div>
));
SelectTrigger.displayName = 'SelectTrigger';

const SelectContent = React.forwardRef(({ children, className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "absolute z-50 w-full mt-1 rounded-md border border-neutral-700 bg-neutral-800 py-1",
      className
    )}
    {...props}
  >
    {children}
  </div>
));
SelectContent.displayName = 'SelectContent';

const SelectValue = React.forwardRef(({ children, placeholder, className, ...props }, ref) => (
  <span
    ref={ref}
    className={cn(
      "block truncate",
      !children && "text-neutral-500",
      className
    )}
    {...props}
  >
    {children || placeholder}
  </span>
));
SelectValue.displayName = 'SelectValue';

const Select = React.forwardRef(({
  value,
  onValueChange,
  children,
  placeholder,
  className,
  ...props
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  // Find the option whose 'value' matches the currently selected 'value'
  const selectedOption = React.Children.toArray(children).find(
    (child) => React.isValidElement(child) && child.props.value === value
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={selectRef} className="relative">
      <SelectTrigger
        ref={ref}
        onClick={() => setIsOpen((prev) => !prev)}
        className={className}
        {...props}
      >
        <SelectValue placeholder={placeholder}>
          {selectedOption?.props.children}
        </SelectValue>
        <ChevronDown
          className={cn(
            "h-4 w-4 opacity-50 transition-transform",
            isOpen && "transform rotate-180"
          )}
        />
      </SelectTrigger>

      {isOpen && (
        <SelectContent>
          {React.Children.map(children, (child) => {
            if (!React.isValidElement(child)) return null;
            return React.cloneElement(child, {
              onClick: () => {
                onValueChange?.(child.props.value);
                setIsOpen(false);
              },
              selected: child.props.value === value
            });
          })}
        </SelectContent>
      )}
    </div>
  );
});
Select.displayName = 'Select';

const SelectItem = React.forwardRef(({
  value,
  children,
  onClick,
  selected,
  className,
  ...props
}, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        "relative flex w-full cursor-pointer select-none items-center px-3 py-1.5 text-sm outline-none hover:bg-neutral-700 focus:bg-neutral-700",
        selected && "bg-neutral-700",
        className
      )}
      onClick={onClick}
      {...props}
    >
      {children}
    </div>
  );
});
SelectItem.displayName = 'SelectItem';

export {
  Select,
  SelectTrigger,
  SelectContent,
  SelectValue,
  SelectItem
};
