import React, { useState, useEffect,useCallback } from 'react';
import { Button } from './ui/Button';
import { Card } from './ui/Card';
import { ChevronLeft, ChevronRight, Plus, User, Eye, HelpCircle, MessageCircle } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { collection, getDocs,getDoc,docs,doc } from 'firebase/firestore';
import { db } from '../firebase';
import TemplateManagementOverlay from './overlays/TemplateManagementOverlay';
import { getAssignedWorkoutsForClient } from '../services/workoutService';
import ClientSearch from './ClientSearch';
import { Alert, AlertDescription } from './ui/Alert';
import ClientWorkoutEditOverlay from './overlays/ClientWorkoutEditOverlay';
import { Tour, useTour } from '@reactour/tour';
import {calendarSteps} from '../tours';
import Joyride from 'react-joyride';


const CalendarPage = () => {
  const { user } = useAuth();
  const [clients, setClients] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [showTemplateOverlay, setShowTemplateOverlay] = useState(false);
  const [showClientWorkoutEdit, setShowClientWorkoutEdit] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [assignedWorkouts, setAssignedWorkouts] = useState([]);
  const [editWorkoutData, setEditWorkoutData] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [showError, setShowError] = useState(false);
  const { setSteps, setIsOpen, setCurrentStep } = useTour();
  
  

  const startTour = useCallback(() => {
    // Reset tour state before starting
    setCurrentStep(0);
    setSteps(calendarSteps);
    
    // Small delay to ensure state is reset
    setTimeout(() => {
      setIsOpen(true);
    }, 100);
  }, [setSteps, setIsOpen, setCurrentStep]);

  // Check for first time visit
  useEffect(() => {
    const hasSeenTour = localStorage.getItem('hasSeenTour-calendar');
    if (!hasSeenTour && user) {
      startTour();
      localStorage.setItem('hasSeenTour-calendar', 'true');
    }
  }, [startTour, user]);

  // Handle tour completion
  const handleTourCallback = (data) => {
    const { status, type } = data;
    if (status === 'finished' || status === 'skipped') {
      setIsOpen(false);
      setCurrentStep(0);
    }
  };

  // Get first day of the month for calendar calculations
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const startingDayIndex = firstDayOfMonth.getDay(); // 0-6 (Sunday-Saturday)
  
  // Get the number of days in the current month
  const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();

  useEffect(() => {
    const fetchClients = async () => {
      if (!user?.uid) return;
    
      try {
        
        const coachRef = doc(db, 'coaches', user.uid);
        const coachSnap = await getDoc(coachRef);
        
        if (coachSnap.exists()) {
          const coachData = coachSnap.data();
          const clientList = coachData.clients || [];
          
          console.log('Found clients:', clientList);
          setClients(clientList);
          if (clientList.length > 0) {
            setSelectedClientId(clientList[0].id);
          }
        }
      } catch (error) {
        
      }
    };
  
    if (user?.uid) {
      fetchClients();
    }
  }, [user]);

  useEffect(() => {
    const loadWorkouts = async () => {
      if (user?.uid && selectedClientId) {
        const workouts = await getAssignedWorkoutsForClient(user.uid, selectedClientId);
        setAssignedWorkouts(workouts);
      }
    };
    loadWorkouts();
  }, [user, selectedClientId]);

  const handlePreviousMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1));
  };

  const handleDateClick = (date) => {
    if (!selectedClientId) {
      setShowError(true);
      // Auto-hide error after 5 seconds
      setTimeout(() => setShowError(false), 5000);
      return;
    }
    console.log('Selected date:', date); // Debug log
  const today = new Date();
  console.log('Today:', today);
    setShowError(false);
    setSelectedDate(date);
    setShowTemplateOverlay(true);
    setSelectedTemplate(null);
    setShowClientWorkoutEdit(false);
    setEditWorkoutData(null);
  };

  const handleTemplateSelected = (template) => {
    console.log('Template selected:', template); // Debug log
    setSelectedTemplate(template);
    setShowTemplateOverlay(false);
    setShowClientWorkoutEdit(true); // Open the workout edit overlay
  };

  const handleWorkoutAssigned = async () => {
    console.log('Workout assigned'); // Debug log
    setShowTemplateOverlay(false);
    setShowClientWorkoutEdit(false);
    // Refresh the workouts list
    if (user?.uid && selectedClientId) {
      const workouts = await getAssignedWorkoutsForClient(user.uid, selectedClientId);
      setAssignedWorkouts(workouts);
    }
  };

  const handleWorkoutCardClick = (workout) => {
    if (!selectedClientId) {
      setShowError(true);
      setTimeout(() => setShowError(false), 5000);
      return;
    }
    setShowError(false);
    setEditWorkoutData(workout);
    setShowClientWorkoutEdit(true);
    setShowTemplateOverlay(false);
  };

  const handleCloseOverlays = () => {
    setShowTemplateOverlay(false);
    setShowClientWorkoutEdit(false);
    setSelectedTemplate(null);
    setEditWorkoutData(null);
  };

  const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  // Create calendar days array including empty cells for proper alignment
  const calendarDays = [...Array(startingDayIndex).fill(null), ...Array(daysInMonth).keys()].map((day, index) => {
    if (day === null) return null;
    return new Date(currentDate.getFullYear(), currentDate.getMonth(), day + 1);
  });

  return (
    <div className="min-h-screen bg-black">
      <div className="container mx-auto p-4 pt-20">
        {/* Tour Component */}
        <Joyride
          steps={calendarSteps}
          run={setIsOpen}
          continuous={true}
          showProgress={true}
          showSkipButton={true}
          callback={handleTourCallback}
          styles={{
            options: {
              zIndex: 1000,
            },
          }}
        />

        {/* Help Button */}
        <button
          onClick={startTour}
          className="fixed bottom-4 right-4 bg-blue-500 hover:bg-blue-600 text-white p-3 rounded-full w-12 h-12 flex items-center justify-center transition-colors"
          aria-label="Start Tour"
        >
          <HelpCircle className="h-10 w-10"/>
        </button>
        
        {/* Error Alert */}
        {showError && (
          <Alert variant="destructive" className="mb-4 animate-in fade-in duration-300">
            <AlertDescription>
              Please select a client before assigning or editing workouts.
            </AlertDescription>
          </Alert>
        )}
        {/* Enhanced Header */}
        <div className="flex items-center justify-between mb-10">
          <div className="flex items-center space-x-6">
            <h1 className="text-5xl font-light tracking-tight text-gray-100">
              {currentDate.toLocaleString('default', { month: 'long' })} {' '}
              <span className="text-gray-400">{currentDate.getFullYear()}</span>
            </h1>
            <div className="flex space-x-2 ml-4">
              <button 
                onClick={handlePreviousMonth}
                className="p-3 rounded-full hover:bg-zinc-800 transition-all duration-300"
              >
                <ChevronLeft className="w-5 h-5 text-gray-300" />
              </button>
              <button 
                onClick={handleNextMonth}
                className="p-3 rounded-full hover:bg-zinc-800 transition-all duration-300"
              >
                <ChevronRight className="w-5 h-5 text-gray-300" />
              </button>
            </div>
          </div>
          
          <div className="flex items-center space-x-4">
            <div className="relative flex items-center">
              <User className="absolute left-3 w-4 h-4 text-gray-400" data-tour="client-search"/>
              <ClientSearch
                value={selectedClientId}
                onChange={(val) => setSelectedClientId(val)}
                className="w-72 pl-10 pr-4 py-2 bg-zinc-900 text-gray-100 rounded-xl border-zinc-700 focus:border-blue-500 transition-all duration-300"
                
             />
            </div>
            {/* <Button
              onClick={() => window.location.assign('/messageManagement')} 
              className="group flex items-center space-x-2 bg-gradient-to-r from-blue-600 to-blue-500 hover:from-blue-500 hover:to-blue-400 text-gray-100 px-6 py-2.5 rounded-xl font-medium transition-all duration-300 shadow-lg hover:shadow-blue-500/20 transform hover:-translate-y-0.5"
            >
              <MessageCircle className="w-4 h-4" />
              <span>Manage Messages</span>
            </Button> */}
            <Button
              onClick={() => window.location.assign('/templates')} 
              className="group flex items-center space-x-2 bg-gradient-to-r from-blue-600 to-blue-500 hover:from-blue-500 hover:to-blue-400 text-gray-100 px-6 py-2.5 rounded-xl font-medium transition-all duration-300 shadow-lg hover:shadow-blue-500/20 transform hover:-translate-y-0.5"
               data-tour="view-templates"
            >
              <Eye className="w-4 h-4" />
              <span>View Templates</span>
            </Button>
          </div>
        </div>


        <div className="rounded-2xl overflow-hidden border border-zinc-800 bg-zinc-900 shadow-2xl">
          <div className="grid grid-cols-7 bg-zinc-800/50">
            {weekDays.map((day) => (
              <div key={day} className="py-4 text-center text-sm font-medium text-gray-400">
                {day}
              </div>
            ))}
          </div>
          
          <div className="grid grid-cols-7" data-tour="calendar-date">
            {calendarDays.map((date, i) => {
              if (!date) return <div key={`empty-${i}`} className="bg-zinc-900 border-b border-r border-zinc-800" />;
              
              const isToday = date.toDateString() === new Date().toDateString();
              const hasWorkouts = assignedWorkouts.some(w => {
                const wDate = w.scheduledTime.toDate();
                return wDate.toDateString() === date.toDateString();
              });

              return (
                <div
                  key={i}
                  onClick={() => handleDateClick(date)}
                  className={`min-h-[140px] p-4 cursor-pointer transition-all duration-300
                    ${isToday ? 'bg-zinc-800 ring-1 ring-blue-500/20' : 'bg-zinc-900'}
                    ${hasWorkouts ? 'bg-blue-900/20' : ''}
                    hover:bg-zinc-800/50
                    border-b border-r border-zinc-800
                  `}
                >
                  <div className={`text-sm mb-3 ${
                    isToday ? 'text-blue-400 font-semibold' : 'text-gray-400'
                  }`}>
                    {date.getDate()}
                  </div>
                  <div className="space-y-2">
                    {assignedWorkouts
                      .filter(w => {
                        const wDate = w.scheduledTime.toDate();
                        return wDate.toDateString() === date.toDateString();
                      })
                      .map((w) => (
                        <div
                          key={w.id}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleWorkoutCardClick(w);
                          }}
                          className="px-3 py-2 text-xs bg-zinc-800 text-gray-300 rounded-lg hover:bg-blue-600/20 hover:text-blue-400 transition-all duration-300"
                        >
                          {w.name}
                        </div>
                      ))}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {showTemplateOverlay && !showClientWorkoutEdit && (
        <TemplateManagementOverlay
          onClose={() => setShowTemplateOverlay(false)}
          onTemplateSelect={handleTemplateSelected}
        />
      )}

      {showClientWorkoutEdit && (
        <ClientWorkoutEditOverlay
          clientId={selectedClientId}
          date={selectedDate}
          template={selectedTemplate}
          existingWorkout={editWorkoutData}
          onClose={() => {
            setShowClientWorkoutEdit(false);
            setSelectedTemplate(null);
            setSelectedDate(null);
          }}
          onWorkoutAssigned={handleWorkoutAssigned}
        />
      )}
    </div>
  );
};

export default CalendarPage;