// src/components/AccountPageTabs/RoutineBuilder.jsx

import React, { useState, useEffect } from 'react';
import { Plus, Trash2, Edit2, Clock, Sun, Moon, GripVertical, CheckSquare } from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/Card';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { v4 as uuidv4 } from 'uuid';

const RoutineBuilder = ({ userId }) => {
  const [routineType, setRoutineType] = useState('morning');
  const [editingIndex, setEditingIndex] = useState(null);
  const [morningSteps, setMorningSteps] = useState([]);
  const [eveningSteps, setEveningSteps] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  

  useEffect(() => {
    const loadData = async () => {
      if (!userId) return;
      
      setIsLoading(true);
      try {
        const docRef = doc(db, 'coaches', userId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          
          
          if (data.firstTime) {
            const demoRoutine = {
              steps: [  // Use "steps" consistently
                {
                  id: uuidv4(),
                  time: '06:00',
                  task: 'Do 50 pushups',
                  description: 'Start your day with strength training',
                  duration: 15
                },
                {
                  id: uuidv4(),
                  time: '06:15',
                  task: 'Take Vitamin C',
                  description: 'Support your immune system',
                  duration: 5
                }
              ]
            };
            

            // Save demo routine to Firestore
            await setDoc(doc(db, 'coaches', userId, 'Routines', 'morningRoutine'), demoRoutine);
          
          // Update state with demo routine
          setMorningSteps(demoRoutine.steps);
          setEveningSteps([]);
          } else {
            // Load existing routines as before
            const morningDoc = await getDoc(doc(db, 'coaches', userId, 'Routines', 'morningRoutine'));
            if (morningDoc.exists()) {
              setMorningSteps(morningDoc.data().steps || []);
            }
          
            // Load evening routine
            const eveningDoc = await getDoc(doc(db, 'coaches', userId, 'Routines', 'eveningRoutine'));
            if (eveningDoc.exists()) {
              setEveningSteps(eveningDoc.data().steps || []);
            }

          }
        }
      } catch (error) {
        console.error('Error loading routine data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [userId]);

  useEffect(() => {
    const saveRoutine = async () => {
      if (!userId || isLoading) return;
      
      const steps = routineType === 'morning' ? morningSteps : eveningSteps;
      const docRef = doc(db, 'coaches', userId, 'Routines', `${routineType}Routine`);
      
      try {
        // Save the entire document with the steps array
        await setDoc(docRef, {
          steps: steps.map(step => ({
            ...step,
            id: step.id || uuidv4() // Ensure each step has an ID
          }))
        });
      } catch (error) {
        console.error('Error saving routine data:', error);
      }
    };
  
    // Add a delay to prevent too frequent saves
    const timeoutId = setTimeout(saveRoutine, 500);
    return () => clearTimeout(timeoutId);
  }, [userId, morningSteps, eveningSteps, routineType, isLoading]);
  


  const calculateTimeOffset = (index) => {
    const steps = routineType === 'morning' ? morningSteps : eveningSteps;
    const previousSteps = steps.slice(0, index);
    return previousSteps
      .filter(step => step.type === 'timed')
      .reduce((total, step) => total + step.duration, 0);
  };

  const TimelineView = () => {
    const steps = routineType === 'morning' ? morningSteps : eveningSteps;
    const setSteps = routineType === 'morning' ? setMorningSteps : setEveningSteps;

    const getRunningTime = () => {
      return steps
        .filter(step => step.type === 'timed')
        .reduce((total, step) => total + step.duration, 0);
    };

    return (
      <div className="space-y-4">
        {steps.map((step, index) => (
          <div 
            key={index} 
            className="group flex items-start gap-4 p-4 bg-neutral-800/50 rounded-lg border border-neutral-700 hover:border-neutral-600 transition-all"
            draggable="true"
            onDragStart={(e) => e.dataTransfer.setData('text/plain', index)}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => {
              e.preventDefault();
              const fromIndex = parseInt(e.dataTransfer.getData('text/plain'));
              const toIndex = index;
              const newSteps = [...steps];
              const [movedStep] = newSteps.splice(fromIndex, 1);
              newSteps.splice(toIndex, 0, movedStep);
              setSteps(newSteps);
            }}
          >
            <div className="cursor-move text-neutral-400 hover:text-neutral-300 mt-2">
              <GripVertical className="w-5 h-5" />
            </div>
            
            <div className="flex-1">
              <div className="flex justify-between items-start mb-2">
                <div>
                  <h3 className="font-semibold text-lg text-neutral-200">{step.title}</h3>
                  <div className="flex items-center gap-2 mt-1">
                    {step.type === 'timed' ? (
                      <>
                        <Clock className="w-4 h-4 text-neutral-400" />
                        <span className="text-sm text-neutral-400">{step.duration} minutes</span>
                      </>
                    ) : (
                      <>
                        <CheckSquare className="w-4 h-4 text-neutral-400" />
                        <span className="text-sm text-neutral-400">Checklist item</span>
                      </>
                    )}
                  </div>
                </div>
                
                <div className="flex gap-2">
                  <button 
                    onClick={() => setEditingIndex(index)}
                    className="p-1.5 text-neutral-400 hover:text-blue-400 hover:bg-blue-900/30 rounded-lg transition-colors"
                  >
                    <Edit2 className="w-4 h-4" />
                  </button>
                  <button 
                    onClick={() => setSteps(steps.filter((_, i) => i !== index))}
                    className="p-1.5 text-neutral-400 hover:text-red-400 hover:bg-red-900/30 rounded-lg transition-colors"
                  >
                    <Trash2 className="w-4 h-4" />
                  </button>
                </div>
              </div>
              
              <p className="text-neutral-400 text-sm">{step.description}</p>
            </div>
          </div>
        ))}

        {steps.length > 0 && steps.some(step => step.type === 'timed') && (
          <div className="mt-6 pt-4 border-t border-neutral-700">
            <div className="flex items-center gap-2 text-neutral-400">
              <Clock className="w-4 h-4" />
              <span className="font-medium">Timed Activities Total: {getRunningTime()} minutes</span>
            </div>
          </div>
        )}
      </div>
    );
  };

  const EditModal = ({ step, index, onClose }) => {
    const steps = routineType === 'morning' ? morningSteps : eveningSteps;
    const setSteps = routineType === 'morning' ? setMorningSteps : setEveningSteps;
    const [stepType, setStepType] = useState(step.type || 'timed');

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-neutral-900 rounded-xl p-6 max-w-lg w-full m-4 border border-neutral-800">
          <h3 className="text-xl font-semibold mb-4 text-neutral-200">Edit Step</h3>
          <form onSubmit={(e) => {
            e.preventDefault();
            const newSteps = [...steps];
            newSteps[index] = {
              title: e.target.title.value,
              description: e.target.description.value,
              type: stepType,
              ...(stepType === 'timed' && { duration: parseInt(e.target.duration?.value) }),
            };
            setSteps(newSteps);
            onClose();
          }}>
            <div className="space-y-4">
              <div className="flex gap-4 mb-4">
                <button
                  type="button"
                  onClick={() => setStepType('timed')}
                  className={`flex-1 p-3 rounded-lg flex items-center justify-center gap-2 ${
                    stepType === 'timed'
                      ? 'bg-blue-600 text-white'
                      : 'bg-neutral-800 text-neutral-300 hover:bg-neutral-700'
                  }`}
                >
                  <Clock className="w-4 h-4" />
                  Timed Activity
                </button>
                <button
                  type="button"
                  onClick={() => setStepType('checklist')}
                  className={`flex-1 p-3 rounded-lg flex items-center justify-center gap-2 ${
                    stepType === 'checklist'
                      ? 'bg-blue-600 text-white'
                      : 'bg-neutral-800 text-neutral-300 hover:bg-neutral-700'
                  }`}
                >
                  <CheckSquare className="w-4 h-4" />
                  Checklist Item
                </button>
              </div>
              
              <div>
                <label className="block text-sm font-medium text-neutral-300 mb-1">Title</label>
                <input
                  name="title"
                  defaultValue={step.title}
                  className="w-full p-2 bg-neutral-800 border border-neutral-700 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-neutral-200"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-neutral-300 mb-1">Description</label>
                <textarea
                  name="description"
                  defaultValue={step.description}
                  rows={3}
                  className="w-full p-2 bg-neutral-800 border border-neutral-700 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-neutral-200"
                />
              </div>
              {stepType === 'timed' && (
                <div>
                  <label className="block text-sm font-medium text-neutral-300 mb-1">Duration (minutes)</label>
                  <input
                    name="duration"
                    type="number"
                    defaultValue={step.duration}
                    min="1"
                    className="w-full p-2 bg-neutral-800 border border-neutral-700 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-neutral-200"
                  />
                </div>
              )}
            </div>
            <div className="flex justify-end gap-3 mt-6">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-neutral-300 bg-neutral-800 rounded-lg hover:bg-neutral-700 transition-colors"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="animate-pulse text-neutral-400">Loading...</div>
      </div>
    );
  }

  const steps = routineType === 'morning' ? morningSteps : eveningSteps;
  const setSteps = routineType === 'morning' ? setMorningSteps : setEveningSteps;

  return (
    <div className="max-w-3xl mx-auto p-4">
      <div className="flex space-x-4 mb-6">
        <button
          className={`flex items-center px-4 py-2 rounded-lg transition-colors ${
            routineType === 'morning' 
              ? 'bg-blue-600 text-white' 
              : 'bg-neutral-800 text-neutral-300 hover:bg-neutral-700'
          }`}
          onClick={() => setRoutineType('morning')}
        >
          <Sun className="w-4 h-4 mr-2" />
          Morning Routine
        </button>
        <button
          className={`flex items-center px-4 py-2 rounded-lg transition-colors ${
            routineType === 'evening' 
              ? 'bg-blue-600 text-white' 
              : 'bg-neutral-800 text-neutral-300 hover:bg-neutral-700'
          }`}
          onClick={() => setRoutineType('evening')}
        >
          <Moon className="w-4 h-4 mr-2" />
          Evening Routine
        </button>
      </div>

      <Card className="border-neutral-800 bg-neutral-900/95 backdrop-blur-sm shadow-xl">
        <CardHeader className="border-b border-neutral-800 bg-neutral-900">
          <CardTitle className="text-xl font-semibold text-neutral-100">
            {routineType === 'morning' ? 'Morning' : 'Evening'} Routine
          </CardTitle>
        </CardHeader>
        <CardContent className="p-6">
          <TimelineView />
          
          <button
            onClick={() => {
              setSteps([...steps, {
                id: uuidv4(),
                title: 'New Step',
                description: '',
                type: 'timed',
                duration: 15
              }]);
              setEditingIndex(steps.length);
            }}
            className="mt-4 w-full p-3 border-2 border-dashed border-neutral-700 rounded-lg text-neutral-400 hover:border-blue-500 hover:text-blue-400 hover:bg-blue-900/20 transition-all flex items-center justify-center gap-2"
          >
            <Plus className="w-4 h-4" />
            Add New Step
          </button>
        </CardContent>
      </Card>

      {editingIndex !== null && (
        <EditModal
          step={steps[editingIndex]}
          index={editingIndex}
          onClose={() => setEditingIndex(null)}
        />
      )}
    </div>
  );
};

export default RoutineBuilder;