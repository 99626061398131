// src/components/Message/MessageManagementDashboard.jsx

import React, { useState } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../ui/Tabs';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../ui/Card';
import { Button } from '../ui/Button';
import { Input } from '../ui/Input';
import { Label } from '../ui/Label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/Select';
import { MessageCircle, Plus, Edit2, Trash2, Calendar, Clock } from 'lucide-react';
import { Badge } from '../ui/Badge';
import { Dialog } from '../ui/Dialog';
import MessageCreationDialog from './components/MessageCreationDialog';
import AutomatedMessageConfig from './components/AutomatedMessageConfig';

// Sample data (unchanged, but you can rename if desired)
const sampleData = {
  clients: [
    {
      id: 'sarah',
      name: 'Sarah Johnson',
      goals: ['protein', 'steps'],
      subscriptionLevel: 'Pro',
      lastMessage: '2024-12-20T10:00:00.000Z',
      messageHistory: [
        { date: '2024-12-19', text: "Hi Sarah, don't forget your daily protein intake!" },
        { date: '2024-12-18', text: "Great job on hitting your steps yesterday!" },
      ],
    },
    {
      id: 'mike',
      name: 'Mike Peters',
      goals: ['workout', 'protein'],
      subscriptionLevel: 'Free',
      lastMessage: '2024-12-21T10:00:00.000Z',
      messageHistory: [
        { date: '2024-12-20', text: "How's your protein intake going, Mike?" },
      ],
    },
    {
      id: 'john',
      name: 'John Smith',
      goals: ['steps', 'workout'],
      subscriptionLevel: 'Premium',
      lastMessage: '2024-12-22T10:00:00.000Z',
      messageHistory: [],
    },
  ],
  upcomingMessages: [
    {
      id: 1,
      clientName: 'Sarah Johnson',
      type: 'routine',
      message: "Morning check-in: How's the mobility routine going?",
      scheduledFor: '2024-12-28T08:00:00.000Z',
      status: 'pending',
    },
    {
      id: 2,
      clientName: 'Mike Peters',
      type: 'goal',
      message: "You're 20g away from your protein goal! Quick protein shake?",
      scheduledFor: '2024-12-28T18:00:00.000Z',
      status: 'pending',
    },
    {
      id: 3,
      clientName: 'John Smith',
      type: 'routine',
      message: 'Evening check-in: Did you complete your stretching routine?',
      scheduledFor: '2024-12-28T20:00:00.000Z',
      status: 'pending',
    },
  ],
  routineTemplates: [
    "How's the morning mobility routine going?",
    'Got your morning protein shake?',
    'Starting the day with your stretches?',
    'Time for your morning cardio!',
  ],
};


const MessageCard = ({ message }) => (
  <Card className="mb-4 bg-[#121212] border-neutral-800">
    <CardContent className="pt-6">
      <div className="flex justify-between items-start">
        <div>
          <h3 className="font-light text-lg text-white">{message.clientName}</h3>
          <p className="text-sm text-neutral-400 mt-1">{message.message}</p>
          <div className="flex gap-2 mt-2">
            <Badge variant="outline" className="flex items-center gap-1 border-neutral-700 bg-transparent">
              <Calendar className="w-3 h-3" />
              {new Date(message.scheduledFor).toLocaleDateString()}
            </Badge>
            <Badge variant="outline" className="flex items-center gap-1 border-neutral-700 bg-transparent">
              <Clock className="w-3 h-3" />
              {new Date(message.scheduledFor).toLocaleTimeString()}
            </Badge>
            <Badge
              variant={message.type === 'routine' ? 'secondary' : 'default'}
              className={message.type === 'routine' ? 'bg-neutral-800 text-white' : 'bg-white text-black'}
            >
              {message.type}
            </Badge>
          </div>
        </div>
        <div className="flex gap-2">
          <Button variant="ghost" size="icon" className="text-neutral-400 hover:text-white hover:bg-neutral-800">
            <Edit2 className="w-4 h-4" />
          </Button>
          <Button variant="ghost" size="icon" className="text-red-500 hover:text-red-400 hover:bg-neutral-800">
            <Trash2 className="w-4 h-4" />
          </Button>
        </div>
      </div>
    </CardContent>
  </Card>
);

const MessageManagementDashboard = () => {
  const [activeTab, setActiveTab] = useState('messages');
  const [isMessageDialogOpen, setIsMessageDialogOpen] = useState(false);

  // For "View All" vs. upcoming
  const [viewAll, setViewAll] = useState(false);

  // Simple filters
  const [filterClient, setFilterClient] = useState('');
  const [filterDateFrom, setFilterDateFrom] = useState('');
  const [filterDateTo, setFilterDateTo] = useState('');

  // Combined array if we "view all". For this example, we’ll just assume upcomingMessages is our main data.
  const allMessages = sampleData.upcomingMessages;

  // Filter logic (very simplistic example)
  const filteredMessages = allMessages.filter((msg) => {
    // Safely handle missing or invalid 'scheduledFor'
    if (!msg.scheduledFor) {
      return false;
    }
  
    const scheduledDate = new Date(msg.scheduledFor);
    if (isNaN(scheduledDate.getTime())) {
      return false; // skip invalid dates
    }
  
    // Filter by client
    if (filterClient && !msg.clientName.toLowerCase().includes(filterClient.toLowerCase())) {
      return false;
    }
  
    // Filter by date range
    const msgDate = scheduledDate.toISOString().split('T')[0]; // "YYYY-MM-DD"
    if (filterDateFrom && msgDate < filterDateFrom) {
      return false;
    }
    if (filterDateTo && msgDate > filterDateTo) {
      return false;
    }
  
    return true;
  });  

  // Show only next X messages if NOT "view all" (e.g., upcoming).
  const messagesToShow = viewAll ? filteredMessages : filteredMessages.slice(0, 5);

  return (
    <div className="pt-24 px-6 pb-6 max-w-6xl mx-auto bg-black min-h-screen">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-light text-white">Message Management</h1>
          <p className="text-neutral-400">Schedule and manage client communications</p>
        </div>
        <Button 
          className="bg-white text-black hover:bg-neutral-100 flex items-center gap-2"
          onClick={() => setIsMessageDialogOpen(true)}
        >
          <Plus className="w-4 h-4" />
          New Message
        </Button>
      </div>

      {/* Tabs: only "Messages" & "Automated" now */}
      <Tabs defaultValue="messages" onValueChange={(val) => setActiveTab(val)} className="w-full">
        <TabsList className="grid grid-cols-2 gap-4 mb-6 bg-[#121212] p-1 rounded-lg">
          <TabsTrigger 
            value="messages" 
            className="data-[state=active]:bg-white data-[state=active]:text-black text-neutral-400 hover:text-white"
          >
            Messages
          </TabsTrigger>
          <TabsTrigger 
            value="automated"
            className="data-[state=active]:bg-white data-[state=active]:text-black text-neutral-400 hover:text-white"
          >
            Automated
          </TabsTrigger>
        </TabsList>

        {/* Messages Tab */}
        <TabsContent value="messages" className="space-y-4">
          {/* Filter Section */}
          <div className="flex flex-col lg:flex-row lg:items-end gap-4 mb-4">
            <div className="flex-1">
              <Label className="text-neutral-400 text-sm mb-1">Filter by Client Name</Label>
              <Input
                value={filterClient}
                onChange={(e) => setFilterClient(e.target.value)}
                placeholder="Type client name (e.g. Sarah)"
                className="bg-neutral-900 border-neutral-800 text-white"
              />
            </div>
            <div>
              <Label className="text-neutral-400 text-sm mb-1">Date From</Label>
              <Input
                type="date"
                value={filterDateFrom}
                onChange={(e) => setFilterDateFrom(e.target.value)}
                className="bg-neutral-900 border-neutral-800 text-white"
              />
            </div>
            <div>
              <Label className="text-neutral-400 text-sm mb-1">Date To</Label>
              <Input
                type="date"
                value={filterDateTo}
                onChange={(e) => setFilterDateTo(e.target.value)}
                className="bg-neutral-900 border-neutral-800 text-white"
              />
            </div>
            <Button 
              variant="outline" 
              className="bg-neutral-900 border-neutral-800 text-white hover:bg-neutral-800"
              onClick={() => {
                setFilterClient('');
                setFilterDateFrom('');
                setFilterDateTo('');
              }}
            >
              Clear Filters
            </Button>
          </div>

          {/* Upcoming / All Messages */}
          {messagesToShow.length > 0 ? (
            messagesToShow.map((message) => (
              <MessageCard key={message.id} message={message} />
            ))
          ) : (
            <Card className="bg-[#121212] border-neutral-800">
              <CardContent className="pt-6">
                <div className="text-center py-8 text-neutral-400">
                  <MessageCircle className="w-12 h-12 mx-auto mb-4" />
                  <p>No messages found</p>
                </div>
              </CardContent>
            </Card>
          )}

          <div className="text-center">
            {!viewAll ? (
              <Button
                variant="outline"
                className="bg-neutral-900 border-neutral-800 text-white hover:bg-neutral-800"
                onClick={() => setViewAll(true)}
              >
                View All
              </Button>
            ) : (
              <Button
                variant="outline"
                className="bg-neutral-900 border-neutral-800 text-white hover:bg-neutral-800"
                onClick={() => setViewAll(false)}
              >
                Show Less
              </Button>
            )}
          </div>
        </TabsContent>

        {/* Automated Messages / Config */}
        <TabsContent value="automated" className="space-y-6">
          <AutomatedMessageConfig
            templates={sampleData.routineTemplates}
            onClientSelected={() => {}}
          />
        </TabsContent>
      </Tabs>

      {/* Right-hand side stats & the new message dialog can remain at the bottom 
          or you can keep it to the side if desired. */}
      <div className="mt-8">
        <Card className="bg-[#121212] border-neutral-800">
          <CardHeader>
            <CardTitle className="text-xl font-light text-white">Quick Stats</CardTitle>
            <CardDescription className="text-neutral-400">Message activity overview</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <span className="text-sm text-neutral-400">Messages Today</span>
                <span className="font-light text-white">12</span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-sm text-neutral-400">Scheduled Tomorrow</span>
                <span className="font-light text-white">8</span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-sm text-neutral-400">Response Rate</span>
                <span className="font-light text-white">87%</span>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>

      <MessageCreationDialog 
        isOpen={isMessageDialogOpen} 
        onClose={() => setIsMessageDialogOpen(false)} 
        sampleData={sampleData} 
      />
    </div>
  );
};

export default MessageManagementDashboard;
