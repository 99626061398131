// src/components/ui/ClientScheduleCard.jsx


import React from 'react';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from './Card';

/**
 * A card to display the client's typical daily schedule.
 *
 * @param {Array} schedule - An array of objects like:
 *   [{ label: 'Wake Up', time: '8:00 AM' }, { label: 'Workout', time: '11:00 AM' }, ...]
 */
const ClientScheduleCard = ({ schedule }) => {
  return (
    <Card className="bg-[#121212] border-neutral-800 mt-4">
      <CardHeader>
        <CardTitle>Client Typical Schedule</CardTitle>
        <CardDescription>A quick overview of daily routine</CardDescription>
      </CardHeader>
      <CardContent className="space-y-2">
        {schedule?.map((item, index) => (
          <div
            key={index}
            className="flex items-center justify-between text-sm text-neutral-200"
          >
            <span>{item.label}</span>
            <span>{item.time}</span>
          </div>
        ))}
      </CardContent>
    </Card>
  );
};

export default ClientScheduleCard;
