import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { getAuth, signOut, sendPasswordResetEmail } from 'firebase/auth';
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';
import { Input } from './ui/Input';
import { Button } from './ui/Button';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { API_URLS } from '../constants/api';
import { 
  UserCircle, 
  Phone, 
  CreditCard, 
  LogOut, 
  ArrowLeft, 
  Lock,
  Mail,
  CheckCircle,
  AlertCircle
} from 'lucide-react';
import { loadStripe } from '@stripe/stripe-js';

// Initialize Stripe
const stripePromise = loadStripe('pk_live_51PmzEsJp9datwQ8XX5QXruYq4HeCdzi5qLpPTOJ6DauNGUe4RicW0vtsCiyow8gqjubN2NtcB9w5jr7ycWtgMAaR00wFdVnC4K');

const AccountSettings = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [resetError, setResetError] = useState('');
  const auth = getAuth();

  useEffect(() => {
    const loadAccountData = async () => {
      if (!user) return;
      
      try {
        const coachDoc = await getDoc(doc(db, 'coaches', user.uid));
        if (coachDoc.exists()) {
          const data = coachDoc.data();
          setFirstName(data.firstName || '');
          setLastName(data.lastName || '');
          setPhoneNumber(data.phoneNumber || '');
          setSubscriptionStatus(data.subscriptionStatus || 'inactive');
        }
      } catch (error) {
        console.error('Error loading account data:', error);
      }
    };

    if (user) {
      loadAccountData();
    }
  }, [user]);

  const handlePasswordReset = async () => {
    try {
      await sendPasswordResetEmail(auth, user.email);
      setResetEmailSent(true);
      setResetError('');
      // Reset the success message after 5 seconds
      setTimeout(() => setResetEmailSent(false), 5000);
    } catch (error) {
      setResetError('Failed to send reset email. Please try again.');
      setTimeout(() => setResetError(''), 5000);
    }
  };

  const handleLogout = () => {
    signOut(auth);
  };

  const updateName = async () => {
    if (!firstName.trim() || !lastName.trim()) {
      alert('Please enter both first and last name');
      return;
    }
    
    try {
      await updateDoc(doc(db, 'coaches', user.uid), {
        firstName,
        lastName,
      });
      alert('Name updated successfully');
    } catch (error) {
      console.error('Error updating name:', error);
    }
  };

  const verifyPhoneNumber = async () => {
    if (!phoneNumber.trim()) {
      alert('Please enter a phone number');
      return;
    }
    
    try {
      await updateDoc(doc(db, 'coaches', user.uid), {
        phoneNumber,
        isMessagingEnabled: false,
      });
      alert('Phone number saved. Please send a message to evolv@a.imsg.co to verify.');
    } catch (error) {
      console.error('Error saving phone number:', error);
    }
  };

  const handleSubscriptionManagement = async () => {
    setIsLoading(true);
    try {
      if (subscriptionStatus === 'inactive') {
        const response = await fetch(API_URLS.createCheckoutSession, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${await user.getIdToken()}`,
          },
          body: JSON.stringify({
            domain: window.location.origin,
          }),
        });

        if (!response.ok) throw new Error('Failed to create checkout session');
        const { sessionId } = await response.json();
        const stripe = await stripePromise;
        await stripe.redirectToCheckout({ sessionId });
      } else {
        const response = await fetch(API_URLS.createPortalSession, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${await user.getIdToken()}`,
          },
          body: JSON.stringify({
            domain: window.location.origin,
          }),
        });

        if (!response.ok) throw new Error('Failed to create portal session');
        const { url } = await response.json();
        window.location.href = url;
      }
    } catch (error) {
      console.error('Error managing subscription:', error);
      alert('Error managing subscription: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-black p-6">
      <div className="max-w-2xl mx-auto mt-10">
        <Card className="bg-neutral-900 border-0 shadow-xl">
          <CardHeader className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:justify-between md:items-center border-b border-neutral-800 pb-6">
            <div className="flex items-center space-x-4">
              <Button
                onClick={() => navigate('/account')}
                variant="ghost"
                size="icon"
                className="text-white hover:bg-neutral-800"
              >
                <ArrowLeft className="h-5 w-5" />
              </Button>
              <CardTitle className="text-white text-2xl">Account Settings</CardTitle>
            </div>
            <div className="flex items-center space-x-2">
              <Mail className="h-4 w-4 text-gray-400" />
              <span className="text-gray-400 text-sm">{user?.email}</span>
            </div>
          </CardHeader>
          
          <CardContent className="space-y-10 pt-8">
            {/* Profile Section */}
            <section className="space-y-6">
              <div className="flex items-center space-x-2">
                <UserCircle className="h-5 w-5 text-blue-400" />
                <h2 className="text-white text-lg font-medium">Profile Information</h2>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="flex flex-col space-y-3">
                  <label className="text-sm font-medium text-gray-300">First Name</label>
                  <Input
                    placeholder="Enter your first name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="bg-neutral-800 text-white border-neutral-700 focus:border-blue-400 focus:ring-blue-400 h-11"
                  />
                </div>
                <div className="flex flex-col space-y-3">
                  <label className="text-sm font-medium text-gray-300">Last Name</label>
                  <Input
                    placeholder="Enter your last name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className="bg-neutral-800 text-white border-neutral-700 focus:border-blue-400 focus:ring-blue-400 h-11"
                  />
                </div>
              </div>
              <Button
                onClick={updateName}
                className="w-full md:w-auto bg-blue-500 text-white hover:bg-blue-600"
              >
                Update Profile
              </Button>
            </section>

            {/* Phone Verification Section */}
            <section className="space-y-6 pt-6 border-t border-neutral-800">
              <div className="flex items-center space-x-2">
                <Phone className="h-5 w-5 text-blue-400" />
                <h2 className="text-white text-lg font-medium">Phone Verification</h2>
              </div>
              <div className="flex flex-col space-y-3">
                <label className="text-sm font-medium text-gray-300">Phone Number</label>
                <Input
                  type="tel"
                  placeholder="Enter your phone number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="bg-neutral-800 text-white border-neutral-700 focus:border-blue-400 focus:ring-blue-400 h-11"
                />
              </div>
              <Button 
                onClick={verifyPhoneNumber} 
                className="w-full md:w-auto bg-blue-500 text-white hover:bg-blue-600"
              >
                Verify Phone Number
              </Button>
            </section>

            {/* Security Section */}
            <section className="space-y-6 pt-6 border-t border-neutral-800">
              <div className="flex items-center space-x-2">
                <Lock className="h-5 w-5 text-blue-400" />
                <h2 className="text-white text-lg font-medium">Security</h2>
              </div>
              <div className="space-y-4">
                {resetEmailSent && (
                  <div className="flex items-center space-x-2 text-green-400 bg-green-400/10 p-4 rounded-lg">
                    <CheckCircle className="h-5 w-5" />
                    <span>Password reset email sent successfully!</span>
                  </div>
                )}
                {resetError && (
                  <div className="flex items-center space-x-2 text-red-400 bg-red-400/10 p-4 rounded-lg">
                    <AlertCircle className="h-5 w-5" />
                    <span>{resetError}</span>
                  </div>
                )}
                <Button 
                  onClick={handlePasswordReset}
                  className="w-full md:w-auto bg-blue-500 text-white hover:bg-blue-600"
                >
                  Reset Password
                </Button>
              </div>
            </section>

            {/* Subscription Section */}
            <section className="space-y-6 pt-6 border-t border-neutral-800">
              <div className="flex items-center space-x-2">
                <CreditCard className="h-5 w-5 text-blue-400" />
                <h2 className="text-white text-lg font-medium">Subscription</h2>
              </div>
              <div className="bg-neutral-800 p-6 rounded-lg">
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-white font-medium">Current Plan</p>
                    <p className="text-gray-400 text-sm mt-1 capitalize">{subscriptionStatus}</p>
                  </div>
                  <Button
                    onClick={handleSubscriptionManagement}
                    disabled={isLoading}
                    className="bg-blue-500 text-white hover:bg-blue-600"
                  >
                    {isLoading ? 'Processing...' : 
                     subscriptionStatus === 'inactive' ? 'Subscribe Now' : 'Manage Plan'}
                  </Button>
                </div>
              </div>
            </section>

            {/* Logout Section */}
            <section className="pt-6 border-t border-neutral-800">
              <Button
                onClick={handleLogout}
                className="w-full md:w-auto bg-neutral-700 text-white hover:bg-neutral-600"
              >
                <LogOut className="h-4 w-4 mr-2" />
                Logout
              </Button>
            </section>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default AccountSettings;