// src/components/overlays/ClientWorkoutEditOverlay.jsx

import React, { useState, useEffect } from 'react';
import { Button } from '../ui/Button';
import { Input } from '../ui/Input';
import { Trash2, Plus, ChevronUp, ChevronDown } from 'lucide-react';
import ExerciseSearch from '../ExerciseSearch';
import { assignCustomWorkout, updateAssignedWorkout, deleteAssignedWorkout } from '../../services/workoutService';
import { useAuth } from '../../contexts/AuthContext';
import MessageSchedule from '../MessageSchedule';
import ExpandedSetsEditor from '../ExpandedSetsEditor';
import { createCustomExercise, modifyExercise, getAllExercises } from '../../services/exerciseService';
import ModifyExerciseOverlay from './ModifyExerciseOverlay';
import CreateExerciseOverlay from './CreateExerciseOverlay';



const ClientWorkoutEditOverlay = ({ clientId, date, template, existingWorkout, onClose, onWorkoutAssigned }) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [messageSendTime, setMessageSendTime] = useState(null);
  const [workoutData, setWorkoutData] = useState({
    name: template?.name || existingWorkout?.name || '',
    description: template?.description || existingWorkout?.description || '',
    headerImageUrl: template?.headerImageUrl || existingWorkout?.headerImageUrl || '',
    sections: [],
    exercises: [],
    coachId: user?.uid || ''
  });
  const [expandedExercise, setExpandedExercise] = useState(null);
  const [showExerciseEdit, setShowExerciseEdit] = useState(false);
  const [exerciseToEdit, setExerciseToEdit] = useState(null);
  const [showModifyOverlay, setShowModifyOverlay] = useState(false);
  const [showCreateOverlay, setShowCreateOverlay] = useState(false);
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [allExercises, setAllExercises] = useState([]);
  const [isRecurring, setIsRecurring] = useState(false);
  const [recurringWeeks, setRecurringWeeks] = useState(1);
  const [selectedDays, setSelectedDays] = useState({
    Sunday: false,
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false
  });


  useEffect(() => {
    const fetchExercises = async () => {
      try {
        const exercises = await getAllExercises(user.uid);
        setAllExercises(exercises);
      } catch (error) {
        console.error('Error fetching exercises:', error);
      }
    };

    fetchExercises();
  }, [user.uid]);

  useEffect(() => {
    const loadData = async () => {
      let sections = [];
      let exercises = [];
  
      if (existingWorkout) {
        sections = existingWorkout.sections || [];
        exercises = existingWorkout.exercises || [];
      } else if (template) {
        sections = template.sections || [];
        exercises = template.exercises || [];
      }
  
      // Map exercises with data from allExercises to ensure name and other fields are populated
      const exercisesWithDetails = exercises.map(ex => {
        let sets = ex.sets;
        if (!Array.isArray(sets)) {
          // Convert sets from a number (or null) to an array of sets objects
          const numSets = typeof sets === 'number' ? sets : 3; 
          sets = Array(numSets).fill().map(() => ({
            reps: ex.reps || 8,
            weight: ex.weight || 0,
          }));
        }
        
        return {
          ...ex,
          sets
        };
      });         
  
      setWorkoutData(prev => ({
        ...prev,
        name: existingWorkout?.name || template?.name || '',
        description: existingWorkout?.description || template?.description || '',
        headerImageUrl: existingWorkout?.headerImageUrl || template?.headerImageUrl || '',
        sections: [...sections],
        exercises: exercisesWithDetails,
        coachId: existingWorkout?.coachId || user.uid
      }));
    };
  
    if (template || existingWorkout) {
      loadData();
    }
  }, [template, existingWorkout, allExercises, user.uid]);
  

  const addSection = (type) => {
    const newSection = {
      id: `${type}-${Date.now()}`,
      type,
      name: type === 'warmup' ? 'Warm Up'
           : type === 'cooldown' ? 'Cool Down'
           : `Block ${String.fromCharCode(65 + workoutData.sections.filter(s => s.type === 'block').length)}`
    };
    setWorkoutData(prev => {
      let newSections = [...prev.sections];
      if (type === 'warmup') {
        newSections = [newSection, ...newSections]; 
      } else {
        newSections.push(newSection);
      }
      return { ...prev, sections: newSections };
    });
  };

  const removeSection = (sectionId) => {
    setWorkoutData(prev => {
      const filteredExercises = prev.exercises.filter(e => e.sectionId !== sectionId);
      const filteredSections = prev.sections.filter(s => s.id !== sectionId);
      return { ...prev, sections: filteredSections, exercises: filteredExercises };
    });
  };

  const addExercise = (sectionId) => {
    const order = workoutData.exercises.filter(e => e.sectionId === sectionId).length;
    const newExercise = {
      exerciseId: '',
      sets: Array(3).fill().map(() => ({ reps: 8, weight: 25 })), // Initialize with 3 sets
      reps: 8, 
      weight: 25, 
      rest: 60,
      notes: '',
      sectionId,
      order
    };
    setWorkoutData(prev => ({
      ...prev,
      exercises: [...prev.exercises, newExercise]
    }));
  };
  

  const removeExercise = (sectionId, order) => {
    setWorkoutData(prev => ({
      ...prev,
      exercises: prev.exercises.filter(e => !(e.sectionId === sectionId && e.order === order))
    }));
  };

  const updateExercise = (sectionId, order, field, value) => {
    setWorkoutData(prev => ({
      ...prev,
      exercises: prev.exercises.map(ex => {
        if (ex.sectionId === sectionId && ex.order === order) {
          return { ...ex, [field]: value };
        }
        return ex;
      })
    }));
  };

  const handleAssignOrUpdate = async () => {
    setLoading(true);
    try {
      const finalData = { 
        ...workoutData, 
        coachId: user.uid,
        exercises: workoutData.exercises.map(exercise => ({
          ...exercise,
          exerciseId: exercise.exerciseId || '',  
          sets: Array.isArray(exercise.sets) ? exercise.sets : [], 
        }))
      };

      if (isRecurring) {
        // Handle recurring workouts
        const startDate = new Date(date);
        const assignments = [];
        
        for (let week = 0; week < recurringWeeks; week++) {
          Object.entries(selectedDays).forEach(([day, isSelected]) => {
            if (isSelected) {
              const dayIndex = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].indexOf(day);
              const currentDate = new Date(startDate);
              currentDate.setDate(startDate.getDate() + (week * 7) + ((7 + dayIndex - startDate.getDay()) % 7));
              
              // Assign workout for each selected day
              assignments.push(
                assignCustomWorkout(finalData, clientId, currentDate, messageSendTime)
              );
            }
          });
        }

        // Wait for all assignments to complete
        await Promise.all(assignments);
      } else {
        // Handle single workout
        if (existingWorkout) {
          await updateAssignedWorkout(
            existingWorkout.coachId || user.uid, 
            clientId, 
            existingWorkout.id, 
            finalData, 
            date, 
            messageSendTime
          );
        } else {
          await assignCustomWorkout(
            finalData, 
            clientId, 
            date, 
            messageSendTime
          );
        }
      }
      
      onWorkoutAssigned();
      onClose();
    } catch (error) {
      console.error('Error assigning workout:', error);
      alert('Failed to assign workout. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Group exercises by sections
  const sectionsWithExercises = workoutData.sections.map(sec => ({
    ...sec,
    exercises: workoutData.exercises
      .filter(ex => ex.sectionId === sec.id)
      .sort((a, b) => a.order - b.order)
  }));

  const handleDelete = async () => {
    if (!window.confirm('Are you sure you want to delete this workout? This action cannot be undone.')) {
      return;
    }

    setLoading(true);
    try {
      await deleteAssignedWorkout(existingWorkout.coachId || user.uid, clientId, existingWorkout.id);
      onWorkoutAssigned(); // Refresh the calen dar view
      onClose(); // Close the overlay
    } catch (error) {
      console.error('Error deleting workout:', error);
      alert('Failed to delete workout. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleEditExercise = (exercise) => {
    setSelectedExercise(exercise);
    if (exercise) {
      setShowModifyOverlay(true);
    } else {
      setShowCreateOverlay(true);
    }
  };

  const handleSaveExercise = async (formData) => {
    try {
      let savedExercise;
      if (selectedExercise) {
        // Modifying existing exercise
        savedExercise = await modifyExercise(user.uid, selectedExercise.id, formData);
        // Update allExercises
        setAllExercises(prev => prev.map(ex => 
          ex.id === selectedExercise.id 
            ? { ...ex, ...formData, formCues: { instructions: formData.instructions, commonMistakes: formData.commonMistakes }, defaultVideoUrl: formData.mediaUrl } 
            : ex
        ));
      } else {
        // Creating new exercise
        savedExercise = await createCustomExercise(user.uid, formData);
        setAllExercises(prev => [...prev, {
          ...savedExercise,
          name: formData.name,
          instructions: formData.instructions,
          commonMistakes: formData.commonMistakes,
          mediaUrl: formData.mediaUrl,
          isCustom: true
        }]);
      }
  
      // Update workoutData exercises if any use this exerciseId
      setWorkoutData(prev => ({
        ...prev,
        exercises: prev.exercises.map(exercise => 
          exercise.exerciseId === (selectedExercise ? selectedExercise.id : savedExercise.id)
            ? { ...exercise, name: formData.name, instructions: formData.instructions, commonMistakes: formData.commonMistakes, mediaUrl: formData.mediaUrl }
            : exercise
        )
      }));
  
      // Close modify or create overlay only (not the entire ClientWorkoutEditOverlay)
      setShowModifyOverlay(false);
      setShowCreateOverlay(false);
      setSelectedExercise(null);
    } catch (error) {
      console.error('Error saving exercise:', error);
      alert('Failed to save exercise. Please try again.');
    }
  };
  

  const RecurringOptions = () => (
    <div className="mb-6 space-y-4 bg-neutral-800 p-4 rounded-lg">
      <div className="flex items-center justify-between">
        <label className="text-white font-medium">Make this a recurring workout?</label>
        <input
          type="checkbox"
          checked={isRecurring}
          onChange={(e) => setIsRecurring(e.target.checked)}
          className="h-4 w-4 rounded border-gray-300"
        />
      </div>
      
      {isRecurring && (
        <>
          <div className="space-y-2">
            <label className="text-white font-medium">Repeat for how many weeks?</label>
            <input
              type="number"
              min="1"
              max="52"
              value={recurringWeeks}
              onChange={(e) => setRecurringWeeks(parseInt(e.target.value))}
              className="w-full bg-neutral-700 text-white rounded p-2"
            />
          </div>
          
          <div className="space-y-2">
            <label className="text-white font-medium">Select days to repeat on:</label>
            <div className="grid grid-cols-7 gap-2">
              {Object.keys(selectedDays).map((day) => (
                <button
                  key={day}
                  onClick={() => setSelectedDays(prev => ({
                    ...prev,
                    [day]: !prev[day]
                  }))}
                  className={`p-2 rounded text-sm ${
                    selectedDays[day] 
                      ? 'bg-blue-600 text-white' 
                      : 'bg-neutral-700 text-gray-300'
                  }`}
                >
                  {day.slice(0, 3)}
                </button>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );

  const handleSave = async () => {
    if (!isRecurring) {
      // Existing single workout save logic
      // ... your current save logic ...
    } else {
      // Handle recurring workouts
      const startDate = new Date(date);
      const assignments = [];
      
      for (let week = 0; week < recurringWeeks; week++) {
        Object.entries(selectedDays).forEach(([day, isSelected]) => {
          if (isSelected) {
            const dayIndex = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].indexOf(day);
            const currentDate = new Date(startDate);
            currentDate.setDate(startDate.getDate() + (week * 7) + ((7 + dayIndex - startDate.getDay()) % 7));
            
            assignments.push({
              ...workoutData,
              scheduledTime: currentDate
            });
          }
        });
      }

      // Save all assignments
      try {
        await Promise.all(assignments.map(assignment => 
          assignCustomWorkout(assignment, clientId, assignment.scheduledTime)
        ));
        onWorkoutAssigned();
      } catch (error) {
        console.error('Error assigning recurring workouts:', error);
      }
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-80 flex items-start justify-center z-50 overflow-y-auto py-8">
      <div className="relative bg-neutral-900 max-w-4xl w-full rounded-lg p-4 my-auto mx-4">
        <Button
          onClick={onClose}
          variant="ghost"
          className="absolute top-2 right-2 text-white hover:text-gray-300"
        >
          X
        </Button>
        <div className="min-h-[200px]">
          <h2 className="text-xl font-bold mb-4 text-white">
            {existingWorkout ? 'Edit Assigned Workout' : 'Assign Workout'}
          </h2>

          <div className="space-y-4">
            <div className="flex flex-col space-y-2">
              <Input
                placeholder="Workout Name"
                value={workoutData.name || ''}
                onChange={(e) => setWorkoutData(prev => ({ ...prev, name: e.target.value }))}
                className="bg-neutral-800 border-neutral-700 w-50"
              />
              <Input
                placeholder="Description"
                value={workoutData.description || ''}
                onChange={(e) => setWorkoutData(prev => ({ ...prev, description: e.target.value }))}
                className="bg-neutral-800 border-neutral-700 w-50"
              />
            </div>

            <div className="flex flex-col space-y-4">
              <div className="flex space-x-2">
                {!workoutData.sections.some(s => s.type === 'warmup') && (
                  <Button
                    type="button"
                    onClick={() => addSection('warmup')}
                    className="bg-neutral-800 hover:bg-neutral-700"
                  >
                    Add Warm Up
                  </Button>
                )}
                <Button
                  type="button"
                  onClick={() => addSection('block')}
                  className="bg-neutral-800 hover:bg-neutral-700"
                >
                  Add Block
                </Button>
                {!workoutData.sections.some(s => s.type === 'cooldown') && (
                  <Button
                    type="button"
                    onClick={() => addSection('cooldown')}
                    className="bg-neutral-800 hover:bg-neutral-700"
                  >
                    Add Cool Down
                  </Button>
                )}
              </div>
  
              {sectionsWithExercises.map(section => (
          <div key={section.id} className="border border-neutral-700 rounded-lg p-4 space-y-4">
            <div className="flex items-center justify-between">
              <h4 className="text-lg font-medium text-white">{section.name}</h4>
              <Button
                type="button"
                variant="ghost"
                onClick={() => removeSection(section.id)}
                className="text-red-500 hover:text-red-400"
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            </div>

            {section.exercises.map((exercise, idx) => (
              <div key={idx} className="relative border border-neutral-700 rounded-lg p-4 space-y-4">
                <div className="flex items-center justify-between">
                  <div className="flex-1 mr-4">
                    <ExerciseSearch
                      value={exercise.exerciseId}
                      onChange={(value) => updateExercise(section.id, idx, 'exerciseId', value)}
                      className="w-full"
                      onEditExercise={handleEditExercise}
                      allExercises={allExercises}
                    />
                  </div>
                  <Button
                    type="button"
                    variant="ghost"
                    onClick={() => removeExercise(section.id, exercise.order)}
                    className="text-red-500 hover:text-red-400"
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </div>

                <Input
                  placeholder="Notes"
                  value={exercise.notes || ''}
                  onChange={(e) => updateExercise(section.id, exercise.order, 'notes', e.target.value)}
                  className="bg-neutral-800 border-neutral-700"
                />

                <div className="mt-4">
                  <ExpandedSetsEditor
                    sets={exercise.sets}
                    onChange={(newSets) => updateExercise(section.id, exercise.order, 'sets', newSets)}
                  />
                </div>

                <div className="mt-4">
                  <div className="space-y-1">
                    <label className="text-sm text-gray-400 block">Rest (sec)</label>
                    <Input
                      type="number"
                      value={exercise.rest != null ? exercise.rest : 60}
                      onChange={(e) =>
                        updateExercise(section.id, exercise.order, 'rest', parseInt(e.target.value))
                      }
                      className="bg-neutral-800 border-neutral-700 w-20"
                      min="0"
                    />
                  </div>
                </div>
              </div>
            ))}

            <Button
              type="button"
              onClick={() => addExercise(section.id)}
              className="w-full bg-neutral-800 hover:bg-neutral-700"
            >
              <Plus className="h-4 w-4 mr-2" />
              Add Exercise to {section.name}
            </Button>
          </div>
        ))}
  
            <RecurringOptions />
  
            <MessageSchedule
              scheduledDate={date}
              messageSendTime={messageSendTime}
              onMessageSendTimeChange={setMessageSendTime}
            />
  
              <div className="flex gap-4">
              {existingWorkout && (
                <Button
                  onClick={handleDelete}
                  disabled={loading}
                  variant="destructive"
                  className="w-1/3 bg-red-600 hover:bg-red-700"
                >
                  {loading ? 'Deleting...' : 'Delete Workout'}
                </Button>
              )}
              <Button
                onClick={handleAssignOrUpdate}
                disabled={loading}
                className={`bg-blue-600 hover:bg-blue-700 ${existingWorkout ? 'w-2/3' : 'w-full'}`}
              >
                {loading ? 'Saving...' : existingWorkout ? 'Update Workout' : 'Assign Workout'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

    {showModifyOverlay && (
        <ModifyExerciseOverlay
          exercise={selectedExercise}
          onSave={handleSaveExercise}
          onClose={() => {
            setShowModifyOverlay(false);
            setSelectedExercise(null);
          }}
        />
      )}

      {showCreateOverlay && (
        <CreateExerciseOverlay
          onSave={handleSaveExercise}
          onClose={() => {
            setShowCreateOverlay(false);
            setSelectedExercise(null);
          }}
        />
      )}
    </div>
  );
};


export default ClientWorkoutEditOverlay;