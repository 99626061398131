// src/components/InjuryForm.js

import React from 'react';
import { DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from './ui/Dialog';
import { Input } from './ui/Input';
import { Button } from './ui/Button';
import { Label } from './ui/Label';
import { Calendar, AlertCircle } from 'lucide-react';
import { format } from 'date-fns';
import { Alert, AlertDescription } from "./ui/Alert";

const InjuryForm = ({ 
  isEditing,
  formData,
  onInputChange,
  onSubmit,
  onClose,
  isSubmitting = false,
  error = null
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Trim the name field only when submitting
    const trimmedFormData = {
      ...formData,
      name: formData.name.trim()
    };
    onSubmit(trimmedFormData);
  };

  const today = format(new Date(), 'yyyy-MM-dd');

  return (
    <DialogContent className="sm:max-w-[600px] bg-zinc-900 border border-gray-800 shadow-xl text-white">
      <DialogHeader>
        <DialogTitle className="text-2xl font-bold bg-gradient-to-r from-cyan-400 to-purple-400 bg-clip-text text-transparent">
          {isEditing ? 'Edit Injury' : 'Add New Injury'}
        </DialogTitle>
        <DialogDescription className="text-gray-400">
          {isEditing ? 'Update injury details' : 'Record a new injury'}
        </DialogDescription>
      </DialogHeader>

      {error && (
        <Alert variant="destructive" className="bg-red-900/20 border-red-900/50 text-red-400">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <Label htmlFor="name" className="text-sm font-medium text-gray-300">
              Injury Name*
            </Label>
            <Input
              id="name"
              name="name"
              value={formData.name}
              onChange={(e) => onInputChange({ ...formData, name: e.target.value })}
              placeholder="e.g., Left Knee Strain"
              className="bg-gray-800 border-gray-700 text-white placeholder-gray-500 focus:border-cyan-500 hover:border-gray-600"
              required
              maxLength={100}
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="date" className="text-sm font-medium text-gray-300 flex items-center gap-2">
              <Calendar className="w-4 h-4 text-cyan-400" />
              Date of Injury*
            </Label>
            <Input
              id="date"
              type="date"
              name="date"
              value={formData.date}
              max={today}
              onChange={(e) => onInputChange({ ...formData, date: e.target.value })}
              className="bg-gray-800 border-gray-700 text-white focus:border-cyan-500 hover:border-gray-600"
              required
            />
          </div>
        </div>

        <DialogFooter className="gap-3 pt-4">
          <Button
            type="button"
            variant="ghost"
            onClick={onClose}
            disabled={isSubmitting}
            className="text-gray-400 hover:bg-gray-800 hover:text-white"
          >
            Cancel
          </Button>
          <Button 
            type="submit"
            disabled={isSubmitting}
            className="bg-gradient-to-r from-cyan-400 to-purple-400 text-gray-900 font-medium hover:from-cyan-500 hover:to-purple-500 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isSubmitting ? 'Saving...' : isEditing ? 'Update Injury' : 'Add Injury'}
          </Button>
        </DialogFooter>
      </form>
    </DialogContent>
  );
};

export default InjuryForm;