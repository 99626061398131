// src/components/MessageEditor.jsx

import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Button } from './ui/Button';
import { Save, Plus, Trash2, MessageCircle, Edit2 } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from './ui/Dialog';

const MessageEditor = ({ userId, isOpen, onClose }) => {
  const [conversations, setConversations] = useState({});
  const [editingMessage, setEditingMessage] = useState(null);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (isOpen) {
      fetchConversations();
    }
  }, [isOpen, userId]);

  const fetchConversations = async () => {
    try {
      const docRef = doc(db, 'coaches', userId, 'philosophy', 'messageStyleSettings');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setConversations(docSnap.data().exampleConversation || {});
      }
    } catch (error) {
      console.error('Error fetching conversations:', error);
    }
  };

  const handleSave = async () => {
    try {
      setSaving(true);
      const docRef = doc(db, 'coaches', userId, 'philosophy', 'messageStyleSettings');
      await updateDoc(docRef, {
        exampleConversation: conversations
      });
      setEditingMessage(null);
    } catch (error) {
      console.error('Error saving conversations:', error);
    } finally {
      setSaving(false);
    }
  };

  const addMessage = (conversationKey, role) => {
    setConversations(prev => {
      const currentMessages = prev[conversationKey] || [];
      return {
        ...prev,
        [conversationKey]: [...currentMessages, { role, message: '' }]
      };
    });
    const newIndex = conversations[conversationKey]?.length || 0;
    setEditingMessage(`${conversationKey}-${newIndex}`);
  };

  const addNewConversation = () => {
    const newKey = `Conversation ${Object.keys(conversations).length + 1}`;
    setConversations(prev => ({
      ...prev,
      [newKey]: []
    }));
  };

  const deleteMessage = (conversationKey, messageIndex) => {
    setConversations(prev => ({
      ...prev,
      [conversationKey]: prev[conversationKey].filter((_, idx) => idx !== messageIndex)
    }));
  };

  const updateMessage = (conversationKey, messageIndex, field, value) => {
    setConversations(prev => ({
      ...prev,
      [conversationKey]: prev[conversationKey].map((msg, idx) => 
        idx === messageIndex ? { ...msg, [field]: value } : msg
      )
    }));
  };

  const MessageBubble = ({ message, isEditing, conversationKey, index }) => {
    const isCoach = message.role === 'coach';
    
    if (isEditing) {
      return (
        <div className="w-full space-y-2 p-2 bg-white/5 rounded-lg">
          <div className="flex items-center justify-between mb-2">
            <select
              value={message.role}
              onChange={(e) => updateMessage(conversationKey, index, 'role', e.target.value)}
              className="bg-white/5 text-white p-2 rounded-lg focus:ring-2 focus:ring-blue-500 outline-none w-32"
            >
              <option value="client">Client</option>
              <option value="coach">Coach</option>
            </select>
          </div>
          <MessageInput 
            value={message.message}
            onChange={(value) => updateMessage(conversationKey, index, 'message', value)}
            onSave={() => setEditingMessage(null)}
            onCancel={() => setEditingMessage(null)}
            placeholder="Enter message..."
          />
        </div>
      );
    }  
  
    return (
      <div className={`group flex items-start gap-2 ${isCoach ? 'flex-row-reverse' : ''}`}>
        <div className={`relative max-w-[80%] ${isCoach ? 'ml-auto' : 'mr-auto'}`}>
          <div
            className={`px-4 py-2 rounded-2xl break-words ${
              isCoach
                ? 'bg-blue-500 text-white rounded-tr-sm'
                : 'bg-white/10 text-white rounded-tl-sm'
            }`}
          >
            {message.message}
          </div>
          <div className={`absolute top-0 ${isCoach ? 'left-0 -translate-x-full' : 'right-0 translate-x-full'} flex items-center gap-1 opacity-0 group-hover:opacity-100 transition-opacity`}>
            <Button
              variant="ghost"
              size="sm"
              onClick={() => setEditingMessage(`${conversationKey}-${index}`)}
              className="h-8 w-8 p-0"
            >
              <Edit2 className="h-4 w-4" />
            </Button>
            <Button
              variant="ghost"
              size="sm"
              onClick={() => deleteMessage(conversationKey, index)}
              className="h-8 w-8 p-0 text-red-400 hover:text-red-300"
            >
              <Trash2 className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </div>
    );
  };
  

  const AddMessageButtons = ({ conversationKey }) => (
    <div className="flex justify-center gap-2 my-4">
      <Button
        variant="ghost"
        size="sm"
        onClick={() => addMessage(conversationKey, 'client')}
        className="text-neutral-400 hover:text-white hover:bg-white/5"
      >
        <Plus className="h-4 w-4 mr-2" />
        Add Client Message
      </Button>
      <Button
        variant="ghost"
        size="sm"
        onClick={() => addMessage(conversationKey, 'coach')}
        className="text-neutral-400 hover:text-white hover:bg-white/5"
      >
        <Plus className="h-4 w-4 mr-2" />
        Add Coach Message
      </Button>
    </div>
  );

const MessageInput = ({ 
  value,
  onChange,
  onSave,
  onCancel,
  placeholder = "Enter message..."
}) => {
  const contentEditableRef = React.useRef(null);
  const [content, setContent] = React.useState(value);

  React.useEffect(() => {
    if (contentEditableRef.current) {
      // Set initial content
      contentEditableRef.current.textContent = value;

      // Place cursor at the end
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNodeContents(contentEditableRef.current);
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }, []);

  const handleInput = (e) => {
    const newValue = e.currentTarget.textContent || '';
    setContent(newValue);
    onChange(newValue);
  };

  return (
    <div className="w-full space-y-2 p-2 bg-white/5 rounded-lg">
      <div className="relative w-full min-h-[80px] bg-white/5 rounded-lg">
        <div
          ref={contentEditableRef}
          contentEditable
          className="w-full text-white p-3 outline-none focus:ring-2 focus:ring-blue-500 rounded-lg min-h-[80px] whitespace-pre-wrap break-words"
          style={{
            direction: 'ltr',
            unicodeBidi: 'plaintext'
          }}
          onInput={handleInput}
          suppressContentEditableWarning={true}
          role="textbox"
          aria-multiline="true"
          data-placeholder={placeholder}
        />
      </div>

      <div className="flex justify-end gap-2 mt-2">
        <Button
          variant="ghost"
          size="sm"
          onClick={onCancel}
          className="text-neutral-400 hover:text-white"
        >
          Cancel
        </Button>
        <Button
          size="sm"
          onClick={onSave}
          className="bg-blue-500 hover:bg-blue-600 text-white"
        >
          Save
        </Button>
      </div>
    </div>
  );
};


  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-2xl max-h-[90vh] overflow-hidden bg-neutral-900 text-white border-neutral-800">
        <DialogHeader className="border-b border-neutral-800 pb-4">
          <DialogTitle className="flex items-center gap-2 text-xl font-medium">
            <MessageCircle className="w-5 h-5 text-blue-400" />
            Example Conversations
          </DialogTitle>
          <DialogDescription className="text-sm text-neutral-400">
            Create and manage example conversations for your coaching style.
          </DialogDescription>
        </DialogHeader>
        
        <div className="flex flex-col h-[calc(100vh-240px)]">
          <div className="flex-1 overflow-y-auto px-4">
            {Object.entries(conversations).map(([key, messages]) => (
              <div key={key} className="mb-8">
                <div className="sticky top-0 bg-neutral-900/95 backdrop-blur-sm py-2 mb-4 z-10">
                  <h3 className="text-sm font-medium text-neutral-400">{key}</h3>
                </div>
                
                <div className="space-y-4">
                  {messages.map((msg, idx) => (
                    <MessageBubble
                      key={idx}
                      message={msg}
                      isEditing={editingMessage === `${key}-${idx}`}
                      conversationKey={key}
                      index={idx}
                    />
                  ))}
                  <AddMessageButtons conversationKey={key} />
                </div>
              </div>
            ))}
          </div>

          <div className="flex justify-between items-center gap-4 p-4 border-t border-neutral-800 bg-neutral-900">
            <Button
              onClick={addNewConversation}
              variant="ghost"
              className="text-neutral-400 hover:text-white hover:bg-white/5"
            >
              <Plus className="h-4 w-4 mr-2" />
              New Conversation
            </Button>
            <Button
              onClick={handleSave}
              disabled={saving}
              className="bg-blue-500 hover:bg-blue-600 text-white"
            >
              <Save className="h-4 w-4 mr-2" />
              {saving ? 'Saving...' : 'Save Changes'}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MessageEditor;