// src/components/Message/components/MessageCreationDialog.jsx

import React, { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../../ui/Dialog';
import { Button } from '../../ui/Button';
import { Input } from '../../ui/Input';
import { Label } from '../../ui/Label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../ui/Select';
import { MessageCircle, RotateCcw, ChevronRight, Users, Eye } from 'lucide-react';
import clsx from 'clsx';

const MessageCreationDialog = ({ isOpen, onClose, sampleData }) => {
  // We removed the "goal-based" type. Only "one-time" & "recurring".
  const [messageType, setMessageType] = useState('');
  const [step, setStep] = useState(1);

  // For one-time (bulk) messages, we allow selecting multiple clients
  // For recurring, we allow only one client.
  const [selectedClients, setSelectedClients] = useState([]);

  // Searching & filtering
  const [searchTerm, setSearchTerm] = useState('');
  const [subscriptionFilter, setSubscriptionFilter] = useState('');
  const [lastMessageFilter, setLastMessageFilter] = useState('');

  // Toggle client history
  const [historyOpenFor, setHistoryOpenFor] = useState(null);

  // The final form data for scheduling
  const [formData, setFormData] = useState({
    frequency: '',
    message: '',
    date: '',
    time: ''
  });

  /************************************************
   *                 Helpers
   ************************************************/
  const updateFormData = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  // Add/remove client IDs from the selection array (one-time)
  const toggleClientSelection = (clientId) => {
    setSelectedClients((prev) =>
      prev.includes(clientId)
        ? prev.filter((id) => id !== clientId)
        : [...prev, clientId]
    );
  };

  const isClientSelected = (clientId) => selectedClients.includes(clientId);

  // For recurring, pick just one client
  const selectSingleClient = (clientId) => {
    setSelectedClients([clientId]);
  };

  /************************************************
   *         Step 1: Select message type
   ************************************************/
  const MessageTypeSelector = () => (
    <div className="grid grid-cols-1 gap-4">
      {/* One-time message */}
      <button
        onClick={() => {
          setMessageType('one-time');
          setStep(2);
        }}
        className="flex items-center justify-between p-4 rounded-lg bg-neutral-900 border border-neutral-800 hover:bg-neutral-800 transition-all group"
      >
        <div className="flex items-center gap-4">
          <div className="p-3 rounded-full bg-neutral-800 group-hover:bg-neutral-700">
            <MessageCircle className="w-5 h-5 text-white" />
          </div>
          <div className="text-left">
            <h3 className="text-white font-light text-lg">One-time Message</h3>
            <p className="text-neutral-400 text-sm">Send a single (or bulk) message</p>
          </div>
        </div>
        <ChevronRight className="w-5 h-5 text-neutral-400 group-hover:text-white transition-colors" />
      </button>

      {/* Recurring check-in */}
      <button
        onClick={() => {
          setMessageType('recurring');
          setStep(2);
        }}
        className="flex items-center justify-between p-4 rounded-lg bg-neutral-900 border border-neutral-800 hover:bg-neutral-800 transition-all group"
      >
        <div className="flex items-center gap-4">
          <div className="p-3 rounded-full bg-neutral-800 group-hover:bg-neutral-700">
            <RotateCcw className="w-5 h-5 text-white" />
          </div>
          <div className="text-left">
            <h3 className="text-white font-light text-lg">Recurring Check-in</h3>
            <p className="text-neutral-400 text-sm">Schedule repeated check-ins</p>
          </div>
        </div>
        <ChevronRight className="w-5 h-5 text-neutral-400 group-hover:text-white transition-colors" />
      </button>
    </div>
  );

  /************************************************
   *    Step 2: Choose Clients (+ filters)
   ************************************************/
  const ClientSelector = () => {
    // Filter logic for both one-time & recurring
    const filteredClients = sampleData.clients.filter((client) => {
      // Search by name
      if (searchTerm && !client.name.toLowerCase().includes(searchTerm.toLowerCase())) {
        return false;
      }
      // Subscription filter (exact match)
      if (subscriptionFilter && client.subscriptionLevel !== subscriptionFilter) {
        return false;
      }
      // Time since last message filter:
      // We'll interpret lastMessageFilter as "has it been X days since last message?"
      // e.g. "3days", "7days", "14days", etc.
      if (lastMessageFilter) {
        const now = new Date();
        const lastMsgTime = new Date(client.lastMessage);
        const diffInMs = now - lastMsgTime; // difference in milliseconds
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

        switch (lastMessageFilter) {
          case '3days':
            // show clients who have gone at least 3 days
            if (diffInDays < 3) return false;
            break;
          case '7days':
            if (diffInDays < 7) return false;
            break;
          case '14days':
            if (diffInDays < 14) return false;
            break;
          default:
            break;
        }
      }
      return true;
    });

    return (
      <div className="space-y-6">
        {/* Header */}
        <div className="flex items-center gap-4 pb-6 border-b border-neutral-800">
          <div className="p-3 rounded-full bg-neutral-800">
            <Users className="w-5 h-5 text-white" />
          </div>
          <div>
            <h3 className="text-white font-light text-lg">
              {messageType === 'one-time'
                ? 'Select One or More Clients'
                : 'Select Client for Recurring'}
            </h3>
            <p className="text-neutral-400 text-sm">Choose who will receive this message</p>
          </div>
        </div>

        {/* Filtering UI (applies to both) */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          {/* Search by name */}
          <div>
            <Label className="text-neutral-400 text-sm">Search</Label>
            <Input
              className="mt-1 bg-neutral-900 border-neutral-800 text-white"
              placeholder="Type a name..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          {/* Subscription filter (only for one-time, but we can keep for recurring if you want) */}
          <div>
            <Label className="text-neutral-400 text-sm">Subscription Level</Label>
            <Select
              value={subscriptionFilter}
              onValueChange={setSubscriptionFilter}
            >
              <SelectTrigger className="mt-1 bg-neutral-900 border-neutral-800 text-white">
                <SelectValue placeholder="All" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="">All</SelectItem>
                <SelectItem value="Free">Free</SelectItem>
                <SelectItem value="Pro">Pro</SelectItem>
                <SelectItem value="Premium">Premium</SelectItem>
              </SelectContent>
            </Select>
          </div>

          {/* Time since last message */}
          <div>
            <Label className="text-neutral-400 text-sm">Last Message Received</Label>
            <Select
              value={lastMessageFilter}
              onValueChange={setLastMessageFilter}
            >
              <SelectTrigger className="mt-1 bg-neutral-900 border-neutral-800 text-white">
                <SelectValue placeholder="No Filter" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="">No Filter</SelectItem>
                <SelectItem value="3days">3 Days</SelectItem>
                <SelectItem value="7days">7 Days</SelectItem>
                <SelectItem value="14days">14 Days</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>

        {/* Client list */}
        {filteredClients.length === 0 ? (
          <p className="text-neutral-500 text-sm italic mt-4">No clients match your filters.</p>
        ) : (
          <div className="space-y-3">
            {filteredClients.map((client) => {
              const selected = isClientSelected(client.id);

              return (
                <div
                  key={client.id}
                  className={clsx(
                    'p-4 rounded-lg bg-neutral-900 border border-neutral-800 transition-all'
                  )}
                >
                  <div className="flex items-center justify-between">
                    {/* Left side: name, subscription, quick info */}
                    <div className="flex items-center gap-4">
                      <div className="w-10 h-10 rounded-full bg-neutral-700 flex items-center justify-center">
                        <span className="text-white font-light">
                          {client.name.charAt(0)}
                        </span>
                      </div>
                      <div className="text-left">
                        <h4 className="text-white font-light">{client.name}</h4>
                        <p className="text-neutral-400 text-sm">
                          {client.subscriptionLevel} &middot;{' '}
                          {client.goals?.join(', ')}
                        </p>
                      </div>
                    </div>

                    {/* Right side: selection or check */}
                    <div className="flex items-center gap-4">
                      {/* History Toggle */}
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() =>
                          setHistoryOpenFor((prev) =>
                            prev === client.id ? null : client.id
                          )
                        }
                        className="text-neutral-400 hover:text-white hover:bg-neutral-800"
                      >
                        <Eye className="w-4 h-4" />
                      </Button>

                      {/* Selection */}
                      {messageType === 'one-time' ? (
                        // Multi-select
                        <button
                          onClick={() => toggleClientSelection(client.id)}
                          className={clsx(
                            'w-4 h-4 rounded-full border-2 transition-colors',
                            selected
                              ? 'bg-white border-white'
                              : 'border-neutral-400 hover:border-white'
                          )}
                        />
                      ) : (
                        // Single select for recurring
                        <button
                          onClick={() => selectSingleClient(client.id)}
                          className={clsx(
                            'px-3 py-1 rounded-md text-sm border-2 transition-colors',
                            selected
                              ? 'bg-white text-black border-white'
                              : 'text-white border-neutral-400 hover:border-white'
                          )}
                        >
                          {selected ? 'Selected' : 'Select'}
                        </button>
                      )}
                    </div>
                  </div>

                  {/* Expandable message history */}
                  {historyOpenFor === client.id && (
                    <div className="mt-4 p-4 bg-neutral-800 rounded-md">
                      <h5 className="text-white font-light mb-2">Recent Messages:</h5>
                      {client.messageHistory && client.messageHistory.length > 0 ? (
                        <ul className="space-y-2">
                          {client.messageHistory.map((msg, idx) => (
                            <li key={idx} className="text-sm text-neutral-300">
                              <span className="block italic text-neutral-400">
                                {msg.date}
                              </span>
                              {msg.text}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p className="text-neutral-500 text-sm italic">No message history.</p>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}

        <div className="flex justify-end pt-4">
          <Button
            onClick={() => setStep(3)}
            className="bg-white text-black hover:bg-neutral-100"
            disabled={
              // One-time: must have at least 1 selection
              // Recurring: must have exactly 1 selection
              messageType === 'one-time'
                ? selectedClients.length === 0
                : selectedClients.length !== 1
            }
          >
            Next
          </Button>
        </div>
      </div>
    );
  };

  /************************************************
   *          Step 3: Compose message
   ************************************************/
  const MessageComposer = () => (
    <div className="space-y-6">
      <div className="flex items-center gap-4 pb-6 border-b border-neutral-800">
        <div className="p-3 rounded-full bg-neutral-800">
          <MessageCircle className="w-5 h-5 text-white" />
        </div>
        <div>
          <h3 className="text-white font-light text-lg">Compose Message</h3>
          <p className="text-neutral-400 text-sm">Write your message and set timing</p>
        </div>
      </div>

      {messageType === 'recurring' && (
        <div className="space-y-2">
          <Label className="text-neutral-400">Frequency</Label>
          <Select
            value={formData.frequency}
            onValueChange={(value) => updateFormData('frequency', value)}
          >
            <SelectTrigger className="bg-neutral-900 border-neutral-800 text-white">
              <SelectValue placeholder="Select frequency" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="daily">Daily</SelectItem>
              <SelectItem value="weekly">Weekly</SelectItem>
              <SelectItem value="custom">Custom</SelectItem>
            </SelectContent>
          </Select>
        </div>
      )}

      <div className="space-y-2">
        <Label className="text-neutral-400">Message</Label>
        <Input
          value={formData.message}
          onChange={(e) => updateFormData('message', e.target.value)}
          placeholder="Enter your message"
          className="bg-neutral-900 border-neutral-800 text-white h-24"
          multiline
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div className="space-y-2">
          <Label className="text-neutral-400">Date</Label>
          <Input
            type="date"
            value={formData.date}
            onChange={(e) => updateFormData('date', e.target.value)}
            className="bg-neutral-900 border-neutral-800 text-white"
          />
        </div>
        <div className="space-y-2">
          <Label className="text-neutral-400">Time</Label>
          <Input
            type="time"
            value={formData.time}
            onChange={(e) => updateFormData('time', e.target.value)}
            className="bg-neutral-900 border-neutral-800 text-white"
          />
        </div>
      </div>

      <div className="flex justify-end pt-4">
        <Button
          onClick={onClose}
          className="bg-white text-black hover:bg-neutral-100"
        >
          Schedule Message
        </Button>
      </div>
    </div>
  );

  /************************************************
   *                  RENDER
   ************************************************/
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-2xl bg-black border-neutral-800">
        <DialogHeader>
          <DialogTitle className="text-2xl font-light text-white">
            {step === 1 && 'Create New Message'}
            {step === 2 && 'Select Recipient(s)'}
            {step === 3 && 'Compose Message'}
          </DialogTitle>
        </DialogHeader>

        <div className="py-6">
          {step === 1 && <MessageTypeSelector />}
          {step === 2 && <ClientSelector />}
          {step === 3 && <MessageComposer />}
        </div>

        {/* Footer buttons: "Back" or "Cancel" */}
        <div className="flex justify-between pt-6 border-t border-neutral-800">
          <Button
            variant="outline"
            onClick={() => (step > 1 ? setStep(step - 1) : onClose())}
            className="bg-neutral-900 border-neutral-800 text-white hover:bg-neutral-800"
          >
            {step > 1 ? 'Back' : 'Cancel'}
          </Button>
          {/* 
            The "Schedule Message" button is placed inline in step 3's content 
            to keep the dialog minimal. You can move it here if you prefer.
          */}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MessageCreationDialog;
