// src/components/ClientPage.jsx

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Input } from './ui/Input';
import { Button } from './ui/Button';
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';
import { ChevronDown, Send } from 'lucide-react';
import { httpsCallable } from 'firebase/functions';
import {
  doc,
  getDoc,
  collection,
  getDocs,
  query,
  orderBy,
  updateDoc,
  arrayUnion,
  arrayRemove,
  setDoc,
  deleteField,
  addDoc,
  where,
  limit,
  onSnapshot,
  writeBatch,
  serverTimestamp,
} from 'firebase/firestore';

import { functions, db } from '../firebase';
import NudgeManager from './NudgeManager';
import AdherenceModal from './AdherenceModal';
import { format } from 'date-fns';
import SessionTab from './SessionTab';
import ClientInfoTab from './ClientInfoTab';
import { processSessionRecap } from '../utils/recapProcessor';
import NutritionTab from './NutritionTab';
export const createDefaultMessageSettings = async (clientId) => {
  const messageSettingsRef = doc(db, 'users', clientId, 'settings', 'messageSettings');
  try {
    await setDoc(messageSettingsRef, {
      requireCoachApproval: True, 
      language: 'en',      
    });
  } catch (error) {
    console.error('Error creating default message settings:', error);
  }
};

const ClientPage = () => {
  const { user, isAdmin } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { clientId } = useParams();
  const currentPage = location.pathname.substring(1);
  const [activeTab, setActiveTab] = useState('sessions');
  const [showNudgeManager, setShowNudgeManager] = useState(false);
  const [nudges, setNudges] = useState([]);
  const [message, setMessage] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [clientInfo, setClientInfo] = useState({
    name: '',
    preferences: [],
    goals: [],
    coachId: '',
  });
  const [clients, setClients] = useState([]);
  const [isSelectingClient, setIsSelectingClient] = useState(false);
  const [isAddingPreference, setIsAddingPreference] = useState(false);
  const [newPreference, setNewPreference] = useState('');
  const [isUpdatingGoal, setIsUpdatingGoal] = useState(false);
  const [newGoal, setNewGoal] = useState('');
  const [isAdherenceModalOpen, setIsAdherenceModalOpen] = useState(false);
  const [hasTrackedToday, setHasTrackedToday] = useState(false);
  const [currentAdherenceValue, setCurrentAdherenceValue] = useState(null);
  const [sessions, setSessions] = useState([]);
  const [selectedSessionId, setSelectedSessionId] = useState(null);
  const [isAddingSession, setIsAddingSession] = useState(false);
  const [sessionData, setSessionData] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [coachFirstName, setCoachFirstName] = useState('');

  const chatContainerRef = useRef(null);
  const [newInsights, setNewInsights] = useState(null);


  const timeZone = 'America/New_York';

  const fetchNudges = async () => {
    try {
      const nudgesCollection = collection(db, 'users', clientId, 'nudges');
      const nudgesSnapshot = await getDocs(nudgesCollection);
      const fetchedNudges = nudgesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNudges(fetchedNudges);
    } catch (error) {
      console.error('Error fetching nudges:', error);
      alert('Error fetching nudges: ' + error.message);
    }
  };

  const onNavigate = (path) => {
    navigate(`/${path}`);
  };

  const fetchMessages = () => {
    try {
      const messagesCollection = collection(db, 'users', clientId, 'messages');
      const q = query(messagesCollection, orderBy('date_sent', 'asc'));
      
      // Set up real-time listener
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const messages = snapshot.docs.map((doc) => {
          const data = doc.data();
          const isFromCoach = data.sender_id === data.coach_id;
          return {
            id: doc.id,
            text: isFromCoach ? data.aiResponse || data.content : data.content,
            sender: isFromCoach ? 'coach' : 'client',
            time: data.date_sent?.toDate().toLocaleString() || new Date().toLocaleString(),
          };
        });
        setChatMessages(messages);

        // Scroll to bottom when new messages arrive
        if (chatContainerRef.current) {
          chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
      });

      // Return unsubscribe function
      return unsubscribe;
    } catch (error) {
      console.error('Error setting up message listener:', error);
      alert('Error setting up message listener: ' + error.message);
    }
  };

  useEffect(() => {
    let unsubscribe;
    
    if (clientId) {
      unsubscribe = fetchMessages();
    }

    // Cleanup function to remove listener when component unmounts or clientId changes
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [clientId]);

  useEffect(() => {
    if (clientId && user) {
      // Remove fetchMessages() from here
      fetchClientData();
      fetchNudges();
      fetchSessions();
      checkIfTrackedToday();
    }
    if (user) {
      fetchCoachData();
      fetchClients();
    }
  }, [clientId, user]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatMessages]);

  useEffect(() => {
    if (showPopup) {
      const timer = setTimeout(() => {
        setShowPopup(false);
        setPopupMessage('');
      }, 3000); // Hide after 3 seconds
      return () => clearTimeout(timer);
    }
  }, [showPopup]);

  const fetchCoachData = async () => {
    try {
      const coachDoc = await getDoc(doc(db, 'coaches', user.uid));
      // ... rest of the function
    } catch (error) {
      console.error('Error fetching coach data:', error);
    }
  };

  const fetchClientData = async () => {
    try {
      // Fetch client's basic info
      const userDoc = await getDoc(doc(db, 'users', clientId));
      let clientName = '';
      let coachId = '';
      if (userDoc.exists()) {
        const userData = userDoc.data();
        clientName = userDoc.data().name || '';
        coachId = userData.coachId || '';
      }

      // Fetch preferences
      const preferencesDoc = await getDoc(
        doc(db, 'users', clientId, 'userKnowledge', 'preferences')
      );
      let preferences = [];
      if (preferencesDoc.exists()) {
        preferences = preferencesDoc.data().foodPreferences || [];
      }

      // Fetch goals from the correct path
      const weeklyGoalsDoc = await getDoc(
        doc(
          db,
          'users',
          clientId,
          'userKnowledge',
          'healthGoals',
          'goalsByTime',
          'weeklyGoals'
        )
      );
      let goals = [];
      if (weeklyGoalsDoc.exists()) {
        const data = weeklyGoalsDoc.data();
        goals = Object.keys(data).map((key) => ({ key, value: data[key] }));
      }

      setClientInfo({ name: clientName, preferences, goals, coachId });
    } catch (error) {
      console.error('Error fetching client data:', error);
      alert('Error fetching client data: ' + error.message);
    }
  };

  const fetchClients = async () => {
    try {
      console.log('Fetching clients...');
      const coachDoc = await getDoc(doc(db, 'coaches', user.uid));
      console.log('Coach document:', coachDoc.data());
      
      if (coachDoc.exists() && coachDoc.data().clients) {
        // Add this to remove duplicates
        const uniqueClientIds = [...new Set(coachDoc.data().clients)];
        console.log('Unique client IDs:', uniqueClientIds);
        
        const clientPromises = uniqueClientIds.map((clientId) => 
          getDoc(doc(db, 'users', clientId))
        );
        const clientDocs = await Promise.all(clientPromises);
        
        const filteredClients = clientDocs
          .filter(doc => doc.exists())
          .map(doc => ({ id: doc.id, ...doc.data() }));
        
        console.log('Filtered clients:', filteredClients);
        setClients(filteredClients);
      } else {
        console.log('No clients found, setting empty array');
        setClients([]);
      }
    } catch (error) {
      console.error('Error loading clients:', error);
      alert('Error loading clients: ' + error.message);
    }
  };

  const fetchSessions = async () => {
    try {
      const sessionsCollection = collection(db, 'users', clientId, 'sessions');
      const q = query(sessionsCollection, orderBy('date', 'desc'));
      const querySnapshot = await getDocs(q);

      const fetchedSessions = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setSessions(fetchedSessions);
    } catch (error) {
      console.error('Error fetching sessions:', error);
      alert('Error fetching sessions: ' + error.message);
    }
  };

  const handleSendMessage = async () => {
    if (message.trim()) {
      try {
        // Create message document
        const messageDoc = {
          content: `${coachFirstName} ${message}`,
          is_system_message: false,
          date_sent: new Date(),
          media_url: "",
          source: "web",
          client_id: clientId,
          coach_id: user.uid,
          sender_id: user.uid
        };

        // Add message to user's messages collection
        const messageRef = collection(db, 'users', clientId, 'messages');
        await addDoc(messageRef, messageDoc);

        // Clear input
        setMessage('');
        
      } catch (error) {
        console.error('Error sending message:', error);
        alert('Error sending message: ' + error.message);
      }
    }
  };

  const handleAddPreference = async () => {
    if (newPreference.trim()) {
      try {
        const preferencesDocRef = doc(db, 'users', clientId, 'userKnowledge', 'preferences');
        const docSnapshot = await getDoc(preferencesDocRef);

        if (docSnapshot.exists()) {
          // Document exists, update it
          await updateDoc(preferencesDocRef, {
            foodPreferences: arrayUnion(newPreference),
          });
        } else {
          // Document doesn't exist, create it
          await setDoc(preferencesDocRef, {
            foodPreferences: [newPreference],
          });
        }

        setClientInfo({
          ...clientInfo,
          preferences: [...clientInfo.preferences, newPreference],
        });
        setNewPreference('');
        setIsAddingPreference(false);
      } catch (error) {
        console.error('Error adding preference:', error);
        alert('Error adding preference: ' + error.message);
      }
    }
  };

  const handleDeletePreference = async (preferenceToDelete) => {
    try {
      const preferencesDocRef = doc(db, 'users', clientId, 'userKnowledge', 'preferences');
      await updateDoc(preferencesDocRef, {
        foodPreferences: arrayRemove(preferenceToDelete),
      });
      // Update local state
      setClientInfo((prevState) => ({
        ...prevState,
        preferences: prevState.preferences.filter((pref) => pref !== preferenceToDelete),
      }));
    } catch (error) {
      console.error('Error deleting preference:', error);
      alert('Error deleting preference: ' + error.message);
    }
  };

  const handleUpdateGoal = async () => {
    if (newGoal.trim()) {
      try {
        const weeklyGoalsDocRef = doc(
          db,
          'users',
          clientId,
          'userKnowledge',
          'healthGoals',
          'goalsByTime',
          'weeklyGoals'
        );
  
        const goalKey = 'Goal 1';
  
        // Update or set the goal in Firestore
        await setDoc(
          weeklyGoalsDocRef,
          {
            [goalKey]: newGoal,
          },
          { merge: true } // This ensures that only the specified field is updated
        );
  
        // Update the local state to reflect the new goal
        setClientInfo({
          ...clientInfo,
          goals: [{ key: goalKey, value: newGoal }],
        });
        setNewGoal('');
        setIsUpdatingGoal(false);
      } catch (error) {
        console.error('Error updating goal:', error);
        alert('Error updating goal: ' + error.message);
      }
    }
  };

  const handleDeleteGoal = async (goalKey) => {
    try {
      const weeklyGoalsDocRef = doc(
        db,
        'users',
        clientId,
        'userKnowledge',
        'healthGoals',
        'goalsByTime',
        'weeklyGoals'
      );
      await updateDoc(weeklyGoalsDocRef, {
        [goalKey]: deleteField(),
      });
      // Update local state
      setClientInfo((prevState) => ({
        ...prevState,
        goals: prevState.goals.filter((goalObj) => goalObj.key !== goalKey),
      }));
    } catch (error) {
      console.error('Error deleting goal:', error);
      alert('Error deleting goal: ' + error.message);
    }
  };

  const handleManageNudges = () => {
    setShowNudgeManager(true);
  };

  const handleTrackAdherence = async () => {
    await checkIfTrackedToday();
    setIsAdherenceModalOpen(true);
  };

  const handleAdherenceSubmit = async (adherenceValue) => {
    try {
      const today = format(new Date(), 'yyyy-MM-dd');
  
      // Prepare adherence data for coach
      const coachAdherenceData = {
        date: today,
        adherenceValue: adherenceValue,
        clientId: clientId,
      };
  
      // Write to coach's Firestore
      const coachAdherenceRef = collection(db, 'coaches', user.uid, 'adherence');
      const q = query(
        coachAdherenceRef,
        where('date', '==', today),
        where('clientId', '==', clientId),
        limit(1)
      );
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        // Update existing document
        const docRef = querySnapshot.docs[0].ref;
        await updateDoc(docRef, coachAdherenceData);
      } else {
        // Create new document
        await addDoc(coachAdherenceRef, coachAdherenceData);
      }
  
      // Prepare adherence data for client
      const clientAdherenceData = {
        date: today,
        adherenceValue: adherenceValue,
        coachId: user.uid,
      };
  
      // Write to client's Firestore
      const clientAdherenceDocRef = doc(db, 'users', clientId, 'adherence', today);
      await setDoc(clientAdherenceDocRef, clientAdherenceData);
  
      // Update state
      setHasTrackedToday(true);
      setCurrentAdherenceValue(adherenceValue);
      setPopupMessage('Adherence tracked successfully!');
      setShowPopup(true);
    } catch (error) {
      console.error('Error saving adherence data:', error);
      alert('Error saving adherence data: ' + error.message);
    }
  };

  const checkIfTrackedToday = async () => {
    try {
      const today = format(new Date(), 'yyyy-MM-dd');
      const coachAdherenceRef = collection(db, 'coaches', user.uid, 'adherence');
      const q = query(
        coachAdherenceRef,
        where('date', '==', today),
        where('clientId', '==', clientId),
        limit(1)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const docData = querySnapshot.docs[0].data();
        setHasTrackedToday(true);
        setCurrentAdherenceValue(docData.adherenceValue);
      } else {
        setHasTrackedToday(false);
        setCurrentAdherenceValue(null);
      }
    } catch (error) {
      console.error('Error checking adherence for today:', error);
      alert('Error checking adherence for today: ' + error.message);
    }
  };

  useEffect(() => {
    const fetchSessionData = async () => {
      if (selectedSessionId) {
        try {
          const sessionDocRef = doc(db, 'users', clientId, 'sessions', selectedSessionId);
          const docSnapshot = await getDoc(sessionDocRef);
          if (docSnapshot.exists()) {
            setSessionData({ id: docSnapshot.id, ...docSnapshot.data() });
          } else {
            console.error('Session document does not exist!');
          }
        } catch (error) {
          console.error('Error fetching session data:', error);
          alert('Error fetching session data: ' + error.message);
        }
      } else {
        setSessionData(null);
      }
    };
    fetchSessionData();
  }, [selectedSessionId]);

  const handleSaveSession = async () => {
    if (!sessionData || !sessionData.note || sessionData.note.trim() === '') {
      setPopupMessage('Session note required to save session.');
      setShowPopup(true);
      return;
    }
    try {
      if (isAddingSession) {
        // Create new session
        const newSessionRef = await addDoc(collection(db, 'users', clientId, 'sessions'), {
          date: new Date().toISOString(),
          note: sessionData.note,
          insights: newInsights || null,
        });
        setSessions([
          { id: newSessionRef.id, date: new Date().toISOString(), note: sessionData.note, insights: newInsights || null, },
          ...sessions,
        ]);
        setSelectedSessionId(newSessionRef.id);
        setIsAddingSession(false);
        setPopupMessage('Session saved successfully!');
        setShowPopup(true);
  
        try {
          // Only send recap message for new sessions
          const sendMessageToClientCallable = httpsCallable(functions, 'sendMessageToClient');
          const recapSuccess = await processSessionRecap(clientId, sendMessageToClientCallable);
          if (!recapSuccess) {
            console.log('Recap message failed but session was saved');
          }
        } catch (recapError) {
          console.error('Error sending recap message:', recapError);
          // Don't block the session save if recap fails
        }
      } else if (selectedSessionId) {
        const sessionDocRef = doc(db, 'users', clientId, 'sessions', selectedSessionId);
        await updateDoc(sessionDocRef, {
          note: sessionData.note,
          insights: newInsights || sessionData.insights || null,
        });
        // Update local sessions array
      setSessions((prevSessions) =>
        prevSessions.map((session) =>
          session.id === selectedSessionId
            ? {
                ...session,
                note: sessionData.note,
                insights: newInsights || sessionData.insights || null,
              }
            : session
        )
      );
      setPopupMessage('Session updated successfully!');
      setShowPopup(true);
    }

    // After saving, merge newInsights into sessionData and reset newInsights
    setSessionData((prevData) => ({
      ...prevData,
      insights: newInsights || prevData.insights || null,
    }));
    setNewInsights(null); // Reset newInsights after saving
  } catch (error) {
    console.error('Error saving session:', error);
    setPopupMessage('Error saving session. Please try again.');
    setShowPopup(true);
  }
};

useEffect(() => {
  console.log('Clients state changed:', clients);
}, [clients]);

const acceptRequest = async (client) => {
  try {
    // ... existing subscription checks ...

    // Create batch for atomic operations
    const batch = writeBatch(db);
    
    // Update user document
    const userRef = doc(db, 'users', client.id);
    batch.update(userRef, {
      coachId: user.uid,
      requestedCoachId: null,
      requestStatus: 'accepted',
      updatedAt: serverTimestamp(),
      subscriptionStatus: 'active',
    });

    // Set message settings
    const messageSettingsRef = doc(db, 'users', client.id, 'settings', 'messageSettings');
    batch.set(messageSettingsRef, {
      requireCoachApproval: true,
      language: 'en',
    });

    // Add welcome messages
    const messagesRef = collection(db, 'users', client.id, 'messages');
    const welcomeMessages = [
      {
        content: `Welcome ${client.name?.split(' ')[0] || ''}!`,
        is_system_message: true,
        date_sent: serverTimestamp(),
        media_url: '',
        source: 'system',
        client_id: client.id,
        coach_id: user.uid,
        sender_id: user.uid
      },
      {
        content: 'You can message me here with anything you need. I will also send your workouts for you to complete!',
        is_system_message: true,
        date_sent: serverTimestamp(),
        media_url: '',
        source: 'system',
        client_id: client.id,
        coach_id: user.uid,
        sender_id: user.uid
      },
      {
        content: 'The app will continuing add cool features to improve your experience. One cool feature is that you can send me what you ate and your nutrition values will be automatically be calculated in the progress view (top left).',
        is_system_message: true,
        date_sent: serverTimestamp(),
        media_url: '',
        source: 'system',
        client_id: client.id,
        coach_id: user.uid,
        sender_id: user.uid
      },
      {
        content: 'For example "I just had a cup of greek yogurt with a handful of blueberries and walnuts."',
        is_system_message: true,
        date_sent: serverTimestamp(),
        media_url: '',
        source: 'system',
        client_id: client.id,
        coach_id: user.uid,
        sender_id: user.uid
      }
    ];

    welcomeMessages.forEach((message, index) => {
      const messageRef = doc(messagesRef);
      batch.set(messageRef, {
        ...message,
        date_sent: serverTimestamp(),
      });
    });

    // Commit all changes atomically
    await batch.commit();

    setMessage('Client request accepted successfully');
  } catch (error) {
    setMessage(`Error accepting request: ${error.message}`);
    setIsRedirectingToCheckout(false);
  }
};

return (
  <div className="min-h-screen bg-[#000000]">
    
    {/* Main content with increased top padding */}
    <div className="pt-20 px-6 pb-6 h-screen"> 
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 h-[calc(100vh-8rem)]">
        {/* Left Column - Chat */}
        <Card className="bg-[#1C1C1C] border-0 shadow-lg h-full overflow-hidden">
          <CardHeader className="bg-[#333333] text-center py-3 rounded-t-lg">
            <CardTitle className="text-[#FFFFFF] text-xl">
              {clientInfo.name || 'Client'}
            </CardTitle>
          </CardHeader>
          <CardContent className="flex flex-col p-4 h-[calc(100vh-12rem)]"> {/* Adjusted height */}
            <div ref={chatContainerRef} className="flex-grow overflow-y-auto mb-4 pr-2">
              {chatMessages.map((msg) => (
                <div
                  key={msg.id}
                  className={`mb-3 ${msg.sender === 'client' ? 'text-left' : 'text-right'}`}
                >
                  <div
                    className={`inline-block p-3 rounded-lg ${
                      msg.sender === 'client' 
                        ? 'bg-[#333333] text-white' 
                        : 'bg-[#7AB8FD] text-black'
                    }`}
                  >
                    {msg.text}
                  </div>
                  <div className="text-xs text-[#808080] mt-1">{msg.time}</div>
                </div>
              ))}
            </div>
            <div className="flex mt-auto">
              <Input
                type="text"
                placeholder="Type your message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="flex-grow mr-2 bg-[#333333] border-[#4F4F4F] text-[#FFFFFF]"
              />
              <Button onClick={handleSendMessage} className="bg-[#6495ED] hover:bg-[#4F7BE8]">
                <Send size={20} />
              </Button>
            </div>
          </CardContent>
        </Card>

        {/* Right Column - Sessions/Client Info */}
        <Card className="bg-[#1C1C1C] border-0 shadow-lg h-full overflow-hidden">
          <CardHeader className="bg-[#333333] rounded-t-lg flex-none">
            <div className="flex flex-row w-full">
              <Button
                variant="ghost"
                className={`flex-1 text-[#FFFFFF] ${
                  activeTab === 'sessions' ? 'border-b-2 border-[#6495ED]' : ''
                }`}
                onClick={() => setActiveTab('sessions')}
              >
                Sessions
              </Button>
              <Button
                variant="ghost"
                className={`flex-1 text-[#FFFFFF] ${
                  activeTab === 'nutrition' ? 'border-b-2 border-[#6495ED]' : ''
                }`}
                onClick={() => setActiveTab('nutrition')}
              >
                Nutrition
              </Button>
              <Button
                variant="ghost"
                className={`flex-1 text-[#FFFFFF] ${
                  activeTab === 'clientInfo' ? 'border-b-2 border-[#6495ED]' : ''
                }`}
                onClick={() => setActiveTab('clientInfo')}
              >
                Client Info
              </Button>
            </div>
          </CardHeader>
          <CardContent className="p-4 overflow-y-auto h-[calc(100%-5rem)]">
            {activeTab === 'sessions' && (
              <SessionTab
                clientId={clientId}
                sessions={sessions}
                setSessions={setSessions}
                selectedSessionId={selectedSessionId}
                setSelectedSessionId={setSelectedSessionId}
                isAddingSession={isAddingSession}
                setIsAddingSession={setIsAddingSession}
                sessionData={sessionData}
                setSessionData={setSessionData}
                clientInfo={clientInfo}
                setClientInfo={setClientInfo}
                isUpdatingGoal={isUpdatingGoal}
                setIsUpdatingGoal={setIsUpdatingGoal}
                newGoal={newGoal}
                setNewGoal={setNewGoal}
                handleUpdateGoal={handleUpdateGoal}
                handleTrackAdherence={handleTrackAdherence}
                handleManageNudges={handleManageNudges}
                handleSaveSession={handleSaveSession}
                newInsights={newInsights}
                setNewInsights={setNewInsights}
              />
            )}
            {activeTab === 'clientInfo' && (
              <ClientInfoTab
                clientInfo={clientInfo}
                clientId={clientId}
                isAddingPreference={isAddingPreference}
                setIsAddingPreference={setIsAddingPreference}
                newPreference={newPreference}
                setNewPreference={setNewPreference}
                handleAddPreference={handleAddPreference}
                handleDeletePreference={handleDeletePreference}
              />
            )}
            {activeTab === 'nutrition' && (
              <NutritionTab clientId={clientId} />
            )}
          </CardContent>
        </Card>
              </div>
            </div>

            {/* Modals remain unchanged */}
            {showNudgeManager && (
              <NudgeManager
                nudges={nudges}
                onClose={() => setShowNudgeManager(false)}
                onUpdateNudges={fetchNudges}
                clientId={clientId}
                coachFirstName={coachFirstName}
              />
            )}
            {isAdherenceModalOpen && (
              <AdherenceModal
                onClose={() => setIsAdherenceModalOpen(false)}
                onSubmit={handleAdherenceSubmit}
                currentAdherence={currentAdherenceValue}
              />
            )}
            {showPopup && (
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="bg-gray-800 text-white px-6 py-4 rounded shadow">
                  {popupMessage}
                </div>
              </div>
            )}
          </div>
        );
      };
      

export default ClientPage;
