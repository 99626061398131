// src/services/workoutTemplateService.js

import { db } from '../firebase';
import { doc, collection, addDoc, setDoc, getDoc, getDocs, updateDoc, writeBatch, Timestamp } from 'firebase/firestore';

// const PATHS = {
//   EXERCISES: 'exercises',
//   COACH_CUSTOM_EXERCISES: ['coaches', '{coachId}', 'customExercises'],
//   WORKOUT_TEMPLATES: ['coaches', '{coachId}', 'workoutTemplates'],
//   USER_WORKOUTS: ['users', '{userId}', 'workouts'],
// };

export const getExerciseData = async (exerciseId, coachId = null) => {
  if (coachId) {
    const customExerciseRef = doc(db, 'coaches', coachId, 'customExercises', exerciseId);
    const customSnap = await getDoc(customExerciseRef);
    if (customSnap.exists()) {
      return customSnap.data();
    }
  }

  // fallback to global
  const exerciseRef = doc(db, 'exercises', exerciseId);
  const exerciseSnap = await getDoc(exerciseRef);
  return exerciseSnap.exists() ? exerciseSnap.data() : null;
};

/**
 * Create a workout template
 */
export const createWorkoutTemplate = async (coachId, templateData) => {
  const exercisesWithData = await Promise.all(
    templateData.exercises.map(async (exercise) => {
      const exerciseData = await getExerciseData(exercise.exerciseId, coachId);
      return {
        ...exercise,
        name: exerciseData.name,
        isCustomExercise: exerciseData.coachId ? true : false
      };
    })
  );

  const template = {
    ...templateData,
    exercises: exercisesWithData,
    coachId,
    isTemplate: true,
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now()
  };

  const templateRef = collection(db, 'coaches', coachId, 'workoutTemplates');
  const docRef = await addDoc(templateRef, template);
  return docRef.id;
};

/**
 * Update a workout template by ID
 */
export const updateWorkoutTemplate = async (coachId, templateId, templateData) => {
  const exercisesWithData = await Promise.all(
    templateData.exercises.map(async (exercise) => {
      const exerciseData = await getExerciseData(exercise.exerciseId, coachId);
      return {
        ...exercise,
        name: exerciseData.name,
        isCustomExercise: exerciseData.coachId ? true : false
      };
    })
  );

  const template = {
    ...templateData,
    exercises: exercisesWithData,
    updatedAt: Timestamp.now()
  };

  const templateRef = doc(db, 'coaches', coachId, 'workoutTemplates', templateId);
  await updateDoc(templateRef, template);

  return true;
};

/**
 * Get a single workout template by ID
 */
export const getWorkoutTemplate = async (coachId, templateId) => {
  const templateRef = doc(db, 'coaches', coachId, 'workoutTemplates', templateId);
  const snap = await getDoc(templateRef);
  return snap.exists() ? snap.data() : null;
};



