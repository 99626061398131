// src/components/overlays/TemplateManagementOverlay.jsx

import React, { useState, useEffect } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import { Card, CardContent } from '../ui/Card';
import { Button } from '../ui/Button';
import { Plus, Trash2, Calendar } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Input } from '../ui/Input';
import { createWorkoutTemplate } from '../../services/workoutTemplateService';


const TemplateManagementOverlay = ({ onClose, onTemplateSelect, isAssigning }) => {
  const { user } = useAuth();
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [isCreating, setIsCreating] = useState(false);
  const [newTemplate, setNewTemplate] = useState({
    name: '',
    description: '',
    sections: [],
    exercises: []
  });

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const templatesRef = collection(db, `coaches/${user.uid}/workoutTemplates`);
        const snapshot = await getDocs(templatesRef);
        const templateList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        templateList.sort((a, b) => b.createdAt?.toMillis() - a.createdAt?.toMillis());
        setTemplates(templateList);
      } catch (error) {
        console.error('Error fetching templates:', error);
      } finally {
        setLoading(false);
      }
    };

    
    if (user?.uid) {
      fetchTemplates();
    }
  }, [user]);

  const handleDeleteTemplate = async (e, templateId) => {
    e.stopPropagation();
    try {
      await deleteDoc(doc(db, `coaches/${user.uid}/workoutTemplates/${templateId}`));
      setTemplates(templates.filter(template => template.id !== templateId));
    } catch (error) {
      console.error('Error deleting template:', error);
    }
  };

  const handleCreateClick = () => {
    onTemplateSelect({
      name: '',
      description: '',
      sections: [],
      exercises: [],
      isNew: true
    });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
      <div className="bg-neutral-900 max-w-2xl w-full rounded-lg p-4 relative">
        <div className="absolute top-2 right-2">
          <Button
            onClick={onClose}
            variant="ghost"
            className="text-white hover:text-gray-300"
          >
            X
          </Button>
        </div>
        <div className="flex items-center justify-between mb-4 max-w-[calc(100%-3rem)]">
          <h2 className="text-xl font-bold text-white">Select a Template</h2>
          <Button
            onClick={handleCreateClick}
            className="bg-blue-600 hover:bg-blue-700"
          >
            <Plus className="h-4 w-4 mr-2" />
            Create Template
          </Button>
        </div>
        {loading ? (
          <div className="flex justify-center py-8">Loading...</div>
        ) : (
          <div className="space-y-4 max-h-96 overflow-y-auto">
            {templates.map(t => (
              <Card
                key={t.id}
                className="bg-neutral-800 hover:bg-neutral-700 cursor-pointer transition-colors"
                onClick={() => onTemplateSelect(t)}
              >
                <CardContent className="p-4">
                  <div className="flex items-center justify-between">
                    <div>
                      <h3 className="font-semibold text-white">{t.name}</h3>
                      <p className="text-sm text-gray-400">{t.description}</p>
                      <p className="text-sm text-blue-400">{t.exercises?.length || 0} exercises</p>
                    </div>
                    <div className="flex items-center gap-2">
                      {t.headerImageUrl && (
                        <img
                          src={t.headerImageUrl}
                          alt="Template"
                          className="h-16 w-16 object-cover rounded"
                        />
                      )}
                      <Button
                        variant="destructive"
                        size="icon"
                        onClick={(e) => handleDeleteTemplate(e, t.id)}
                        className="h-8 w-8 bg-red-600 hover:bg-red-700"
                        title="Delete Template"
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default TemplateManagementOverlay;
