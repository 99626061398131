// src/components/ClientGoalTab.jsx
import React, { useState, useEffect } from 'react';
import { Plus, Timer, AlertCircle } from 'lucide-react';
import { collection, doc, getDocs, addDoc, updateDoc, deleteDoc, query, orderBy } from 'firebase/firestore';
import { db } from '../firebase';
import { Button } from './ui/Button';
import { Card, CardContent } from './ui/Card';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from './ui/Dialog';
import { Badge } from './ui/Badge';
import GoalForm from './GoalForm';

const ClientGoalTab = ({ clientId }) => {
  const [goals, setGoals] = useState([]);
  const [isAddingGoal, setIsAddingGoal] = useState(false);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [goalToDelete, setGoalToDelete] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    targetDate: '',
    targetValue: '',
    currentValue: '',
    unit: '',
  });

  useEffect(() => {
    fetchGoals();
  }, [clientId]);

  const fetchGoals = async () => {
    try {
      const goalsRef = collection(db, 'users', clientId, 'userKnowledge', 'goals', 'items');
      const q = query(goalsRef, orderBy('targetDate', 'asc'));
      const snapshot = await getDocs(q);
      const goalsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setGoals(goalsData);
    } catch (error) {
      console.error('Error fetching goals:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const resetForm = () => {
    setFormData({
      title: '',
      targetDate: '',
      targetValue: '',
      currentValue: '',
      unit: '',
    });
    setSelectedGoal(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const goalsRef = collection(db, 'users', clientId, 'userKnowledge', 'goals', 'items');
      const goalData = {
        ...formData,
        targetValue: Number(formData.targetValue),
        currentValue: formData.currentValue ? Number(formData.currentValue) : 0,
      };
      
      if (selectedGoal) {
        await updateDoc(doc(goalsRef, selectedGoal.id), {
          ...goalData,
          updatedAt: new Date().toISOString(),
        });
      } else {
        await addDoc(goalsRef, {
          ...goalData,
          currentValue: 0,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        });
      }
      
      await fetchGoals();
      setIsAddingGoal(false);
      resetForm();
    } catch (error) {
      console.error('Error saving goal:', error);
    }
  };

  const handleDeleteGoal = async () => {
    if (!goalToDelete) return;
    
    try {
      const goalRef = doc(db, 'users', clientId, 'userKnowledge', 'goals', 'items', goalToDelete);
      await deleteDoc(goalRef);
      await fetchGoals();
      setGoalToDelete(null);
    } catch (error) {
      console.error('Error deleting goal:', error);
    }
  };

  const handleEditGoal = (goal) => {
    setSelectedGoal(goal);
    setFormData({
      title: goal.title,
      targetDate: goal.targetDate,
      targetValue: goal.targetValue.toString(),
      currentValue: goal.currentValue?.toString() || '',
      unit: goal.unit,
    });
    setIsAddingGoal(true);
  };

  const calculateProgress = (current, target) => {
    return Math.round((current / target) * 100);
  };

  const getDaysRemaining = (targetDate) => {
    const today = new Date();
    const target = new Date(targetDate);
    const diffTime = target - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const renderGoalCard = (goal) => {
    const progress = calculateProgress(goal.currentValue, goal.targetValue);
    const daysRemaining = getDaysRemaining(goal.targetDate);
    const isOverdue = daysRemaining < 0;

    return (
      <Card key={goal.id} className="relative group hover:shadow-md transition-shadow duration-200">
        <CardContent className="pt-6">
          <div className="flex justify-between items-start gap-4">
            <div className="space-y-3 flex-1">
              <div className="flex items-center gap-2 flex-wrap">
                <h3 className="text-lg font-semibold">{goal.title}</h3>
                <Badge variant="outline">
                  {progress}% Complete
                </Badge>
                {isOverdue && (
                  <Badge variant="destructive" className="flex items-center gap-1">
                    <AlertCircle className="w-3 h-3" />
                    Overdue
                  </Badge>
                )}
              </div>
              
              <div className="flex flex-wrap gap-4 text-sm text-neutral-400">
                <div className="flex items-center">
                  <Timer className="w-4 h-4 mr-1" />
                  <span>Target: {new Date(goal.targetDate).toLocaleDateString()}</span>
                </div>
                {!isOverdue && daysRemaining > 0 && (
                  <Badge variant="outline" className="bg-neutral-900">
                    {daysRemaining} days remaining
                  </Badge>
                )}
              </div>

              <div className="bg-neutral-800 rounded-full h-2 w-full overflow-hidden">
                <div 
                  className="bg-blue-500 h-full transition-all duration-300"
                  style={{ width: `${progress}%` }}
                />
              </div>

              <div className="flex justify-between text-sm">
                <span className="text-neutral-400">
                  Current: {goal.currentValue} {goal.unit}
                </span>
                <span className="text-neutral-400">
                  Target: {goal.targetValue} {goal.unit}
                </span>
              </div>
            </div>

            <div className="flex gap-2">
              <Button
                variant="ghost"
                size="sm"
                className="hover:bg-neutral-800"
                onClick={() => handleEditGoal(goal)}
              >
                Edit
              </Button>
              <Button
                variant="ghost"
                size="sm"
                className="text-red-500 hover:text-red-400 hover:bg-red-950"
                onClick={() => setGoalToDelete(goal.id)}
              >
                Delete
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-2xl font-bold">Goals</h2>
          <p className="text-neutral-400">Track and manage client goals</p>
        </div>
        <Button onClick={() => setIsAddingGoal(true)} className="gap-2">
          <Plus className="w-4 h-4" />
          Add Goal
        </Button>
      </div>

      {/* Goals List */}
      <div className="space-y-4">
        {goals.length === 0 ? (
          <Card>
            <CardContent className="pt-6">
              <p className="text-center text-neutral-400">No goals have been set yet.</p>
            </CardContent>
          </Card>
        ) : (
          goals.map(renderGoalCard)
        )}
      </div>

      {/* Add/Edit Goal Dialog */}
      <Dialog 
        open={isAddingGoal} 
        onOpenChange={(open) => {
          if (!open) {
            resetForm();
          }
          setIsAddingGoal(open);
        }}
      >
        <GoalForm
          isEditing={!!selectedGoal}
          formData={formData}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          onClose={() => {
            setIsAddingGoal(false);
            resetForm();
          }}
        />
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={!!goalToDelete} onOpenChange={(open) => !open && setGoalToDelete(null)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete Goal</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete this goal? This action cannot be undone.
            </DialogDescription>
          </DialogHeader>
          <div className="flex justify-end gap-3 mt-4">
            <Button
              variant="ghost"
              onClick={() => setGoalToDelete(null)}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDeleteGoal}
              variant="destructive"
              className="bg-red-500 hover:bg-red-600"
            >
              Delete
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ClientGoalTab;