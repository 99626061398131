// src/components/AccountPage.js
import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from './ui/Card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from './ui/Tabs';
import { Button } from './ui/Button';
import { Progress } from './ui/Progress';
import { Settings, HelpCircle } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { motion } from 'framer-motion';
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { collection, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import { FirstAccountTour } from '../tours';
import Joyride from 'react-joyride';
import { getFunctions, httpsCallable } from 'firebase/functions';

import NutritionPhilosophyEditor from './AccountPageTabs/NutritionPhilosophyEditor';
import ProductsTab from './AccountPageTabs/ProductsTab';
import MessagingTab from './AccountPageTabs/MessagingTab';
import RoutineBuilder from './AccountPageTabs/RoutineBuilder';



console.log("FirstAccountTour content:", FirstAccountTour);

const AccountPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [activeTab, setActiveTab] = useState("nutrition");
  const [isFirstSignup, setIsFirstSignup] = useState(false);
  const [showTourModal, setShowTourModal] = useState(false);
  const [tourStep, setTourStep] = useState(0);
  const [showAiDemo, setShowAiDemo] = useState(false);
  const [demoResponses, setDemoResponses] = useState('');
  const [isGeneratingDemo, setIsGeneratingDemo] = useState(false);
  const [displayedText, setDisplayedText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showChatOverlay, setShowChatOverlay] = useState(false);
  const [demoMessages, setDemoMessages] = useState([]);
  const [showLearningModal, setShowLearningModal] = useState(false);
  const [learningResponses, setLearningResponses] = useState([]);
  const [pendingDemoClient, setPendingDemoClient] = useState(null);

  useEffect(() => {
    console.log("AccountPage mounted");
    const checkFirstTime = async () => {
      if (user?.uid) {
        try {
          const userDoc = await getDoc(doc(db, 'coaches', user.uid));
          
          if (userDoc.exists()) {
            const isFirstTime = userDoc.data().firstTime === true;
            setShowWelcomeModal(isFirstTime);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          setShowWelcomeModal(false);
        }
      }
    };

    checkFirstTime();
  }, [user?.uid]);

  useEffect(() => {
    console.log("Welcome modal state:", showWelcomeModal);
  }, [showWelcomeModal]);

  useEffect(() => {
    console.log("Tour modal state:", showTourModal);
  }, [showTourModal]);

  const handleStartSetup = () => {
    console.log("Starting setup");
    setShowWelcomeModal(false);
    setShowTourModal(true);
    setTourStep(0);
    console.log("Tour modal and step set");
  };

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (!showWelcomeModal && !showTourModal) {
  //       console.log("Forcing welcome modal");
  //       setShowWelcomeModal(true);
  //     }
  //   }, 1000);
  //   return () => clearTimeout(timer);
  // }, []);

  useEffect(() => {
    if (demoResponses && !isGeneratingDemo) {
      const timer = setTimeout(() => {
        if (currentIndex < demoResponses.length) {
          setDisplayedText(prev => prev + demoResponses[currentIndex]);
          setCurrentIndex(prev => prev + 1);
        }
      }, 30);

      return () => clearTimeout(timer);
    }
  }, [currentIndex, demoResponses, isGeneratingDemo]);

  const generateDemoResponse = async () => {
    setIsGeneratingDemo(true);
    try {
      // Gather all coach data
      const coachData = {
        products: [],
        nutrition: {},
        routines: {},
        daily: {}
      };

      // Get all products
      const productsRef = collection(db, 'coaches', user.uid, 'products');
      const productsSnap = await getDocs(productsRef);
      coachData.products = productsSnap.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Get nutrition philosophy
      const nutritionRef = doc(db, 'coaches', user.uid, 'philosophy', 'nutrition');
      const nutritionSnap = await getDoc(nutritionRef);
      if (nutritionSnap.exists()) {
        coachData.nutrition = nutritionSnap.data();
      }

      // Get daily routines
      const dailyRef = doc(db, 'coaches', user.uid, 'daily', 'morning');
      const dailySnap = await getDoc(dailyRef);
      if (dailySnap.exists()) {
        coachData.daily = dailySnap.data();
      }

      // Get routines
      const routinesRef = collection(db, 'coaches', user.uid, 'routines');
      const routinesSnap = await getDocs(routinesRef);
      coachData.routines = routinesSnap.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      console.log("Gathered coach data:", coachData);

      const functions = getFunctions(undefined, 'us-central1');
      const generateDemoResponseFunction = httpsCallable(
        functions, 
        'generateDemoResponse',
        { timeout: 300000 }
      );
      
      const result = await generateDemoResponseFunction({ coachData });
      console.log("Function result:", result);
      
      if (result.data.success) {
        setDemoResponses(result.data.data);
        setShowAiDemo(true);
      } else {
        throw new Error(result.data.message || 'Failed to generate responses');
      }
    } catch (error) {
      console.error('Error generating demo responses:', error);
      setDemoResponses('Error generating responses. Please try again.');
    } finally {
      setIsGeneratingDemo(false);
    }
  };
  const handleNextStep = async () => {
    console.log("Current tour step:", tourStep);

    if (tourStep === 0) {
      const docRef = doc(db, 'coaches', user.uid, 'philosophy', 'nutrition');
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists() && docSnap.data().selectedDiet === 'keto') {
        setTourStep(1);
        setActiveTab("routines");
      } else {
        alert("Please select Keto diet to continue");
      }
    } else if (tourStep === 1) {
      setTourStep(2);
      setTimeout(() => {
        setActiveTab("products");
      }, 100);
    } else if (tourStep === 2) {
      // Check if protein powder is selected
      const productsRef = collection(db, 'coaches', user.uid, 'products');
      const productsSnapshot = await getDocs(productsRef);
      const hasProteinPowder = productsSnapshot.docs.some(
        doc => doc.data().name === 'Protein Powder'
      );

      if (hasProteinPowder) {
        setTourStep(3);
        setTimeout(() => {
          setActiveTab("messaging");
        }, 100);
      } else {
        alert("Please select Protein Powder to continue");
      }
    } else if (tourStep === 3) {
      setTourStep(5);
      setShowTourModal(false);
      setShowLearningModal(true);
    } else if (tourStep === 5) {
      await generateDemoResponse();
      setTourStep(6);
    } else if (tourStep === 6) {
      // Clean up all modals and states
      setShowAiDemo(false);
      setShowTourModal(false);
      setShowWelcomeModal(false);
      setDemoResponses('');
      setDisplayedText('');
      setCurrentIndex(0);
      
      if (user?.uid) {
        try {
          await updateDoc(doc(db, 'coaches', user.uid), {
            firstTime: false
          });
        } catch (error) {
          console.error("Error updating first-time status:", error);
        }
      }
    }
  };

  const handleBack = () => {
    if (tourStep === 1) {
      setActiveTab("nutrition");
      setTourStep(0);
    } else if (tourStep === 2) {
      setActiveTab("routines");
      setTourStep(1);
    } else if (tourStep === 3) {
      setActiveTab("products");
      setTourStep(2);
    }
  };

  const ChatOverlay = ({ initialMessages = [], onClose }) => {
    const [messages, setMessages] = useState(initialMessages);
    const [input, setInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [coachContext, setCoachContext] = useState(null);
    const { user } = useAuth();

    useEffect(() => {
      // Gather coach data when overlay opens
      const gatherCoachData = async () => {
        const coachData = {
          products: [],
          nutrition: {},
          routines: {},
          daily: {}
        };

        try {
          // Get all products
          const productsRef = collection(db, 'coaches', user.uid, 'products');
          const productsSnap = await getDocs(productsRef);
          coachData.products = productsSnap.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));

          // Get nutrition philosophy
          const nutritionRef = doc(db, 'coaches', user.uid, 'philosophy', 'nutrition');
          const nutritionSnap = await getDoc(nutritionRef);
          if (nutritionSnap.exists()) {
            coachData.nutrition = nutritionSnap.data();
          }

          // Get daily routines
          const dailyRef = doc(db, 'coaches', user.uid, 'daily', 'morning');
          const dailySnap = await getDoc(dailyRef);
          if (dailySnap.exists()) {
            coachData.daily = dailySnap.data();
          }

          // Get routines
          const routinesRef = collection(db, 'coaches', user.uid, 'routines');
          const routinesSnap = await getDocs(routinesRef);
          coachData.routines = routinesSnap.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));

          setCoachContext(coachData);
        } catch (error) {
          console.error('Error gathering coach data:', error);
        }
      };

      gatherCoachData();
    }, [user.uid]);

    const handleSubmit = async (e) => {
      e.preventDefault();
      if (!input.trim() || !coachContext) return;

      const userMessage = input.trim();
      setInput('');

      // Get previous message and last 5 messages
      const previousMessage = messages.length > 0 ? messages[messages.length - 1].content : null;
      const lastFiveMessages = messages.slice(-5).map(msg => msg.content).join('\n');

      setMessages(prev => [...prev, { role: 'user', content: userMessage }]);
      setIsLoading(true);

      try {
        const functions = getFunctions();
        const generateDemoResponse = httpsCallable(functions, 'generateDemoResponse');
        
        const result = await generateDemoResponse({
          message: userMessage,
          coachData: coachContext,
          previousMessage: previousMessage,
          lastFiveMessages: lastFiveMessages
        });
        
        if (result.data.success && result.data.data) {
          setMessages(prev => [...prev, { 
            role: 'assistant', 
            content: result.data.data 
          }]);
        } else {
          throw new Error(result.data.message || 'No response received');
        }
      } catch (error) {
        console.error('Error getting response:', error);
        setMessages(prev => [...prev, { 
          role: 'assistant', 
          content: 'Sorry, I encountered an error. Please try again.' 
        }]);
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <div className="fixed inset-0 flex items-center justify-center z-[99999] bg-black bg-opacity-50">
        <div className="bg-[#1C1C1C] rounded-lg w-full max-w-3xl mx-4 h-[80vh] flex flex-col">
          <div className="flex justify-between items-center p-4 border-b border-neutral-800">
            <h2 className="text-xl font-bold text-white">Chat with Your AI Coach</h2>
            <button 
              onClick={onClose}
              className="text-neutral-400 hover:text-white p-2"
            >
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                className="h-6 w-6" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M6 18L18 6M6 6l12 12" 
                />
              </svg>
            </button>
          </div>
          
          <div className="flex-1 overflow-y-auto p-4 space-y-4">
            {messages.map((message, index) => (
              <div 
                key={index}
                className={`p-3 rounded-lg ${
                  message.role === 'user' 
                    ? 'bg-blue-600 ml-auto text-white' 
                    : 'bg-neutral-800 text-white'
                } max-w-[80%]`}
              >
                {message.content}
              </div>
            ))}
            {isLoading && (
              <div className="bg-neutral-800 p-3 rounded-lg max-w-[80%] text-white">
                Thinking...
              </div>
            )}
          </div>
          
          <form onSubmit={handleSubmit} className="p-4 border-t border-neutral-800">
            <div className="flex gap-2">
              <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Ask your AI coach a question..."
                className="flex-1 bg-neutral-800 p-2 rounded-lg text-white"
              />
              <button 
                type="submit"
                disabled={isLoading}
                className="bg-blue-600 px-4 py-2 rounded-lg hover:bg-blue-700 disabled:opacity-50 text-white"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  const AiDemoModal = () => (
    <div className="fixed inset-0 flex items-center justify-center z-[99999]">
      <div className="fixed inset-0 bg-black bg-opacity-50" />
      <div className="relative bg-[#1C1C1C] p-8 rounded-lg max-w-2xl mx-4 z-[99999]">
        <div className="text-white font-mono whitespace-pre-line min-h-[200px] relative z-[99999]">
          {tourStep === 5 ? (
            <h2 className="text-2xl font-bold mb-4">Now let's check your AI in action!</h2>
          ) : (
            isGeneratingDemo ? (
              <div className="flex items-center gap-2">
                <span>Generating responses...</span>
              </div>
            ) : (
              <div className="relative z-[99999] space-y-4">
                {demoResponses}
              </div>
            )
          )}
        </div>
        <button
          onClick={async () => {
            if (user?.uid) {
              try {
                await updateDoc(doc(db, 'coaches', user.uid), {
                  firstTime: false
                });
              } catch (error) {
                console.error("Error updating firstTime status:", error);
              }
            }
            setShowAiDemo(true);
            setShowChatOverlay(true);
          }}
          className="mt-6 px-4 py-2 rounded-md bg-[#6495ED] hover:bg-blue-600 text-white relative z-[99999]"
        >
          {tourStep === 5 ? "Show Me" : "Start Chatting"}
        </button>
      </div>
    </div>
  );

  const TourModal = () => {
    if (!showTourModal) return null;
    
    return (
      <motion.div 
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        className={`fixed ${getPosition()} transform -translate-x-1/2 z-[1000] bg-[#1C1C1C] p-6 rounded-lg max-w-md mx-4`}
      >
        <div className="text-white">
          <p className="mb-4">{FirstAccountTour[tourStep]?.content}</p>
          <div className="flex justify-between">
            {tourStep > 0 && tourStep < 5 && (
              <button
                onClick={handleBack}
                className="px-4 py-2 rounded-md border border-gray-600 hover:bg-gray-700"
              >
                Back
              </button>
            )}
            <button
              onClick={handleNextStep}
              className="px-4 py-2 rounded-md bg-[#6495ED] hover:bg-blue-600 ml-auto"
            >
              {tourStep === 5 ? "Show Me" : tourStep === 4 ? "Next" : "Next"}
            </button>
          </div>
        </div>
      </motion.div>
    );
  };

  const getPosition = () => {
    console.log("Getting position for step:", tourStep);
    switch (tourStep) {
      case 0:
        return 'top-24 left-[35%]';
      case 1:
        return 'top-24 left-1/2';
      case 2:
        return 'top-24 left-[60%]';
      case 3:
        return 'top-24 left-[85%]';
      default:
        return 'top-24 left-1/2';
    }
  };

  useEffect(() => {
    const loadDemoMessages = async () => {
      if (!user) return;
      
      try {
        const demoMessagesRef = collection(db, 'coaches', user.uid, 'demoMessages');
        const messagesSnap = await getDocs(demoMessagesRef);
        const messages = messagesSnap.docs
          .map(doc => doc.data())
          .sort((a, b) => a.timestamp?.seconds - b.timestamp?.seconds);
        setDemoMessages(messages);
      } catch (error) {
        console.error('Error loading demo messages:', error);
      }
    };

    loadDemoMessages();
  }, [user]);

  const LearningOverlay = ({ onClose }) => {
    const [answer, setAnswer] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [learningResponses, setLearningResponses] = useState([]);
    const questions = [
      "Hey, I couldnt complete my last workout",
      "I don't like tempeh, what other vegetarian protein sources should I have?",
      "Just did biceps to failure",
      "Not in the mood to workout today",
      "I do not like fish",
      'Yo i didnt like the last meal',
      'Need some motivation. Rough day.',
      'My back is sore',
      'I dont like bicep curls is there aything else I can do?',
      'Should I intermittent fast',
    ];

    // Load existing responses on mount
    useEffect(() => {
      const loadExistingResponses = async () => {
        if (!user?.uid) return;
        
        try {
          const qaRef = collection(db, 'coaches', user.uid, 'trainingQA');
          const qaSnap = await getDocs(qaRef);
          const responses = qaSnap.docs.map(doc => ({
            question: doc.data().question,
            answer: doc.data().answer
          }));
          
          setLearningResponses(responses);
          // Set current question index to the number of existing responses
          setCurrentQuestionIndex(responses.length);
        } catch (error) {
          console.error('Error loading existing responses:', error);
        }
      };

      loadExistingResponses();
    }, [user?.uid]);

    const handleSubmit = async (e) => {
      e.preventDefault();
      if (!answer.trim()) return;

      setIsLoading(true);
      try {
        // Store the Q&A pair
        const qaRef = collection(db, 'coaches', user.uid, 'trainingQA');
        await addDoc(qaRef, {
          question: questions[currentQuestionIndex],
          answer: answer.trim(),
          timestamp: serverTimestamp()
        });

        // Add to local state
        setLearningResponses(prev => [...prev, { 
          question: questions[currentQuestionIndex], 
          answer: answer.trim() 
        }]);
        
        // Clear answer and move to next question
        setAnswer('');
        setCurrentQuestionIndex(prev => prev + 1);
      } catch (error) {
        console.error('Error storing Q&A:', error);
      } finally {
        setIsLoading(false);
      }
    };

    const isComplete = learningResponses.length === questions.length;

    return (
      <div className="fixed inset-0 flex items-center justify-center z-[99999] bg-black bg-opacity-50">
        <div className="bg-[#1C1C1C] rounded-lg w-full max-w-3xl mx-4 h-[80vh] flex flex-col">
          <div className="flex justify-between items-center p-4 border-b border-neutral-800">
            <h2 className="text-xl font-bold text-white">Help Train Your AI Coach</h2>
            <button onClick={onClose} className="text-neutral-400 hover:text-white">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <div className="flex-1 overflow-y-auto p-4">
            {!isComplete ? (
              <div className="mb-6 text-white">
                <p className="mb-4">Question {currentQuestionIndex + 1} of {questions.length}:</p>
                <p className="text-xl font-semibold mb-6">{questions[currentQuestionIndex]}</p>
              </div>
            ) : (
              <div className="mb-6 text-white">
                <p className="text-xl font-semibold mb-6">All questions completed!</p>
              </div>
            )}

            {learningResponses.map((qa, index) => (
              <div key={index} className="mb-4 p-3 bg-neutral-800 rounded-lg text-white">
                <p className="font-bold">Q: {qa.question}</p>
                <p className="mt-2">A: {qa.answer}</p>
              </div>
            ))}
          </div>

          {!isComplete && (
            <form onSubmit={handleSubmit} className="p-4 border-t border-neutral-800">
              <div className="space-y-4">
                <div>
                  <label className="block text-white mb-2">Your Answer:</label>
                  <textarea
                    value={answer}
                    onChange={(e) => setAnswer(e.target.value)}
                    placeholder="Provide your detailed coaching response..."
                    className="w-full bg-neutral-800 p-2 rounded-lg text-white min-h-[100px]"
                  />
                </div>

                <div className="flex gap-2">
                  <button
                    type="submit"
                    disabled={isLoading || !answer.trim()}
                    className="flex-1 bg-blue-600 px-4 py-2 rounded-lg hover:bg-blue-700 disabled:opacity-50 text-white"
                  >
                    {currentQuestionIndex < questions.length - 1 ? "Next Question" : "Submit Final Answer"}
                  </button>
                </div>
              </div>
            </form>
          )}

          <div className="p-4 border-t border-neutral-800">
            <button
              onClick={() => {
                onClose();
                setShowAiDemo(true);
              }}
              className="w-full bg-green-600 px-4 py-2 rounded-lg hover:bg-green-700 text-white"
              disabled={!isComplete}
            >
              Continue to AI Demo
            </button>
          </div>

          <div className="p-4 text-center text-neutral-400">
            {!isComplete ? 
              `${questions.length - learningResponses.length} questions remaining` :
              "All questions answered!"
            }
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen w-full bg-black relative">
      <button 
        className="fixed top-6 right-6 z-50 p-3 rounded-full hover:bg-gray-800 transition-colors bg-neutral-900"
        onClick={() => navigate('/account-settings')}
      >
        <Settings className="w-6 h-6 text-white" />
      </button>

      {showWelcomeModal && (
        <div className="fixed inset-0 flex items-center justify-center z-[1000]">
          <div className="absolute inset-0 bg-black bg-opacity-50" />
          <motion.div 
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            className="bg-neutral-900 p-8 rounded-lg max-w-md mx-4 text-center relative"
          >
            <h2 className="text-2xl font-bold mb-2 text-white">Let's Create Your AI Twin</h2>
            <p className="text-gray-300 mb-6">
              In just a few minutes, you'll have an AI version of yourself ready to coach your clients 24/7
            </p>
            <button
              onClick={handleStartSetup}
              className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 rounded-lg transition-colors"
            >
              Start Setup (5 min)
            </button>
          </motion.div>
        </div>
      )}

      <TourModal />
      {showAiDemo && <AiDemoModal />}
      {showChatOverlay && <ChatOverlay />}

      <div className="container mx-auto px-4 pt-20 pb-0 max-w-[1400px]">
        <Tabs value={activeTab} onValueChange={setActiveTab}>
          <TabsList className="grid w-full grid-cols-4 px-6">
            <TabsTrigger value="nutrition">Nutrition</TabsTrigger>
            <TabsTrigger value="routines">Routines</TabsTrigger>
            <TabsTrigger value="products">Products</TabsTrigger>
            <TabsTrigger value="messaging">Messaging</TabsTrigger>
          </TabsList>

          <TabsContent value="nutrition" className="mb-0">
            <NutritionPhilosophyEditor userId={user?.uid} />
          </TabsContent>
          <TabsContent value="routines" className="mb-0">
            <RoutineBuilder userId={user?.uid} />
          </TabsContent>
          <TabsContent value="products" className="mb-0">
            <ProductsTab userId={user?.uid} isFirstSignup={isFirstSignup} />
          </TabsContent>
          <TabsContent value="messaging" className="mb-0">
            <MessagingTab userId={user?.uid} />
          </TabsContent>
        </Tabs>
      </div>

      <button
        onClick={() => {
          setShowAiDemo(true);
          setShowChatOverlay(true);
        }}
        className="fixed bottom-8 right-8 bg-blue-600 hover:bg-blue-700 text-white rounded-full p-4 shadow-lg flex items-center gap-2 z-50"
      >
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          className="h-6 w-6" 
          fill="none" 
          viewBox="0 0 24 24" 
          stroke="currentColor"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" 
          />
        </svg>
        <span>Test your AI</span>
      </button>

      {showChatOverlay && (
        <ChatOverlay 
          initialMessages={demoMessages}
          onClose={() => {
            setShowChatOverlay(false);
            setShowAiDemo(false);
          }}
        />
      )}

      {showLearningModal && (
        <LearningOverlay 
          onClose={() => {
            setShowLearningModal(false);
            setShowAiDemo(true);
          }}
        />
      )}
    </div>
  );
};

export default AccountPage;