// src/components/CoachDashboard.jsx

import React, { useState, useEffect ,useCallback} from 'react';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  onSnapshot,
  serverTimestamp,
  arrayUnion,
  arrayRemove,
  increment,
  addDoc,
  setDoc,
} from 'firebase/firestore';
import { CheckIcon, X , HelpCircle} from 'lucide-react';
import { Input } from './ui/Input';
import { Button } from './ui/Button';
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';
import { db } from '../firebase';
import AdherenceWidget from './AdherenceWidget';
import NavigationBar from './NavigationBar';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { API_URLS } from '../constants/api';
import { loadStripe } from '@stripe/stripe-js';
import SubscriptionModal from './SubscriptionModal';
import { useTour } from '@reactour/tour';
import { dashboardSteps } from '../tours';
import { motion } from 'framer-motion';
import Joyride from 'react-joyride';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from './ui/Dialog';
import { initializeClientAccount } from '../utils/clientInitialization';

const stripePromise = loadStripe('pk_live_51PmzEsJp9datwQ8XX5QXruYq4HeCdzi5qLpPTOJ6DauNGUe4RicW0vtsCiyow8gqjubN2NtcB9w5jr7ycWtgMAaR00wFdVnC4K');

const CoachDashboard = ({ handlePaymentSuccess }) => {
  const { user, isAdmin } = useAuth();
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [message, setMessage] = useState('');
  const [subscriptionQuantity, setSubscriptionQuantity] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [requestedSeats, setRequestedSeats] = useState(0);
  const [coachData, setCoachData] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState('');
  const [isLoadingClients, setIsLoadingClients] = useState(true);
  const [pendingRequests, setPendingRequests] = useState([]);
  
  // New state to show a loading overlay when redirecting to Stripe
  const [isRedirectingToCheckout, setIsRedirectingToCheckout] = useState(false);
  const { setSteps, setIsOpen, setCurrentStep } = useTour();
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [showTourModal, setShowTourModal] = useState(false);
  const [tourStep, setTourStep] = useState(0);
  const [pendingDemoClient, setPendingDemoClient] = useState(null);
  
  // Add new state for success modal
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  
  const startTour = useCallback(() => {
    // Reset tour state before starting
    setCurrentStep(0);
    setSteps(dashboardSteps);
    
    // Small delay to ensure state is reset
    setTimeout(() => {
      setIsOpen(true);
    }, 100);
  }, [setSteps, setIsOpen, setCurrentStep]);

  // Check for first time visit and show welcome message
  useEffect(() => {
    const hasSeenWelcome = localStorage.getItem('hasSeenWelcome');
    
    if (!hasSeenWelcome && user) {
      setShowWelcomeModal(true);
      localStorage.setItem('hasSeenWelcome', 'true');
    }
  }, [user]);

  const handleGetStarted = () => {
    setShowWelcomeModal(false);
    // Reset and start tour
    setCurrentStep(0);
    startTour();
    localStorage.setItem('hasSeenTour-dashboard', 'true');
  };

  // Handle tour completion
  const handleTourCallback = (data) => {
    const { status, type } = data;
    if (status === 'finished' || status === 'skipped') {
      setIsOpen(false);
      setCurrentStep(0);
    }
  };

  useEffect(() => {
    if (user?.uid) {
      const unsubscribe = loadClients();
      return () => {
        if (unsubscribe) unsubscribe();
      };
    }
  }, [user?.uid]);

  useEffect(() => {
    if (user?.uid) {
      const q = query(
        collection(db, 'users'),
        where('requestedCoachId', '==', user.uid),
        where('requestStatus', '==', 'pending')
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const uniqueRequests = new Map();
        
        snapshot.docs.forEach((doc) => {
          const request = {
            id: doc.id,
            ...doc.data()
          };
          // Only add if not already in the map
          if (!uniqueRequests.has(doc.id)) {
            uniqueRequests.set(doc.id, request);
          }
        });
        
        const requests = Array.from(uniqueRequests.values());
        console.log('Pending requests:', requests);
        setPendingRequests(requests);
      });

      return () => unsubscribe();
    }
  }, [user?.uid]);

  useEffect(() => {
    let unsubscribeCoach = () => {};
  
    const setupSubscriptions = async () => {
      if (!user?.uid) return;
  
      try {
        const coachRef = doc(db, 'coaches', user.uid);
        unsubscribeCoach = onSnapshot(coachRef, (doc) => {
          if (doc.exists) {
            const data = doc.data();
            setSubscriptionQuantity(data.subscriptionQuantity || 0);
            setCoachData(data);
          }
        });
      } catch (error) {
        console.error('Error setting up subscription listener:', error);
      }
    };
  
    setupSubscriptions();
    return () => unsubscribeCoach();
  }, [user?.uid]);

  const loadClients = () => {
    if (!user?.uid) return;
  
    setIsLoadingClients(true);
  
    const clientsQuery = query(
      collection(db, 'users'),
      where('coachId', '==', user.uid)
    );
  
    return onSnapshot(clientsQuery, async (snapshot) => {
      try {
        const clientList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setClients(clientList);
        
        const clientIds = clientList.map(client => client.id);
        
        const coachDocRef = doc(db, 'coaches', user.uid);
        await updateDoc(coachDocRef, {
          numberOfClients: clientList.length,
          clients: clientIds,
          updatedAt: serverTimestamp(),
        });
  
        const coachDoc = await getDoc(coachDocRef);
        if (coachDoc.exists()) {
          const data = coachDoc.data();
          setSubscriptionQuantity(data.subscriptionQuantity || 0);
        } else {
          setSubscriptionQuantity(0);
        }
      } catch (error) {
        console.error('Error processing client update:', error);
        setMessage('Error updating clients: ' + error.message);
      } finally {
        setIsLoadingClients(false);
      }
    }, (error) => {
      console.error('Error in client subscription:', error);
      setMessage('Error loading clients: ' + error.message);
      setIsLoadingClients(false);
    });
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    setUploading(true);
    setUploadMessage('');
    
    try {
      const fileContents = await file.text();
      const exercisesData = JSON.parse(fileContents);
  
      const idToken = await user.getIdToken();
      console.log('Checkout URL:', API_URLS.createCheckoutSession);
      const response = await fetch(API_URLS.uploadExercises, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`,
        },
        body: JSON.stringify({ exercises: exercisesData }),
      });
  
      const result = await response.json();
  
      if (result.success) {
        setUploadMessage('Exercises uploaded successfully');
      } else {
        throw new Error(result.error || 'Failed to upload exercises');
      }
    } catch (error) {
      console.error('Error uploading exercises:', error);
      setUploadMessage(`Error uploading exercises: ${error.message}`);
    } finally {
      setUploading(false);
    }
  };

  const removeClient = async (clientId) => {
    try {
      const idToken = await user.getIdToken();
      const response = await fetch(API_URLS.removeClient, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`,
        },
        body: JSON.stringify({ clientId }),
      });
  
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Failed to remove client');
      }
  
      await loadClients();
      setMessage('Client removed successfully');
    } catch (error) {
      console.error('Error removing client:', error);
      setMessage('Error removing client: ' + error.message);
    }
  };

  const handleClientClick = (clientId) => {
    navigate(`/client/${clientId}`);
  };

  const acceptRequest = async (client) => {
    try {
      // Check if coach has a customer ID and active/trialing subscription
      if (!coachData?.stripeCustomerId || coachData?.subscriptionStatus === 'canceled' || coachData?.subscriptionStatus === 'inactive') {
        // Store the pending client ID in localStorage before redirecting
        if (client.type === 'demo') {
          localStorage.setItem('pendingDemoClient', JSON.stringify(client));
        }
        
        setIsRedirectingToCheckout(true);
        const idToken = await user.getIdToken();
        
        // Define base URL
        const baseUrl = window.location.origin;
        console.log('Base URL:', baseUrl); // Debug log
        
        const sessionData = {
          seatCount: 1,
          domain: baseUrl,
          successUrl: `${baseUrl}/dashboard`,
          cancelUrl: `${baseUrl}/dashboard`,
        };
        
        console.log('Session data:', sessionData); // Debug log
        
        const response = await fetch(API_URLS.createCheckoutSession, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`,
          },
          body: JSON.stringify(sessionData),
        });

        const data = await response.json();
        console.log('Stripe session response:', data); // Debug log
        
        if (data.sessionId) {
          const stripe = await stripePromise;
          const result = await stripe.redirectToCheckout({
            sessionId: data.sessionId,
          });
          if (result.error) {
            console.error('Stripe redirect error:', result.error);
            setMessage(result.error.message);
            setIsRedirectingToCheckout(false);
          }
        }
        return;
      }

      // If subscription is active/trialing but no seats left
      if (clients.length >= subscriptionQuantity) {
        setRequestedSeats(clients.length + 1);
        setShowSubscriptionModal(true);
        return;
      }

      // Accept the request
      await updateDoc(doc(db, 'users', client.id), {
        coachId: user.uid,
        requestedCoachId: null,
        requestStatus: 'accepted',
        updatedAt: serverTimestamp(),
        subscriptionStatus: 'active',
      });
      
      // Initialize client account with welcome messages and settings
      await initializeClientAccount(client.id, user.uid, client.name);
      
      setMessage('Client request accepted successfully');
    } catch (error) {
      setMessage(`Error accepting request: ${error.message}`);
      setIsRedirectingToCheckout(false);
    }
    window.history.replaceState({}, '', '/dashboard');
  };

  
  const declineRequest = async (client) => {
    try {
      await updateDoc(doc(db, 'users', client.id), {
        requestedCoachId: null,
        requestStatus: 'declined',
        updatedAt: serverTimestamp()
      });
    } catch (error) {
      setMessage(`Error declining request: ${error.message}`);
    }
  };

  const handleSubscriptionUpdate = async (seats) => {
    setIsLoading(true);
    try {
      // Force token refresh and log the full process
      console.log('Starting subscription update...');
      console.log('Getting fresh token...');
      
      // Make sure we have a user
      if (!user) {
        throw new Error('No user found');
      }
      
      const idToken = await user.getIdToken(true);
      console.log('Token received:', {
        tokenLength: idToken.length,
        tokenStart: idToken.substring(0, 20),
        tokenEnd: idToken.substring(idToken.length - 20)
      });

      const coachDoc = await getDoc(doc(db, 'coaches', user.uid));
      const cData = coachDoc.data();
      
      // Log the full request details
      const requestDetails = {
        url: API_URLS.createCheckoutSession,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        body: {
          seatCount: seats || requestedSeats,
          domain: window.location.origin
        }
      };
      console.log('Request details:', requestDetails);

      if (!cData.subscriptionId || cData.subscriptionStatus === 'canceled') {
        const response = await fetch(API_URLS.createCheckoutSession, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`
          },
          body: JSON.stringify({
            seatCount: seats || requestedSeats,
            domain: window.location.origin
          })
        });

        console.log('Response status:', response.status);
        console.log('Response headers:', Object.fromEntries(response.headers.entries()));
        
        const data = await response.json();
        console.log('Response data:', data);

        if (!response.ok) {
          throw new Error(data.error || 'Failed to create checkout session');
        }

        if (data.sessionId) {
          console.log('Received session ID, redirecting to checkout...');
          const stripe = await stripePromise;
          const result = await stripe.redirectToCheckout({ sessionId: data.sessionId });
          if (result.error) {
            setMessage(result.error.message);
          }
        } else {
          setShowSubscriptionModal(false);
          setMessage('Subscription updated successfully');
        }
        return;
      }

      // Update existing subscription
      let subscriptionResponse;
      try {
        subscriptionResponse = await fetch(API_URLS.createCheckoutSession, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            seatCount: seats || requestedSeats,
            domain: window.location.origin,
          }),
        });
      
        if (!subscriptionResponse.ok) {
          const errorData = await subscriptionResponse.json();
          throw new Error(errorData.error || 'Failed to create checkout session');
        }
      
        const subscriptionData = await subscriptionResponse.json();
        if (subscriptionData.sessionId) {
          const stripe = await stripePromise;
          const result = await stripe.redirectToCheckout({
            sessionId: subscriptionData.sessionId,
          });
          if (result.error) {
            setMessage(result.error.message);
            setIsRedirectingToCheckout(false);
          }
        }

        // Handle success case
        if (subscriptionData.success) {
          setShowSubscriptionModal(false);
          setMessage('Subscription updated successfully');

          const updatedCoachDoc = await getDoc(doc(db, 'coaches', user.uid));
          if (updatedCoachDoc.exists()) {
            const d = updatedCoachDoc.data();
            setSubscriptionQuantity(d.subscriptionQuantity || 0);
          }
        } else if (subscriptionData.redirectUrl) {
          window.location.href = subscriptionData.redirectUrl;
        }

      } catch (error) {
        console.error('Detailed error:', error);
        setMessage(`Error creating checkout session: ${error.message}`);
        setIsRedirectingToCheckout(false);
        throw error; // Re-throw to be caught by outer catch block
      }      

    } catch (error) {
      console.error('Full error details:', {
        name: error.name,
        message: error.message,
        stack: error.stack
      });
      setMessage(`Error updating subscription: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Effect to check firstTimeDashboard
  useEffect(() => {
    const checkFirstTimeDashboard = async () => {
      if (!user?.uid) return;
      
      const coachRef = doc(db, 'coaches', user.uid);
      const coachDoc = await getDoc(coachRef);
      
      if (coachDoc.exists() && coachDoc.data().firstTimeDashboard === true) {
        setShowWelcomeModal(true);
      }
    };

    checkFirstTimeDashboard();
  }, [user?.uid]);

  const handleStartSetup = () => {
    setShowWelcomeModal(false);
    setShowTourModal(true);
    setTourStep(0);
  };

  const handleNextStep = async () => {
    if (tourStep === 0) {
      // Create demo client on first step
      const demoClient = {
        name: 'Sarah - Demo Client',
        email: 'sarah.demo@example.com',
        requestedCoachId: user.uid,
        requestStatus: 'pending',
        type: 'demo',
        createdAt: serverTimestamp(),
      };

      // Add demo client to users collection
      const demoClientRef = await addDoc(collection(db, 'users'), demoClient);
      
      // Set message settings with AI approval enabled
      await setDoc(
        doc(db, 'users', demoClientRef.id, 'settings', 'messageSettings'),
        {
          requireCoachApproval: true,
          language: 'en',
        }
      );
      
      setPendingDemoClient({ id: demoClientRef.id, ...demoClient });
      setTourStep(1);
    } else if (tourStep === 1) {
      if (pendingDemoClient) {
        await acceptRequest(pendingDemoClient);
      }
      setShowTourModal(false);
    }
  };

  const getPosition = () => {
    switch (tourStep) {
      case 0:
        return 'top-[250px] left-[1%]'; // Higher and more to the left for pending requests
      case 1:
        return 'top-[250px] left-[1%]'; // Higher and more to the left for accept button
      default:
        return 'top-24 left-1/2';
    }
  };

  const TourModal = () => {
    if (!showTourModal) return null;
    
    const tourContent = [
      "When potential clients download Evolv from the App Store, they can search for your name and send a request to join your coaching program. New requests appear here.",
      "For your first client, we'll set up your 30-day free trial. Press the Checkmark to continue, click Confirm Changes to begin your trial. Good Luck on your Coaching Journey!"
    ];

    // Set firstTimeDashboard to false after showing first message
    useEffect(() => {
      if (tourStep === 0 && user?.uid) {
        const updateFirstTime = async () => {
          const coachRef = doc(db, 'coaches', user.uid);
          await updateDoc(coachRef, {
            firstTimeDashboard: false
          });
        };
        updateFirstTime();
      }
    }, [tourStep, user?.uid]);
    
    return (
      <motion.div 
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        className={`fixed ${getPosition()} transform -translate-x-1/2 z-[1000] bg-[#1C1C1C] p-6 rounded-lg max-w-md mx-4`}
      >
        <div className="text-white">
          <p className="mb-4">{tourContent[tourStep]}</p>
          <div className="flex justify-end">
            <button
              onClick={handleNextStep}
              className="px-4 py-2 rounded-md bg-[#6495ED] hover:bg-blue-600"
            >
              Next
            </button>
          </div>
        </div>
      </motion.div>
    );
  };

  // Combine the Stripe return check into a single useEffect
  useEffect(() => {
    const checkStripeReturn = async () => {
      if (!user) return;

      const urlParams = new URLSearchParams(window.location.search);
      const sessionId = urlParams.get('session_id');
      
      if (!sessionId) return;
      
      console.log('Checking Stripe return:', sessionId); // Should now only log once
      
      try {
        const idToken = await user.getIdToken();
        const response = await fetch(API_URLS.checkSession, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`,
          },
          body: JSON.stringify({ sessionId }),
        });

        const data = await response.json();
        if (data.success) {
          handlePaymentSuccess?.();
          // Clear the URL parameters without refreshing the page
          window.history.replaceState({}, '', window.location.pathname);
        }
      } catch (error) {
        console.error('Error checking session:', error);
      }
    };

    checkStripeReturn();
  }, [user, handlePaymentSuccess]); // Only depend on user and handlePaymentSuccess

  return (
    <div className="bg-[#000000] min-h-screen text-[#E0E0E0] flex flex-col w-full pt-20">
      {showWelcomeModal && (
        <div className="fixed inset-0 flex items-center justify-center z-[1000]">
          <div className="absolute inset-0 bg-black bg-opacity-50" />
          <motion.div 
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            className="bg-neutral-900 p-8 rounded-lg max-w-md mx-4 text-center relative"
          >
            <h2 className="text-2xl font-bold mb-2 text-white">Let's Get Your First Client</h2>
            <p className="text-gray-300 mb-6">
              We've added a demo client request to show you how client onboarding works
            </p>
            <button
              onClick={handleStartSetup}
              className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 rounded-lg transition-colors"
            >
              Show Me
            </button>
          </motion.div>
        </div>
      )}

      <TourModal />

      {/* Tour Component */}
      <Joyride
        steps={dashboardSteps}
        run={setIsOpen}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        callback={handleTourCallback}
        styles={{
          options: {
            zIndex: 1000,
          },
        }}
      />

      {/* Help Button */}
      <button
        onClick={startTour}
        className="fixed bottom-4 right-4 bg-blue-500 hover:bg-blue-600 text-white p-3 rounded-full w-12 h-12 flex items-center justify-center transition-colors"
        aria-label="Start Tour"
      >
        <HelpCircle className="h-10 w-10"/>
      </button>

      {message && (
        <div className={`p-4 rounded ${
          message.includes('Error') ? 'bg-red-500' : 'bg-green-500'
        } text-white mb-4`}>
          {message}
        </div>
      )}
      
      {isAdmin && (
        <div className="mb-4 px-6 md:px-8">
          <label className="block text-sm font-medium text-gray-300 mb-1">
            Upload Exercises JSON
          </label>
          <input
            type="file"
            accept=".json"
            onChange={handleFileUpload}
            disabled={uploading}
            className="block w-full text-sm text-gray-500
              file:mr-4 file:py-2 file:px-4
              file:rounded-lg file:border-0
              file:text-sm file:font-semibold
              file:bg-blue-50 file:text-blue-700
              hover:file:bg-blue-100"
          />
          {uploadMessage && (
            <div className="mt-2 text-sm text-green-600">{uploadMessage}</div>
          )}
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full flex-grow px-6 pb-6">
      
        {/* Coach Stats Card */}
        <Card className="bg-[#1C1C1C] border-0 p-6 flex flex-col gap-6" >
          <CardHeader className="p-0" data-tour="performance-card">
            <CardTitle className="text-[#FFFFFF]">Coach Performance</CardTitle>
          </CardHeader>
          <CardContent className="p-0" >
            <AdherenceWidget coachId={user.uid}  />
          </CardContent>
        </Card>

        {/* Clients and Pending Requests */}
        
        <Card className="bg-neutral-900 border-0 p-6 flex flex-col gap-6">
          <CardHeader className="p-0">
            <div className="flex justify-between items-start">
              <div className="flex flex-col gap-2">
                <CardTitle className="text-[#FFFFFF]">
                  Your Clients
                </CardTitle>
                <p className="text-sm text-gray-400" data-tour="clients-section">
                  {clients.length} of {subscriptionQuantity} available spots filled
                </p>
              </div>
              <Button
                variant="ghost"
                className="bg-neutral-800 text-white hover:bg-blue-600 whitespace-nowrap text-sm"
                onClick={() => setShowSubscriptionModal(true) }
                data-tour="adjust-capacity"    
                            
              >
                Adjust Capacity
              </Button>
            </div>
          </CardHeader>
          <CardContent className="p-0 flex flex-col gap-6">
          
          
            {/* Pending Requests Section */}
            <div className="space-y-4" data-tour="pending-1">
              <h2 className="text-white font-semibold">Pending Requests</h2>
              <div className="space-y-2" data-tour="pending-2">
                {pendingRequests.length === 0 ? (
                  <div className="text-center py-2 text-gray-400">No pending requests</div>
                ) : (
                  pendingRequests.map((req) => (
                    <div key={req.id} className="flex justify-between items-center bg-[#333333] px-4 py-3 rounded-lg">
                      <div>
                        <div className="text-[#FFFFFF]">{req.name}</div>
                        <div className="text-sm text-gray-400">{req.email}</div>
                      </div>
                      <div className="flex gap-2 items-center">
                        <Button
                          variant="ghost"
                          className="bg-green-600 hover:bg-green-700 text-white p-2 h-max"
                          onClick={() => acceptRequest(req)}
                          disabled={isRedirectingToCheckout} // Prevent multiple clicks
                        >
                          <CheckIcon className="h-4 w-4" />
                        </Button>
                        <Button
                          variant="ghost"
                          className="bg-red-600 hover:bg-red-700 text-white p-2 h-max"
                          onClick={() => declineRequest(req)}
                          disabled={isRedirectingToCheckout}
                        >
                          <X className="h-4 w-4" />
                        </Button>
                      </div>
                    </div>
                  ))
                )}
              </div>
            
            </div>

            {/* Clients Section */}
            <div className="space-y-4">
              <h2 className="text-white font-semibold">Current Clients</h2>
              <div className="space-y-2">
                {isLoadingClients ? (
                  <div className="text-center py-4 text-gray-400">Loading clients...</div>
                ) : clients.length === 0 ? (
                  <div className="text-center py-4 text-gray-400">No clients found</div>
                ) : (
                  clients.map((client) => (
                    <div
                      key={client.id}
                      className="flex justify-between items-center bg-[#333333] cursor-pointer px-4 py-3 rounded-lg"
                      onClick={() => handleClientClick(client.id)}
                    >
                      <div>
                        <div className="text-[#FFFFFF]">
                          {client.name} ({client.email})
                        </div>
                        <div className="text-sm text-gray-400">{client.phoneNumber}</div>
                      </div>
                      <Button
                        size="icon"
                        variant="ghost"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeClient(client.id);
                        }}
                        className="text-[#E0E0E0] hover:text-[#6495ED]"
                        disabled={isRedirectingToCheckout}
                      >
                        <X className="h-4 w-4" />
                      </Button>
                    </div>
                  ))
                )}
              </div>
            </div>
          </CardContent>
        </Card>
      </div>

      <SubscriptionModal
        isOpen={showSubscriptionModal}
        onClose={() => setShowSubscriptionModal(false)}
        currentSeats={subscriptionQuantity}
        requestedSeats={requestedSeats}
        onConfirm={handleSubscriptionUpdate}
        isLoading={isLoading}
        trialEndsAt={coachData?.trialEndsAt}
        subscriptionStatus={coachData?.subscriptionStatus || 'inactive'}
      />

      {isRedirectingToCheckout && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="text-white flex flex-col items-center">
            <svg className="animate-spin h-8 w-8 text-white mb-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.372 0 0 5.372 0 12h4z"></path>
            </svg>
            <div>One moment please...</div>
          </div>
        </div>
      )}

      {/* Success Modal */}
      {showSuccessModal && (
        <div className="fixed inset-0 flex items-center justify-center z-[1000]">
          <div className="absolute inset-0 bg-black bg-opacity-50" />
          <motion.div 
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            className="bg-neutral-900 p-8 rounded-lg max-w-md mx-4 text-center relative"
          >
            <h2 className="text-2xl font-bold mb-4 text-white">You're All Set!</h2>
            <p className="text-gray-300 mb-6">
              Feel free to explore the dashboard. Look for the help buttons <HelpCircle className="inline-block w-5 h-5" /> 
              throughout the app for guidance on specific features.
            </p>
            <button
              onClick={() => setShowSuccessModal(false)}
              className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 rounded-lg transition-colors"
            >
              Got It
            </button>
          </motion.div>
        </div>
      )}
    </div>
  );
};

export default CoachDashboard;