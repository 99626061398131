// src/components/MessageSchedule.jsx

import React from 'react';
import { Input } from './ui/Input';
import { Label } from './ui/Label';
import  { Switch }  from './ui/Switch';

const MessageSchedule = ({ scheduledDate, messageSendTime, onMessageSendTimeChange }) => {
    const [useCustomTime, setUseCustomTime] = React.useState(false);
    
    // Calculate default send time (1 day before if >2 days away, at 2 PM CT)
    const defaultSendTime = React.useMemo(() => {
      const now = new Date();
      const scheduledDateTime = new Date(scheduledDate);
      const twoDaysFromNow = new Date();
      twoDaysFromNow.setDate(now.getDate() + 2);
      
      if (scheduledDateTime < twoDaysFromNow) {
        // If less than 2 days away, send immediately
        return now;
      } else {
        
        const sendDate = new Date(scheduledDateTime);
        sendDate.setDate(sendDate.getDate() - 1);
        
        
        const jan = new Date(sendDate.getFullYear(), 0, 1).getTimezoneOffset();
        const jul = new Date(sendDate.getFullYear(), 6, 1).getTimezoneOffset();
        const isCDT = sendDate.getTimezoneOffset() < Math.max(jan, jul);
        
        sendDate.setUTCHours(isCDT ? 19 : 20); // 2 PM CT in UTC
        sendDate.setMinutes(0);
        sendDate.setSeconds(0);
        sendDate.setMilliseconds(0);
        
        return sendDate;
      }
    }, [scheduledDate]);
  
    // Set initial message send time
    React.useEffect(() => {
      if (typeof onMessageSendTimeChange === 'function') {
        onMessageSendTimeChange(defaultSendTime);
      }
    }, [defaultSendTime, messageSendTime, onMessageSendTimeChange]);
  
    // Helper function to check if date is in DST
    const isDaylightSavingTime = (date) => {
      const jan = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
      const jul = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
      const stdTimezoneOffset = Math.max(jan, jul);
      return date.getTimezoneOffset() < stdTimezoneOffset;
    };
  
    // Format date for datetime-local input
    const formatDateForInput = (date) => {
      return date.toISOString().slice(0, 16);
    };
  
    const handleCustomTimeToggle = (checked) => {
      setUseCustomTime(checked);
      if (!checked) {
        onMessageSendTimeChange(defaultSendTime);
      }
    };
  
    const handleTimeChange = (e) => {
      const newDate = new Date(e.target.value);
      onMessageSendTimeChange(newDate);
    };
  
    // Format display date in Central Time
    const formatDisplayDate = (date) => {
      return new Date(date).toLocaleString('en-US', {
        timeZone: 'America/Chicago',
        dateStyle: 'full',
        timeStyle: 'short'
      });
    };
  
    return (
      <div className="space-y-4 p-4 bg-neutral-800 rounded-lg">
        <div className="flex items-center justify-between">
          <div className="space-y-0.5">
            <Label className="text-sm font-medium text-gray-200">
              Customize Message Send Time
            </Label>
            <p className="text-xs text-gray-400">
              {scheduledDate < new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000)
                ? "Workout will be sent immediately"
                : "Workout will be sent one day before the scheduled date at 2 PM Central Time"}
            </p>
          </div>
          <Switch
            checked={useCustomTime}
            onChange={handleCustomTimeToggle}
            className="bg-neutral-700"
          />
        </div>
  
        {useCustomTime ? (
          <div className="space-y-2">
            <Label htmlFor="sendTime" className="text-sm text-gray-300">
              Send Time (Your Local Time)
            </Label>
            <Input
              id="sendTime"
              type="datetime-local"
              value={formatDateForInput(messageSendTime || defaultSendTime)}
              onChange={handleTimeChange}
              className="bg-neutral-700 border-neutral-600"
            />
          </div>
        ) : (
          <p className="text-sm text-gray-400">
            Workout will be sent to your client on: {formatDisplayDate(defaultSendTime)}
          </p>
        )}
      </div>
    );
  };
  
export default MessageSchedule;