import React, { useState, useEffect } from 'react';
import { Plus, Edit2, Trash2, Check } from 'lucide-react';
import { format } from 'date-fns';
import {
  collection,
  doc,
  getDocs,
  setDoc,
  deleteDoc,
  query,
  orderBy,
} from 'firebase/firestore';
import { db } from '../firebase';
import { Button } from './ui/Button';
import {
  Dialog,
  DialogTrigger,
} from './ui/Dialog';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from './ui/Card';
import { ScrollArea } from './ui/ScrollArea';
import InjuryForm from './InjuryForm';

const INJURY_STATUS = {
  ACTIVE: 'active',
  RESOLVED: 'resolved'
};

const StatusBadge = ({ status }) => {
  const colors = {
    [INJURY_STATUS.ACTIVE]: 'bg-red-500/10 text-red-500',
    [INJURY_STATUS.RESOLVED]: 'bg-green-500/10 text-green-500',
  };

  return (
    <span className={`px-2 py-1 rounded-full text-xs font-medium ${colors[status]}`}>
      {status.charAt(0).toUpperCase() + status.slice(1)}
    </span>
  );
};

const ClientInjuriesTab = ({ clientId }) => {
  const [currentInjuries, setCurrentInjuries] = useState([]);
  const [injuryHistory, setInjuryHistory] = useState([]);
  const [isAddingInjury, setIsAddingInjury] = useState(false);
  const [editingInjury, setEditingInjury] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    date: format(new Date(), 'yyyy-MM-dd'),
    description: '',
    status: INJURY_STATUS.ACTIVE,
    treatment: '',
    recoveryNotes: '',
  });

  useEffect(() => {
    fetchInjuries();
  }, [clientId]);

  const fetchInjuries = async () => {
    try {
      const injuriesRef = collection(db, 'users', clientId, 'userKnowledge', 'injuries', 'records');
      const q = query(injuriesRef, orderBy('date', 'desc'));
      const querySnapshot = await getDocs(q);
      
      const current = [];
      const history = [];
      
      querySnapshot.forEach((doc) => {
        const injury = { id: doc.id, ...doc.data() };
        if (injury.status === INJURY_STATUS.RESOLVED) {
          history.push(injury);
        } else {
          current.push(injury);
        }
      });
      
      setCurrentInjuries(current);
      setInjuryHistory(history);
    } catch (error) {
      console.error('Error fetching injuries:', error);
    }
  };

  const handleAddInjury = async () => {
    try {
      const injuryRef = doc(collection(db, 'users', clientId, 'userKnowledge', 'injuries', 'records'));
      await setDoc(injuryRef, {
        ...formData,
        status: INJURY_STATUS.ACTIVE,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      });
      
      setFormData({
        name: '',
        date: format(new Date(), 'yyyy-MM-dd'),
        description: '',
        status: INJURY_STATUS.ACTIVE,
        treatment: '',
        recoveryNotes: '',
      });
      setIsAddingInjury(false);
      fetchInjuries();
    } catch (error) {
      console.error('Error adding injury:', error);
    }
  };

  const handleUpdateInjury = async () => {
    try {
      const injuryRef = doc(db, 'users', clientId, 'userKnowledge', 'injuries', 'records', editingInjury.id);
      await setDoc(injuryRef, {
        ...formData,
        updatedAt: new Date().toISOString(),
      }, { merge: true });
      
      setEditingInjury(null);
      fetchInjuries();
    } catch (error) {
      console.error('Error updating injury:', error);
    }
  };

  const handleResolveInjury = async (injury) => {
    try {
      const injuryRef = doc(db, 'users', clientId, 'userKnowledge', 'injuries', 'records', injury.id);
      await setDoc(injuryRef, {
        ...injury,
        status: INJURY_STATUS.RESOLVED,
        resolvedAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      }, { merge: true });
      
      fetchInjuries();
    } catch (error) {
      console.error('Error resolving injury:', error);
    }
  };

  const handleDeleteInjury = async (injuryId) => {
    try {
      await deleteDoc(doc(db, 'users', clientId, 'userKnowledge', 'injuries', 'records', injuryId));
      fetchInjuries();
    } catch (error) {
      console.error('Error deleting injury:', error);
    }
  };

  const renderInjuryCard = (injury) => {
    return (
      <Card key={injury.id} className="mb-4">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <div className="space-y-1">
            <CardTitle>{injury.name}</CardTitle>
            <CardDescription>
              {format(new Date(injury.date), 'MMM dd, yyyy')}
            </CardDescription>
          </div>
          <div className="flex space-x-2">
            <StatusBadge status={injury.status} />
            {injury.status === INJURY_STATUS.ACTIVE && (
              <Button
                variant="ghost"
                size="icon"
                onClick={() => handleResolveInjury(injury)}
                className="text-green-500 hover:text-green-400"
              >
                <Check className="h-4 w-4" />
              </Button>
            )}
            <Button
              variant="ghost"
              size="icon"
              onClick={() => {
                setFormData(injury);
                setEditingInjury(injury);
              }}
            >
              <Edit2 className="h-4 w-4" />
            </Button>
            <Button
              variant="ghost"
              size="icon"
              onClick={() => handleDeleteInjury(injury.id)}
            >
              <Trash2 className="h-4 w-4" />
            </Button>
          </div>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {injury.description && (
              <div>
                <p className="text-sm font-medium mb-1">Description</p>
                <p className="text-sm text-neutral-400">{injury.description}</p>
              </div>
            )}
            
            {injury.treatment && (
              <div>
                <p className="text-sm font-medium mb-1">Treatment</p>
                <p className="text-sm text-neutral-400">{injury.treatment}</p>
              </div>
            )}
            
            {injury.recoveryNotes && (
              <div>
                <p className="text-sm font-medium mb-1">Recovery Notes</p>
                <p className="text-sm text-neutral-400">{injury.recoveryNotes}</p>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    );
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">Injury Tracking</h2>
        <Dialog open={isAddingInjury} onOpenChange={setIsAddingInjury}>
          <DialogTrigger asChild>
            <Button>
              <Plus className="h-4 w-4 mr-2" />
              Add Injury
            </Button>
          </DialogTrigger>
          <InjuryForm
            isEditing={false}
            formData={formData}
            onInputChange={setFormData}
            onSubmit={handleAddInjury}
            onClose={() => setIsAddingInjury(false)}
          />
        </Dialog>
      </div>

      <ScrollArea className="h-[calc(100vh-16rem)]">
        <div className="space-y-6">
          {/* Current Injuries Section */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Current Injuries</h3>
            {currentInjuries.length === 0 ? (
              <Card>
                <CardContent className="py-4">
                  <p className="text-neutral-400 text-center">No current injuries</p>
                </CardContent>
              </Card>
            ) : (
              <div>
                {currentInjuries.map((injury) => renderInjuryCard(injury))}
              </div>
            )}
          </div>

          {/* Injury History Section */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Injury History</h3>
            {injuryHistory.length === 0 ? (
              <Card>
                <CardContent className="py-4">
                  <p className="text-neutral-400 text-center">No injury history</p>
                </CardContent>
              </Card>
            ) : (
              <div>
                {injuryHistory.map((injury) => renderInjuryCard(injury))}
              </div>
            )}
          </div>
        </div>
      </ScrollArea>

      {editingInjury && (
        <Dialog open={Boolean(editingInjury)} onOpenChange={() => setEditingInjury(null)}>
          <InjuryForm
            isEditing={true}
            formData={formData}
            onInputChange={setFormData}
            onSubmit={handleUpdateInjury}
            onClose={() => setEditingInjury(null)}
          />
        </Dialog>
      )}
    </div>
  );
};

export default ClientInjuriesTab;